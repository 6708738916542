export const InstallGateway = (Data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/uiapi/rest/gateways/install", {
                body: JSON.stringify(Data),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
            });

            if (response.status === 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const GetGatewaysModels = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/uiapi/rest/gateways/models", {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
            });

            if (response.status === 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const GetGatewayConfig = (gatewayId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/uiapi/rest/gateways/" + gatewayId, {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
            });

            if (response.status === 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
