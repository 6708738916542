export const addDevice = (device) => {
    return new Promise(async (resolve, reject) => {

        try {
            const response = await fetch("/uiapi/rest/nodes", {
                body: JSON.stringify(
                    device,
                ),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const getDevice = (deveui) => {
    return new Promise(async (resolve, reject) => {

        try {
            const response = await fetch("/uiapi/rest/nodes/" + deveui + "?all=true", {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
            });
            if (response.status >= 400) {
                const data = await response.text();
                reject(data);
            }
            else if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }
            else {
                const data = await response.json();
                resolve(data);
            }
        } catch (error) {
            reject(error);
        }
    });
};

export const editDevice = (device) => {
    return new Promise(async (resolve, reject) => {

        try {
            const {deveui, ...rest} = device;
            const response = await fetch("/uiapi/rest/nodes/" + deveui, {
                body: JSON.stringify(
                    rest,
                ),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
