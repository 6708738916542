import * as React from "react";

import "react-toastify/dist/ReactToastify.css";

import SwaggerUI from "swagger-ui-react";
//import "swagger-ui-themes/themes/3.x/theme-newspaper.css";
import "swagger-ui-react/swagger-ui.css"


export class SwaggerPage extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    public render() {
        return (
            <SwaggerUI url="/uiapi/rest/openapi-schema?dassui=true"></SwaggerUI>
        );
    }
}
