import { JSONSchema6 } from "json-schema";
import * as React from "react";
import { Button, Checkbox, Col, ControlLabel, FormControl, FormGroup, HelpBlock, Modal, Row } from "react-bootstrap";
import Form, { UiSchema } from "react-jsonschema-form";
import { JsonUISchema, TranslateForm } from "./../../controllers/Profiles/ProfileFormController";
import { strings } from "./../../services/Localization";
import { SelectDropdown } from "./../DropdownComponent/SelectDropdown";


interface ILinkedProfileFormModalProps {
    ShowModal: boolean;
    EditMode: boolean;
    HideModal: () => void;
    FormData: any;
    FormSchema: any;
    SourceProfiles: any;
    SelectedProfile: any;
    Profile: "DeviceProfiles" | "ServiceProfiles" | "RoamingProfiles" | "ConnectivityProfiles" | "QOSProfiles" | "ChannelProfiles";
    ProfileName: string;
    UserRights: any;
    LoadingData: any;
    CreateProfile: any;
    UpdateProfile: (data: any) => void;
    DisableTargetProfile: boolean;
    ConvertToLinkedMode: boolean;
    ProfileOwnerList: any;
    GetSourceProfiles: (data: any) => void;
    ViewMode: boolean;
    FilterProfiles: any;
    FilterUsers: any;
    Error?: any
}


export class LinkedProfileFormModal extends React.Component<ILinkedProfileFormModalProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            FormSchema: this.props.FormSchema || {},
            InspectProfile: false,
            Loading: true,
            ProfileDesc: "",
            ProfileName: "",
            ProfileNotes: this.props.FormData.notes,
            SelectedProfile: {},
            ShareProfile: false,
            SourceProfile: "",
            SourceUser: "",
            TargetUser: "",
        };
    }

    public componentDidMount() {
        if (this.props.ConvertToLinkedMode) {
            this.setState({
                Loading: this.props.LoadingData,
                SourceProfile: this.props.SelectedProfile.profile_uuid,
            });
        }
    }

    public componentWillReceiveProps(nextProps) {
        const CanInspectProfile = (this.props.UserRights.can_inspect_device_profile &&
            this.props.Profile === "DeviceProfiles") ||
            (this.props.UserRights.can_inspect_service_profile &&
            this.props.Profile === "ServiceProfiles") ||
            (this.props.UserRights.can_inspect_roaming_profile &&
            this.props.Profile === "RoamingProfiles") ||
            (this.props.UserRights.can_inspect_connectivity_profile &&
            this.props.Profile === "ConnectivityProfiles") ||
            (this.props.UserRights.can_inspect_qos_profile &&
            this.props.Profile === "QOSProfiles") ||
            (this.props.UserRights.can_inspect_channel_profile &&
            this.props.Profile === "ChannelProfiles");

        if (this.props.EditMode || this.props.ViewMode) {
            const ServiceSubProfiles: any = this.state.SelectedProfile;
            if (nextProps.FormData.can_be_inspected && CanInspectProfile &&
                (this.props.Profile === "ServiceProfiles")) {
                ServiceSubProfiles.ChannelProfile = {
                    profile_name: nextProps.FormData.content.channel_profile_uuid,
                    profile_uuid: nextProps.FormData.content.channel_profile_uuid,
                };
                ServiceSubProfiles.QoSProfile = {
                    profile_name: nextProps.FormData.content.qos_profile_uuid,
                    profile_uuid: nextProps.FormData.content.qos_profile_uuid,
                };
                ServiceSubProfiles.RoamingProfile = {
                    profile_name: nextProps.FormData.content.roaming_profile_uuid,
                    profile_uuid: nextProps.FormData.content.roaming_profile_uuid,
                };
                ServiceSubProfiles.ConnectivityProfile = {
                    profile_name: nextProps.FormData.content.connectivity_profile_uuid,
                    profile_uuid: nextProps.FormData.content.connectivity_profile_uuid,
                };
            }
            this.setState({
                InspectProfile: nextProps.FormData.can_be_inspected || this.state.InspectProfile,
                ProfileDesc: nextProps.FormData.description || this.state.ProfileDesc,
                ProfileName: nextProps.FormData.profile_name || this.state.ProfileName,
                ProfileNotes: nextProps.FormData.notes,
                ProfileOwner: nextProps.FormData.profile_owner || this.state.TargetUser,
                SelectedProfile: ServiceSubProfiles,
                SourceProfile: nextProps.FormData.link_to_profile_uuid || this.state.SourceProfile,
            });
            if (this.props.UserRights.is_customer) {
                this.setState({
                    ShareProfile: nextProps.FormData.shared_with_all_users || this.state.ShareProfile,
                });
            }
        }
        if (this.props.ViewMode || this.props.EditMode) {
            if (this.state.FormSchema !== nextProps.FormSchema) {
                this.setState({
                    FormSchema: TranslateForm(this.props.Profile, nextProps.FormSchema) || {},
                });
            }
        }
        setTimeout(() => {
            if (nextProps.LoadingData !== this.state.Loading) {
                this.setState({
                    Loading: nextProps.LoadingData,
                });
            }
        }, 500);
    }

    public CheckForErrors = () => {
        const length = this.state.ProfileName.length;
        if (length === 0) {
            return {message: strings.REQUIRED_PROPERTY_MESSAGE, error: "error"};
        } else if (length < 6) {
            return {message: strings.LEAST_6_CHARACTERS_LONG_ERROR_MESSAGE, error: "error"};
        } else if (length > 64) {
            return {message: strings.MOST_64_CHARACTERS_LONG_ERROR_MESSAGE, error: "error"};
        }
        return {message: "", error: null};
    }

    public OnChangeValue = (event) => {
        this.setState({
            [event.name]: event.value,
        });
    }

    public SelectedProfile = (ProfileData) => {
        let EmptyValue = this.state.EmptyValue;
        if (ProfileData.label !== undefined) {
            EmptyValue = undefined;
        }
        if ((ProfileData.ProfileName === "TargetUser") || (ProfileData.ProfileName === "SourceUser")) {
            if (ProfileData.ProfileName === "SourceUser") {
                if (EmptyValue === undefined) {
                    this.props.GetSourceProfiles(ProfileData.label);
                    if (ProfileData.label === undefined) {
                        EmptyValue = true;
                    }
                }
            }
            this.setState({
                EmptyValue,
                [ProfileData.ProfileName]: (ProfileData.label !== undefined ? ProfileData.label : ""),
            });
        } else {
            this.setState({
                [ProfileData.ProfileName]: ProfileData.value,
            });
        }
    }

    public SubmitLinkedProfile = () => {
        const LinkedProfileValues: any = {
            can_be_inspected: this.state.InspectProfile,
            description: this.state.ProfileDesc,
            link_to_profile_uuid: this.state.SourceProfile,
            notes: this.state.ProfileNotes,
            profile_name: this.state.ProfileName,
            shared_with_all_users: this.state.ShareProfile,
        };
        if (!this.props.EditMode && !this.props.ViewMode) {
            LinkedProfileValues.notes = this.state.ProfileNotes || "";
        } else {
            if (this.state.ProfileNotes !== undefined) {
                LinkedProfileValues.notes = this.state.ProfileNotes;
            }
        }
        if ((this.props.UserRights.administrator || this.props.UserRights.customer_admin) &&
        (this.state.TargetUser.length > 0)) {
            LinkedProfileValues.userid = this.state.TargetUser;
        }
        if (this.props.EditMode) {
            this.props.UpdateProfile(LinkedProfileValues);
        } else {
            this.props.CreateProfile(LinkedProfileValues);
        }
    }

    public render() {
        const CanInspectProfile = (this.props.UserRights.can_inspect_device_profile &&
            this.props.Profile === "DeviceProfiles") ||
            (this.props.UserRights.can_inspect_service_profile &&
            this.props.Profile === "ServiceProfiles") ||
            (this.props.UserRights.can_inspect_roaming_profile &&
            this.props.Profile === "RoamingProfiles") ||
            (this.props.UserRights.can_inspect_connectivity_profile &&
            this.props.Profile === "ConnectivityProfiles") ||
            (this.props.UserRights.can_inspect_qos_profile &&
            this.props.Profile === "QOSProfiles") ||
            (this.props.UserRights.can_inspect_channel_profile &&
            this.props.Profile === "ChannelProfiles");
        const schema: JSONSchema6 = this.state.FormSchema;
        const uiSchema: UiSchema = JsonUISchema({name: this.props.Profile, error: this.props.Error || null});
        if (this.props.FormData.can_be_inspected && (this.props.EditMode || this.props.ViewMode) &&
            (Object.keys(this.props.FormSchema).length > 0)) {
            Object.keys(this.props.FormSchema.properties).map((key) => {
                uiSchema[key] = { "ui:readonly": true };
            });
        }
        return(
            <div>
                <Modal
                    show={this.props.ShowModal}
                    onHide={this.props.HideModal}
                    bsSize="lg"
                    className="ReactModal"
                    backdrop="static"
                >
                    <Modal.Header closeButton={true}>
                        <Modal.Title>
                            {this.props.EditMode ?
                                `${strings.ACTION_EDIT_TITLE} ${this.props.ProfileName}` :
                                `${strings.ACTION_ADD_TITLE} ${this.props.ProfileName}`
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="padding_0_all ScrollableBody LinkedProfileModalBody">
                            <Row>
                                {this.state.Loading ? (
                                        <Col sm={12} className="LoaderWrapper">
                                            <i className="fa fa-spinner fa-spin fa-5x"></i>
                                        </Col>
                                    ) : (
                                        <Col sm={12} className="padding_0_all">
                                            <form className={
                                                ((this.props.EditMode || this.props.ViewMode) &&
                                                this.props.FormData.can_be_inspected && CanInspectProfile) ?
                                                    "LinkedProfileFormWithContent SuccessBorder" :
                                                    "LinkedProfileForm SuccessBorder"
                                                }
                                            >
                                                {((this.props.UserRights.administrator ||
                                                    this.props.UserRights.customer_admin) &&
                                                    (!this.props.ViewMode && !this.props.EditMode)) && (
                                                    <FormGroup
                                                        controlId="TargetUser"
                                                        className="padding_top_10"
                                                    >
                                                        <ControlLabel>
                                                            {strings.LINKED_PROFILE_MODAL_TARGET_USER}
                                                        </ControlLabel>
                                                        <SelectDropdown
                                                            Profiles={this.props.ProfileOwnerList}
                                                            ProfileName="TargetUser"
                                                            SelectedProfile={this.SelectedProfile}
                                                            CustomPlaceholder={
                                                                strings.LINKED_PROFILE_MODAL_TARGET_USER_PLACEHOLDER
                                                            }
                                                            DefaultValue={
                                                                ((this.state.TargetUser.length > 0) &&
                                                                    this.state.TargetUser)
                                                            }
                                                            Disabled={this.props.ViewMode ? true : false}
                                                            Remote={true}
                                                            RemoteFilter={this.props.FilterUsers}
                                                        />
                                                    </FormGroup>
                                                )}
                                                <FormGroup
                                                    controlId="ProfileName"
                                                    validationState={
                                                        this.CheckForErrors().error === "error" ? "error" : null
                                                    }
                                                >
                                                    <ControlLabel>{strings.TABLE_PROFILE_NAME}</ControlLabel>
                                                    <FormControl
                                                        type="text"
                                                        value={this.state.ProfileName}
                                                        onChange={(e) => this.OnChangeValue(e.target)}
                                                        name="ProfileName"
                                                        disabled={this.props.ViewMode ? true : false}
                                                        required={true}
                                                    />
                                                    {(this.CheckForErrors().error === "error") &&
                                                        <HelpBlock
                                                            className="react_required_custom_message"
                                                        >
                                                            {this.CheckForErrors().message}
                                                        </HelpBlock>
                                                    }
                                                </FormGroup>
                                                <FormGroup
                                                    controlId="ProfileDesc"
                                                >
                                                    <ControlLabel>{strings.TABLE_COMMENT}</ControlLabel>
                                                    <FormControl
                                                        componentClass="textarea"
                                                        value={this.state.ProfileDesc}
                                                        onChange={(e) => this.OnChangeValue(e.target) }
                                                        name="ProfileDesc"
                                                        disabled={this.props.ViewMode ? true : false}
                                                    />
                                                </FormGroup>
                                                {(((this.state.ProfileNotes !== undefined) &&
                                                (this.props.EditMode || this.props.ViewMode)) ||
                                                (!this.props.EditMode && !this.props.ViewMode)) && (
                                                    <FormGroup
                                                        controlId="ProfileNotes"
                                                    >
                                                        <ControlLabel>{strings.PROFILES_NOTES_FIELD}</ControlLabel>
                                                        <FormControl
                                                            componentClass="textarea"
                                                            value={this.state.ProfileNotes}
                                                            onChange={(e) => this.OnChangeValue(e.target) }
                                                            name="ProfileNotes"
                                                            disabled={this.props.ViewMode ? true : false}
                                                        />
                                                    </FormGroup>
                                                )}
                                                <hr className="horizontal_line" />
                                                {((this.props.UserRights.administrator ||
                                                    this.props.UserRights.customer_admin) &&
                                                    (!this.props.ViewMode && !this.props.ConvertToLinkedMode)) && (
                                                    <FormGroup
                                                        controlId="SourceUser"
                                                        className="padding_top_10"
                                                    >
                                                        <ControlLabel>
                                                            {strings.LINKED_PROFILE_MODAL_SOURCE_USER}
                                                        </ControlLabel>
                                                        <SelectDropdown
                                                            Profiles={this.props.ProfileOwnerList}
                                                            ProfileName="SourceUser"
                                                            SelectedProfile={this.SelectedProfile}
                                                            CustomPlaceholder={
                                                                strings.LINKED_PROFILE_MODAL_SOURCE_USER_PLACEHOLDER
                                                            }
                                                            DefaultValue={
                                                                ((this.state.TargetUser.length > 0) &&
                                                                    this.state.TargetUser)
                                                            }
                                                            MenuPosition={"top"}
                                                            Disabled={this.props.ViewMode ? true : false}
                                                            Remote={true}
                                                            RemoteFilter={this.props.FilterUsers}
                                                        />
                                                    </FormGroup>
                                                )}
                                                <FormGroup
                                                    controlId="SourceProfile"
                                                    className="padding_top_10"
                                                >
                                                    <ControlLabel>
                                                        {strings.LINKED_PROFILE_MODAL_SOURCE_PROFILE}
                                                    </ControlLabel>
                                                    <SelectDropdown
                                                        Profiles={this.props.SourceProfiles}
                                                        ProfileName="SourceProfile"
                                                        SelectedProfile={this.SelectedProfile}
                                                        DefaultValue={
                                                            (this.props.SelectedProfile && this.props.SelectedProfile)
                                                        }
                                                        Disabled={
                                                            (this.props.ViewMode ? (
                                                                this.props.ViewMode ? true : false
                                                            ) : (
                                                                this.props.DisableTargetProfile &&
                                                                this.props.DisableTargetProfile
                                                            ))
                                                        }
                                                        MenuPosition={"top"}
                                                        Remote={true}
                                                        RemoteFilter={this.props.FilterProfiles}
                                                        Required={true}
                                                    />
                                                    {(this.state.SourceProfile === "") &&
                                                        <HelpBlock
                                                            className="react_required_custom_message"
                                                        >
                                                            {strings.REQUIRED_PROPERTY_MESSAGE}
                                                        </HelpBlock>
                                                    }
                                                </FormGroup>
                                            </form>
                                            {(this.props.FormData.can_be_inspected && CanInspectProfile
                                            && (this.props.EditMode || this.props.ViewMode)) && (
                                                <div className="padding_left_right_30">
                                                    <hr className="horizontal_line margin_b_20" />
                                                    {(this.props.Profile === "ServiceProfiles") ? (
                                                        <div>
                                                            <h3 className="service_profile_legend">
                                                                {strings.SERVICE_PROFILE_LEGEND}
                                                            </h3>
                                                            <form>
                                                                <FormGroup
                                                                    controlId="ProfileDesc"
                                                                >
                                                                    <ControlLabel>
                                                                        {strings.SERVICE_PROFILE_CHANNEL}
                                                                    </ControlLabel>
                                                                    <SelectDropdown
                                                                        Profiles={[]}
                                                                        ProfileName="ChannelProfile"
                                                                        SelectedProfile={null}
                                                                        DefaultValue={
                                                                            this.state.SelectedProfile.ChannelProfile
                                                                        }
                                                                        Disabled={true}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup
                                                                    controlId="ProfileDesc"
                                                                >
                                                                    <ControlLabel>
                                                                        {strings.SERVICE_PROFILE_QoS}
                                                                    </ControlLabel>
                                                                    <SelectDropdown
                                                                        Profiles={[]}
                                                                        ProfileName="QoSProfile"
                                                                        SelectedProfile={null}
                                                                        DefaultValue={
                                                                            this.state.SelectedProfile.QoSProfile
                                                                        }
                                                                        Disabled={true}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup
                                                                    controlId="ProfileDesc"
                                                                >
                                                                    <ControlLabel>
                                                                        {strings.SERVICE_PROFILE_ROAMING}
                                                                    </ControlLabel>
                                                                    <SelectDropdown
                                                                        Profiles={[]}
                                                                        ProfileName="RoamingProfile"
                                                                        SelectedProfile={null}
                                                                        DefaultValue={
                                                                            this.state.SelectedProfile.RoamingProfile
                                                                        }
                                                                        Disabled={true}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup
                                                                    controlId="ProfileDesc"
                                                                >
                                                                    <ControlLabel>
                                                                        {strings.SERVICE_PROFILE_CONNECTIVITY}
                                                                    </ControlLabel>
                                                                    <SelectDropdown
                                                                        Profiles={[]}
                                                                        ProfileName="ConnectivityProfile"
                                                                        SelectedProfile={null}
                                                                        DefaultValue={
                                                                        this.state.SelectedProfile.ConnectivityProfile
                                                                        }
                                                                        Disabled={true}
                                                                    />
                                                                </FormGroup>
                                                            </form>
                                                        </div>
                                                    ) : (
                                                        (Object.keys(this.props.FormSchema).length > 0) && (
                                                            <Form
                                                                method="POST"
                                                                autocomplete="off"
                                                                schema={schema}
                                                                uiSchema={uiSchema}
                                                                liveValidate={false}
                                                                formData={this.props.FormData.content}
                                                                showErrorList={false}
                                                                className="DeviceProfileForm LinkedProfileFormContent"
                                                            >
                                                                <Button
                                                                    type="submit"
                                                                    style={{display: "none"}}
                                                                >
                                                                    Submit
                                                                </Button>
                                                            </Form>
                                                        )
                                                    )}
                                                </div>
                                            )}

                                            <div className="FormBtnsStyle">
                                                {((this.props.UserRights.is_customer &&
                                                (this.props.Profile !== "QOSProfiles" &&
                                                this.props.Profile !== "ChannelProfiles")) ||
                                                (this.props.UserRights.is_customer &&
                                                (this.props.Profile === "QOSProfiles" ||
                                                this.props.Profile === "ChannelProfiles") &&
                                                !this.props.UserRights.is_tenant)) && (
                                                    <Checkbox
                                                        className="pull-left ShareProfileCheckBox"
                                                        checked={this.state.ShareProfile}
                                                        onChange={() => {
                                                            this.setState({
                                                                ShareProfile: !this.state.ShareProfile,
                                                            });
                                                        }}
                                                        disabled={this.props.ViewMode ? true : false}
                                                    >
                                                        {strings.SHARE_PROFILE_CHECKBOX}
                                                    </Checkbox>
                                                )}
                                                <Checkbox
                                                    className="pull-left InspectProfileCheckBox"
                                                    checked={this.state.InspectProfile}
                                                    onChange={() => {
                                                        this.setState({
                                                            InspectProfile: !this.state.InspectProfile,
                                                        });
                                                    }}
                                                    disabled={this.props.ViewMode ? true : false}
                                                >
                                                    {strings.LINKED_PROFILE_MODAL_CAN_INSPECT_PROFILE}
                                                </Checkbox>
                                                <div className="pull-right">
                                                    {!this.props.ViewMode && (
                                                        <Button
                                                            type="submit"
                                                            className="React_new_style_btn_ok"
                                                            disabled={
                                                                ((this.CheckForErrors().error === null) &&
                                                                (this.state.SourceProfile.length > 0)) ? false : true
                                                            }
                                                            onClick={this.SubmitLinkedProfile}
                                                        >
                                                            {this.props.EditMode ? strings.ACTION_EDIT :
                                                            strings.ACTION_ADD}
                                                        </Button>
                                                    )}
                                                    <Button
                                                        onClick={this.props.HideModal}
                                                        className="React_new_style_btn_cancel"
                                                    >
                                                        {strings.CLOSE_BUTTON_TEXT}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                }
                            </Row>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
