(function (angular) {
    var ngBaiduMap = window.ngBaiduMap;

    angular.module('dassUiModule', ['ui.bootstrap', 'ngFileUpload', 'ngCookies', 'pascalprecht.translate', 'nemLogging', 'ui-leaflet', ngBaiduMap, 'angularjs-dropdown-multiselect', 'ngTable', 'daterangepicker', 'ngSanitize', 'ui.select']);
    var app = angular.module('dassUiModule');

    // this interceptor is used to catch every http response that has error
    // and if the error is 'Token has expired' to redirect the user to login page
    app.factory('sessionExpired', ['$q', function($q) {
        var sessionExpired = {
            responseError: function(response) {
                if (response.status == 401) { // if session has expired

                    window.location.href = '/app/signin';
                }
                return $q.reject(response);
            }
        };
        return sessionExpired;
    }]);

    app.config(['$logProvider', '$translateProvider', '$locationProvider', '$httpProvider', function ($logProvider, $translateProvider, $locationProvider, $httpProvider) {
        $logProvider.debugEnabled(false);
        $translateProvider.useSanitizeValueStrategy('sanitizeParameters');
        $translateProvider.useMessageFormatInterpolation();
        $translateProvider.preferredLanguage('en');
        $translateProvider.useSanitizeValueStrategy('escaped');
        $httpProvider.interceptors.push('sessionExpired');
    }]);

    app.run(['uibPaginationConfig', '$rootScope', '$filter', '$cookies', '$translate', function (uibPaginationConfig, $rootScope, $filter, $cookies, $translate) {	
        $rootScope.translatedPagination = uibPaginationConfig;
	    var language = $cookies.get('language') 

        $rootScope.$on('$translateChangeSuccess', function (test) {
            $translate = $filter('translate');
            uibPaginationConfig.firstText = $translate('FIRST');
            uibPaginationConfig.lastText = $translate('LAST');
            uibPaginationConfig.previousText = $translate('PREVIOUS');
            uibPaginationConfig.nextText = $translate('NEXT');
            $rootScope.translatedPagination = uibPaginationConfig;
        });
        $translate.use(language);
    }]);

})(angular);