// This controller is the controller for the legacy device edit modal view.
//
var app = angular.module('dassUiModule');

function nullOrUndefined(what) {
    return what === undefined || what === null;
}

// function nullOrUndefined(what) {
//     return what === undefined || what === null;
// }

const typeToUrlMap = {
    customer: "/uiapi/rest/customers",
    organization: "/uiapi/rest/organisations",
    user: "/uiapi/rest/users",
}


function DeviceFormController($filter, $scope, $rootScope, $window, $timeout, $element, $attrs, DeviceService, UserService, $uibModal, ToastService, DataService) {
    var $translate = $filter('translate');
    var toHex32 = $filter('toHex32');
    var vm = this;
    vm.debug = false;
    vm.constants = constants;
    const USERID_REGEX = /^([A-Za-zªµºÀ-ÖØ-öø-ƺƼ-ƿǄǆ-Ǉǉ-Ǌǌ-Ǳǳ-ʓʕ-ʯͰ-ͳͶ-ͷͻ-ͽΆΈ-ΊΌΎ-ΡΣ-ϵϷ-ҁҊ-ԣԱ-Ֆա-ևႠ-Ⴥᴀ-ᴫᵢ-ᵷᵹ-ᶚḀ-ἕἘ-Ἕἠ-ὅὈ-Ὅὐ-ὗὙὛὝὟ-ώᾀ-ᾇᾐ-ᾗᾠ-ᾧᾰ-ᾴᾶ-Άιῂ-ῄῆ-Ήῐ-ΐῖ-Ίῠ-Ῥῲ-ῴῶ-Ώⁱⁿℂℇℊ-ℓℕℙ-ℝℤΩℨK-ℭℯ-ℴℹℼ-ℿⅅ-ⅉⅎↃ-ↄⰀ-Ⱞⰰ-ⱞⱠ-Ɐⱱ-ⱼⲀ-ⳤⴀ-ⴥꙀ-ꙟꙢ-ꙭꚀ-ꚗꜢ-ꝯꝱ-ꞇꞋ-ꞌﬀ-ﬆﬓ-ﬗＡ-Ｚａ-ｚ]|\ud801[\udc00-\udc4f]|\ud835[\udc00-\udc54\udc56-\udc9c\udc9e-\udc9f\udca2\udca5-\udca6\udca9-\udcac\udcae-\udcb9\udcbb\udcbd-\udcc3\udcc5-\udd05\udd07-\udd0a\udd0d-\udd14\udd16-\udd1c\udd1e-\udd39\udd3b-\udd3e\udd40-\udd44\udd46\udd4a-\udd50\udd52-\udea5\udea8-\udec0\udec2-\udeda\udedc-\udefa\udefc-\udf14\udf16-\udf34\udf36-\udf4e\udf50-\udf6e\udf70-\udf88\udf8a-\udfa8\udfaa-\udfc2\udfc4-\udfcb]|[@\-\._]|[0-9])*$/;

    vm.activeTab = 0;

    vm.tabs = [
        'TAB_KEYS',
        'TAB_QOS',
        'TAB_PACKET_STORAGE',
        'TAB_LORA_PARAMETERS',
        'TAB_LORA_LOCATION',
        'TAB_TAGS'
    ];

    vm.users = [];

    // this is helper to be able to revert to previous tag name/desc when the user clicks on the cancel btn
    vm.oldTagName = '';
    vm.oldTagDesc = '';

    vm.editTagNameValidity = true;
    vm.editTag = function(index) {
        if (!vm.editTagNameValidity || !vm.validTagName) {
            // if some of the tags have incorrent tag name, forbid to edit some other tag

            return;
        }

        var counter = 0;
        for (var tagKey in vm.device.tags) {
            if (counter == index) {
                vm.oldTagName = tagKey;
                vm.oldTagDesc = vm.device.tags[tagKey];

                break;
            }

            counter++;
        }

        if (vm.editTagNameValidity) {
            vm.hideAllTagsInput();
        }

        // show corresponding buttons depending of which row is currently being edited
        var tagKey = document.querySelector('.tags-table .table-body .key-' + index);
        var tagDesc = document.querySelector('.tags-table .table-body .val-' + index);
        var tagEditBtns = document.querySelector('.tags-table .table-body .current-tag-edit-btns-' + index);
        var tagActionsBtns = document.querySelector('.tags-table .table-body .current-tag-action-btns-' + index);
        if (tagKey) {
            tagKey.setAttribute("tags", "show");
        }
        if (tagDesc) {
            tagDesc.setAttribute("tags", "show");
        }
        if (tagActionsBtns) {
            tagActionsBtns.setAttribute("tags", "show");
        }
        if (tagEditBtns) {
            tagEditBtns.setAttribute("tags", "show");
        }

        $timeout(function() {   // we need timeout for immediate effect, otherwise the focus wont fork
            try {
                document.querySelector('.tags-table .table-body .key-' + index + ' .tag-input-container').focus();
            }
            catch (e) {
                
            }
        }, 0);
    }

    vm.hideAllTagsInput = function() {
        var counter = 0;
        for (var tagKey in vm.device.tags) {

            // hide actions button for all tags
            var tagKey = document.querySelector('.tags-table .table-body .key-' + counter);
            var tagDesc = document.querySelector('.tags-table .table-body .val-' + counter);
            var tagEditBtns = document.querySelector('.tags-table .table-body .current-tag-edit-btns-' + counter);
            var tagActionsBtns = document.querySelector('.tags-table .table-body .current-tag-action-btns-' + counter);
            if (tagKey) {
                tagKey.setAttribute("tags", "hide");
            }
            if (tagDesc) {
                tagDesc.setAttribute("tags", "hide");
            }
            if (tagActionsBtns) {
                tagActionsBtns.setAttribute("tags", "hide");
            }
            if (tagEditBtns) {
                tagEditBtns.setAttribute("tags", "hide");
            }
            
            counter++;
        }
    }

    vm.deleteTag = function(index) {
        var counter = 0;
        for (var tagKey in vm.device.tags) {
            if (counter == index) {
                delete vm.device.tags[tagKey];
                break;
            }
            counter++;
        }
    }

    vm.newTagKeyVal = '';
    vm.editTagKey = function(newTagKey, index) {
        var deviceTags = {};
        var counter = 0;
        for (var tagKey in vm.device.tags) {
            if (counter == index) {
                var bFlag = false;
                var secondCounter = 0;
                for (var currentTagKey in vm.device.tags) {
                    if (currentTagKey == newTagKey && counter != secondCounter) {
                        bFlag = true;

                        break;
                    }

                    secondCounter++;
                }

                if (bFlag) {
                    deviceTags[tagKey] = vm.device.tags[tagKey];
                    vm.newTagKeyVal = newTagKey;
                    document.querySelector('.same-tag-name-' + counter).setAttribute("tags", "show");

                    $rootScope.$broadcast('updateDeviceNewTagName', '%&');
                }
                else {
                    deviceTags[newTagKey] = vm.device.tags[tagKey];
                    vm.newTagKeyVal = '';
                    document.querySelector('.same-tag-name-' + counter).setAttribute("tags", "hide");

                    $rootScope.$broadcast('updateDeviceNewTagName', '');
                }

                var patt = /^[a-zA-Z\d\-_]*$/;
                var result = newTagKey.match(patt);
                if (!result) {
                    vm.editTagNameValidity = false;
                    document.querySelector('.popover-tag-' + counter).setAttribute("tags", "show");
                }
                else {
                    vm.editTagNameValidity = true;
                    document.querySelector('.popover-tag-' + counter).setAttribute("tags", "hide");
                }
            }
            else {
                deviceTags[tagKey] = vm.device.tags[tagKey];
            }

            counter++;
        }

        vm.device.tags = deviceTags;
//        $rootScope.$broadcast('updateDeviceTags', vm.device.tags);
    }

    vm.editTagVal = function(newTagVal, index) {
        var counter = 0;
        for (var tagKey in vm.device.tags) {
            if (counter == index) {
                vm.device.tags[tagKey] = newTagVal;
                break;
            }

            counter++;
        }

//        $rootScope.$broadcast('updateDeviceTags', vm.device.tags);
    }

    vm.sameTagName = false;
    vm.newTagName = "";
    vm.newTagDesc = "";
    vm.insertTag = function() {
        var bFlag = false;
        for (var tagKey in vm.device.tags) {
            if (tagKey == vm.newTagName) {
                bFlag = true;

                break;
            }
        }

        vm.sameTagName = bFlag;
        if (bFlag) {
            return;
        }

        if (vm.newTagName != '' && vm.newTagDesc != '' && vm.validTagName) {
            if (vm.device && vm.device.tags) {
                vm.device.tags[vm.newTagName] = vm.newTagDesc;
            }
            else {
                vm.device.tags = {};
                vm.device.tags[vm.newTagName] = vm.newTagDesc;
            }
    
            vm.newTagName = "";
            vm.newTagDesc = "";
    
            document.querySelector('.new-tag.name').focus();
//            $rootScope.$broadcast('updateDeviceTags', vm.device.tags);
        }
        else if (!vm.validTagName && vm.newTagDesc != '') {
            document.querySelector('.new-tag.name').focus();
        }
        else if (vm.newTagDesc == '') {
            document.querySelector('.new-tag.desc').focus();
        }
        else if (vm.newTagName == '' && vm.newTagDesc == '') {
            document.querySelector('.new-tag.name').focus();
        }
    }

    vm.clearTagsInput = function() {
        vm.newTagName = "";
        vm.newTagDesc = "";
        vm.validTagName = true;
        vm.sameTagName = false;

        $timeout(function() {   // this is needed to immediately send the change to app-modals.js, because the Update btn function is there - and this value show us if the button should be enabled or disabled

            // with broadcast, I am informing to the parent component that there is a change so that component can re-render again
            $rootScope.$broadcast('updateDeviceNewTagName', vm.newTagName);

        }, 0);
    }

    vm.validTagName = true;
    vm.enterOnNewTag = function(event, inputToFocus) {
        $timeout(function() {   // // hack with timeout to immediately display/hide the tooltip if there are same tag names
            
            vm.user.newTagName = vm.newTagName;

            var bFlag = false;
            for (var tagKey in vm.device.tags) {
                if (tagKey == vm.newTagName) {
                    bFlag = true;

                    break;
                }
            }

            vm.sameTagName = bFlag;

        }, 0);

        if(event.keyCode == 13) {   // '13' is the key code for enter
            event.preventDefault();

            if (vm.newTagName != '' && vm.newTagDesc != '') {
                vm.insertTag();
            }
            else if (vm.newTagName != '' && inputToFocus == 'newTagDesc') {     // move the focus to tag description input
                document.querySelector('.new-tag.desc').focus();
            }
            else if (vm.newTagDesc != '' && inputToFocus == 'newTagName') {     // move the focus to tag name input
                document.querySelector('.new-tag.name').focus();
            }

            $timeout(function() {
                document.querySelector('.tags-table').click();
            }, 0);
        }
        else if (inputToFocus == 'newTagDesc') {
            $timeout(function() {   // hack with timeout to immediately display/hide the tooltip if there is and error in the tag name

                // with broadcast, I am informing to the parent component that there is a change so that component can re-render again
                $rootScope.$broadcast('updateDeviceNewTagName', vm.newTagName);

                var patt = /^[a-zA-Z\d\-_]*$/;
                var result = vm.newTagName.match(patt);
                if (!result) {
                    vm.validTagName = false;
                }
                else {
                    vm.validTagName = true;
                }

            }, 0);
        }
    }

    vm.enterOnTagName = function(event, tagPositionNumber) {
        if(event.keyCode == 13) {   // '13' is the key code for enter
            event.preventDefault();

            if (vm.newTagKeyVal != '') {    // if the edited tag name already exist, don`t hide the input box

                $rootScope.$broadcast('updateDeviceNewTagName', '%&');
                return;
            }
            else {
                $rootScope.$broadcast('updateDeviceNewTagName', '');
            }

            if (vm.editTagNameValidity) {
                vm.hideAllTagsInput();
            }
        }
        else if (event.keyCode != 9 && tagPositionNumber >= 0) {
            vm.editTagNameValidity = true;
            document.querySelector('.popover-tag-' + tagPositionNumber).setAttribute("tags", "hide");
        }
    }

    vm.saveTag = function() {
        if (vm.newTagKeyVal != '') {    // if the edited tag name already exist, don`t hide the input box

            $rootScope.$broadcast('updateDeviceNewTagName', '%&');
            return;
        }
        else {
            $rootScope.$broadcast('updateDeviceNewTagName', '');
        }

        if (vm.editTagNameValidity) {
            vm.hideAllTagsInput();
        }
    }

    vm.clearCurrentTagInput = function(index) {
        var newTags = {};
        var counter = 0;
        for (var tagKey in vm.device.tags) {
            if (counter == index) {
                newTags[vm.oldTagName] = vm.oldTagDesc;

                vm.editTagNameValidity = true;
                vm.user.newTagName = '';
                vm.newTagKeyVal = '';
                document.querySelector('.popover-tag-' + counter).setAttribute("tags", "hide");
                document.querySelector('.same-tag-name-' + counter).setAttribute("tags", "hide");
            }
            else {
                newTags[tagKey] = vm.device.tags[tagKey];
            }

            counter++;
        }

        vm.device.tags = newTags;

    }

    vm.showUsers = () => {
        const modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'showUsers.html',
            controller: 'DataSelectController',
            size: "lg",
            resolve: {
                items: () => {
                    return {
                        dataType: '/uiapi/rest/users',
                        title: "USER_LIST_TITLE",
                        actions: [],
                        bulkActions: [],
                        columns: [{
                            key: "userid",
                            type: "text",
                            title: "USER_ID",
                            filterable: true,
                            filterType: 'text',
                            filterField: 'search_id',
                            filterParams: {
                                mapper: (x) => x.toLowerCase() || undefined,
                                validation: (field, value) => {
                                    console.log("Check if user exists ", value);
                                    return new Promise((resolve, reject) => {
                                        if (!value.match(USERID_REGEX)) resolve(false);
                                        DataService.getData('/uiapi/rest/users', { search_id: value, get_pages: true }).then(data => {
                                            resolve(data.total > 0);
                                        }).catch(err => {
                                            resolve(false);
                                        });
                                    })
                                }
                            }
                        }, {
                            key: "administrator",
                            title: "Administrator",
                            type: "text",
                            render: (user) => user.administrator ? $translate("YES") : $translate("NO"),
                        }, {
                            key: "can_register",
                            title: "Registration Rights",
                            type: "text",
                            render: (user) => user.can_register ? $translate("YES") : $translate("NO"),
                        }],
                        users: vm.users,
                        owner: vm.user,
                    }
                }
            }
        });

        modalInstance.result.then((selectedUsers) => {
            if (selectedUsers.length > 0) {
                vm.device.userid = selectedUsers[0].userid;
            }
        }, () => {
            console.log("Dismissed");
        });

    }

    vm.RootUser = undefined;
    vm.AssignToParentMessage = $translate('CHANGE_OWNER_ASSIGN_TO_PARENT', {
        parentId: vm.RootUser || "",
    });
    vm.GetParentId = (userData) => {
        if (!userData.is_customer && userData.administrator) {
            UserService.getAllUserData(userData.userid).then((response) => {
                if ((response.status === 200) && (response.data !== undefined) && (response.data.userid !== undefined)) {
                    vm.RootUser = response.data.userid;
                    vm.AssignToParentMessage = $translate('CHANGE_OWNER_ASSIGN_TO_PARENT', {
                        parentId: vm.RootUser || "",
                    });
                } else {
                    vm.RootUser = undefined;
                }
            }).catch(err => {
                vm.RootUser = undefined;
                console.log(err);
            });
        } else {
            vm.RootUser = undefined;
        }
    };

    vm.ChangeOwner = (UserId) => {
        console.log(UserId);
        if (UserId !== undefined) {
            const DeviceData = Object.assign({}, vm.resultDevice);
            DeviceData.userid = UserId;
            vm.myModalInstance.close(DeviceData);
        }
    }
    vm.SelectedUserType = "user";
    vm.ChangeUserType = (UserType) => {
        vm.SelectedUserType = UserType;
        console.log(UserType);
        vm.refreshDa();
        setTimeout(() => {
            vm.refreshDa();
        }, 200);
    }
    vm.showOwnerTable = false;
    vm.workingCount = 0;
    vm.refreshDa = () => {
        vm.loadUsers = (type) => {
            vm.type = type;
            console.log("Loading users of type", type);
            vm.workingCount += 1
            UserService.getUsers(vm.type).then(
                (user) => {
                    vm.users = user;
                    vm.workingCount -= 1
                }
            ).catch(err => {
                vm.workingCount -= 1
                ToastService.showMessage($translate(err.data), "error")
                ToastService.showMessage($translate('MSG_LOGIN_FORWARD_FAILED'), "error")
            });
        };      

        vm.reloadData();
        vm.initDataTable();
        Promise.all([vm.loadUsers()]).then(vm.initDataTable);
    }
    vm.reloadData = () => {
        console.log("This function should be overwritten by the component before it gets called");
    };

    vm.showUsersTable = () => {
        if (vm.showOwnerTable === false) {
            vm.showOwnerTable = true;
            vm.tableSelectionChanged = (items) => {
                console.log("Selection changed ", items);

                vm.selectedItems = items;

                if (vm.selectedItems.length > 0) {
                    vm.device.userid = vm.selectedItems[0].userid;
                }

            };
            vm.loadUsers = (type) => {
                vm.type = type;
                console.log("Loading users of type", type);
                UserService.getUsers(vm.type).then(
                    (user) => {
                        vm.users = user;

                    }
                ).catch(err => {
                    ToastService.showMessage($translate(err.data), "error")
                    ToastService.showMessage($translate('MSG_LOGIN_FORWARD_FAILED'), "error")
                });
            };

            
            vm.initDataTable = () => {
                vm.title = "USER_LIST_TITLE";
                vm.columns = [{
                    key: "userid",
                    type: "text",
                    title: "USER_ID",
                    filterable: true,
                    filterType: 'text',
                    filterField: 'search_id',
                    filterParams: {
                        mapper: (x) => x.toLowerCase() || undefined,
                        validation: (field, value) => {
                            console.log("Check if user exists ", value);
                            return new Promise((resolve, reject) => {
                                if (value !== undefined) {
                                    if (!value.match(USERID_REGEX)) resolve(false);
                                    DataService.getData(typeToUrlMap[vm.SelectedUserType], { search_id: value, get_pages: true }).then(data => {
                                        if (data.valid != undefined && !data.valid) {
                                            resolve(false);    
                                        }
                                        else {
                                            resolve(true);
                                        }
                                    }).catch(err => {
                                        resolve(false);
                                    });
                                } else {
                                    resolve(true);
                                }
                            })
                        }
                    }
                }, {
                    key: "administrator",
                    title: "Administrator",
                    filterField: 'search_administrator',
                    filterable: true,
                    filterType: 'boolean_toggle',
                    type: "text",
                    render: (user) => user.administrator ? $translate("YES") : $translate("NO"),
                }, {
                    key: "can_register",
                    title: "Registration Rights",
                    type: "text",
                    filterField: 'search_can_register',
                    filterable: true,
                    filterType: 'boolean_toggle',
                    render: (user) => user.can_register ? $translate("YES") : $translate("NO"),
                }];
                vm.actions = [];
                vm.bulkActions = [];
                vm.dataType = typeToUrlMap[vm.SelectedUserType];

            };
            vm.initDataTable();
            Promise.all([vm.loadUsers()]).then(vm.initDataTable);

        }
        else {
            vm.showOwnerTable = false;
        }
    }







    vm.loadUsers = () => {
        var userErr = (err) => {
            // ToastService.showMessage($translate("MSG_ERROR_LOADING_USERS"), "error");
            console.error("Couldn't load users ", err);
        };
        UserService.getUserData().then(userData => {
            vm.owner = userData.user;
            vm.user = userData.user;
            vm.GetParentId(userData.user);
            if (!vm.user.administrator) {
                return;
            } else {
                vm.tabs.push('TAB_MISCELLANIOUS');
            }
            UserService.getUsers('user')
                .then(users => {
                    vm.users = vm.users.concat(users);
                    console.log("Loading users ", users);
                })
                .then(() => userData.user.customer_admin && UserService.getUsers('customer'))
                .then(users => {
                    vm.users = vm.users.concat(users);

                    console.log("Loading customers ", users);
                })
                .catch(userErr);
        }).catch(userErr);
    }

    // Reference: http://dotansimha.github.io/angularjs-dropdown-multiselect/docs/#/main
    vm.userSelectSettings = {
        displayProp: "userid",
        searchField: "userid",
        dynamicTitle: true,
        enableSearch: true,
        selectionLimit: 1,
        scrollableHeight: '100px',
        scrollable: true
    }

    vm.usersTranslations = {
        dynamicButtonTextSuffix: $translate("USER_SELECTED_SUFFIX"),
        buttonDefaultText: $translate("USER_SELECT_ASSIGN_BUTTON"),
        checkAll: $translate("USER_SELECT_ALL"),
        uncheckAll: $translate("USER_SELECT_NONE"),
    }

    vm.loadUsers();

    vm.selectedUser = [];

    vm.selectEvents = {
        onItemDeselect: (item) => {
            console.log("onDeviceDeSelectEvent");
        },
        onItemSelect: (item) => {
            console.log("onDeviceSelectEvent");
        }
    }

    // Logic from AddDeviceModalCtrl
    var lora_rx2_sf = (vm.constants && vm.constants.default_rx2_sf) || "sf12";

    vm.changed = false;
    vm.loraloc_ena = undefined;

    vm.slot2sf = [
        { tag: "SF7" },
        { tag: "SF8" },
        { tag: "SF9" },
        { tag: "SF10" },
        { tag: "SF11" },
        { tag: "SF12" }
    ];

    var dev_prop_mobility = 0;
    var dev_prop_location = 0;

    function keycmp(obj, key) {
        if ((vm.deviceToEdit[key] || "").toLowerCase() !== (vm.device[key] || "").toLowerCase()) {
            obj[key] = (vm.device[key] || "").toLowerCase();
            return true;
        }
        return false;
    }

    function valcmp(obj, key) {
        if (vm.deviceToEdit[key] != vm.device[key]) {
            obj[key] = vm.device[key];
            return true;
        }
        return false;
    }


    vm.accept = () => {
        console.log("accept")
    }

    vm.cancel = () => {
        console.log("Cancel")
    }

    vm.macVersions = [
        { version: "1.0.0" },
        { version: "1.0.1" },
        { version: "1.0.2" },
        { version: "1.0.3" },
        { version: "1.0.4" },
        { version: "1.1.1" },
    ]

    vm.device = {};

    vm.init = (device) => {
        vm.editMode = !nullOrUndefined(device);
        var numQoS = (vm.constants  && vm.constants.num_qos) || 4;
        var qosIdx;
        vm.qoslist = [];
        for (qosIdx = 0; qosIdx < numQoS; qosIdx++) {
            vm.qoslist.push(qosIdx);
        }

        if (!vm.batch) {
            vm.device = {
                deveui: "",
                comment: "",
                appeui: "",
                joineui: "",
                qos_class: 0,
                activation: "otaa",
                devaddr: "",
                appkey: "",
                appskey: "",
                nwkskey: "",
                nwkkey: "",
                snwksintkey: "",
                fnwksintkey: "",
                nwksenckey: "",
                device_prop_mobility: 0,
                device_prop_location: 0,
                max_allowed_dutycycle: (vm.constants && vm.constants.max_duty_cycle) || 0,
                expected_avr_dutycycle: 0,
                redundant_uplink_cnt: 0,
                lora_device_class: 0,
                ownerid: "",
                lora_rx2_sf: lora_rx2_sf.toUpperCase(),
                lora_rx_delay1: 1,
                lora_fcnt_32bit: true,
                expiry_time_downlink: 168,
                expiry_time_uplink: 168,
                options: "",
                MACVersion: "1.0.2",
                RegParamsRevision: "A",
                activated: true,
                downlink_allowed: true,
                MaxEIRP: vm.constants.default_max_eirp != undefined ? vm.constants.default_max_eirp : 16,
                lora_location: false,
                tags: {},
            }
        }

        vm.changeMACVersion = function () {
            if (vm.device.MACVersion == "1.0.2") {
                vm.device.RegParamsRevision = "B";
            }
        }
        vm.changeMACVersion();

        vm.showStatus = function () {
            let frm = $scope.devform;
            let dif = false;
            vm.valid = true;

            if ((vm.device.MACVersion === "1.0.0" || vm.device.MACVersion === "1.0.1" || vm.device.MACVersion === "1.0.3") &&
                (vm.device.RegParamsRevision !== "A")) {
                vm.device.RegParamsRevision = "A";
            }
            if ((vm.device.MACVersion === "1.0.2") &&
                (vm.device.RegParamsRevision !== "A" && vm.device.RegParamsRevision !== "B")) {
                vm.device.RegParamsRevision = "A";
            }
            if ((vm.device.MACVersion === "1.0.4" || vm.device.MACVersion === "1.1.1") &&
                (vm.device.RegParamsRevision !== "RP002-1.0.0" && vm.device.RegParamsRevision !== "RP002-1.0.1" && vm.device.RegParamsRevision !== "RP002-1.0.2")) {
                vm.device.RegParamsRevision = "RP002-1.0.0";
            }

            // basic sanity check
            if (frm.deveui || frm.batchDevices) {
                if ((!vm.batch && frm.deveui.$invalid) || frm.maxduty.$invalid || frm.avrduty.$invalid || frm.appeui.$invalid || frm.joineui.$invalid) {
                    vm.valid = false;
                    return;
                }

                var options;
                var oo = vm.device.options;

                if (typeof oo === "string" && oo != "") {
                    oo = oo.toLocaleLowerCase().trim();
                    if (oo.length >= 2 && oo.substring(0, 2) == "0x") {
                        oo = oo.substring(2);
                    }
                    try {
                        options = parseInt(oo, 16);
                    } catch (e) { }
                }

                // Check validity of all parameters.
                if (vm.device.activation == "abp" && vm.device.MACVersion == '1.1.1') {
                    if (frm.devaddr.$invalid || frm.appskey.$invalid || frm.snwksintkey.$invalid
                        || frm.fnwksintkey.$invalid || frm.nwksenckey.$invalid) {
                        vm.valid = false;
                        return;
                    }
                } else if (vm.device.activation == "abp") {
                    if (frm.devaddr.$invalid || frm.nwkskey.$invalid || frm.appskey.$invalid) {
                        vm.valid = false;
                        return;
                    }
                } else if (vm.device.activation == "otaa" && vm.device.MACVersion == '1.1.1') {
                    if ((frm.appkey.$invalid || frm.nwkkey.$invalid) || (!frm.appkey.$invalid && vm.device.appkey !== "" && vm.device.nwkkey === "")) {
                        vm.valid = false;
                        return;
                    }
                } else {
                    if (frm.appkey.$invalid) {
                        vm.valid = false;
                        return;
                    }
                }

                if (frm.MaxEIRP.$invalid || frm.maxduty.$invalid || frm.avrduty.$invalid || frm.downlink_expiry.$invalid || frm.uplink_expiry.$invalid) {
                    vm.valid = false;
                    return;
                }

                if (device != null) {

                    var obj = {},
                        warn = {},
                        text;
                    dif = vm.compare(obj, warn);

                    obj.deveui = device.deveui;


                    if (obj.nwkskey != null && obj.devaddr == null) {
                        obj.devaddr = device.devaddr;
                    } else if (obj.devaddr != null && obj.nwkskey == null) {
                        obj.nwkskey = device.nwkskey;
                    }

                    if (obj.options != null) {
                        obj.options = parseInt(obj.options, 16);
                    }

                    if (warn.diff_reregister) {
                        if (device.device_status >= 2) {
                            text = $translate('WARNING_DEVICE_STATUS_LT2');
                        } else {
                            text = $translate('WARNING_DEVICE_STATUS_OTHER');
                        }
                    } else if (warn.diff_danger) {
                        if (device.device_status >= 2) {
                            text = $translate('WARNING_DIFF_DANGER');
                        }
                    }
                }

            }
            else {
            
            }
            var prop1 = ["", "static", "mobile"][vm.device.device_prop_mobility];
            var prop2 = ["", "outdoor", "indoor"][vm.device.device_prop_location];
            var prop = [prop1, prop2].filter(x => x).join(",") || (vm.batch ? undefined : '');
            var devregobj = {
                deveui: vm.device.deveui,
                comment: vm.device.comment != "" ? vm.device.comment : "",
                lora_device_class: vm.device.lora_device_class,
                lora_fcnt_32bit: vm.device.lora_fcnt_32bit,
                lora_rx_delay1: vm.device.lora_rx_delay1 ? vm.device.lora_rx_delay1 : undefined,
                lora_rx_delay2: vm.device.lora_rx_delay1 ? vm.device.lora_rx_delay1 + 1 : undefined,
                lora_major: vm.batch ? undefined : 0,
                lora_location: vm.device.lora_location,
                lora_rx2_sf: (vm.device.lora_rx2_sf && vm.device.lora_rx2_sf.toLocaleLowerCase()) ||
                                 (vm.batch ? undefined : vm.slot2sf[0].tag),
                expiry_time_uplink: vm.device.expiry_time_uplink,
                expiry_time_downlink: vm.device.expiry_time_downlink,
                redundant_uplink_cnt: vm.device.redundant_uplink_cnt,
                max_allowed_dutycycle: vm.device.max_allowed_dutycycle != null ? vm.device.max_allowed_dutycycle : undefined,
                expected_avr_dutycycle: vm.device.expected_avr_dutycycle != null ? vm.device.expected_avr_dutycycle : undefined,
                device_properties: prop,
                qos_class: vm.device.qos_class,
                options: options,
                activation: vm.device.activation,
                activated: vm.device.activated,
                downlink_allowed: vm.device.downlink_allowed,
                MaxEIRP: vm.device.MaxEIRP,
                RegParamsRevision: vm.device.RegParamsRevision,
                userid: vm.device.userid,
                MACVersion: vm.device.MACVersion == '' ? '1.0.0' : vm.device.MACVersion, // The MACVersion needs to be an empty string for HTML to render it default.
                tags: vm.device.tags,
            };

            if (vm.device.activation == "abp" && vm.device.MACVersion === '1.1.1') {
                devregobj.devaddr = vm.device.devaddr;
                devregobj.appskey = vm.device.appskey != "" ? vm.device.appskey : "";
                devregobj.appeui = vm.device.appeui != "" ? vm.device.appeui : undefined;
                devregobj.fnwksintkey = vm.device.fnwksintkey;
                devregobj.snwksintkey = vm.device.snwksintkey;
                devregobj.nwksenckey = vm.device.nwksenckey;

            } else if (vm.device.activation == "abp") {
                devregobj.devaddr = vm.device.devaddr;
                devregobj.nwkskey = vm.device.nwkskey;
                devregobj.appskey = vm.device.appskey != "" ? vm.device.appskey : "";
                devregobj.appeui = vm.device.appeui != "" ? vm.device.appeui : undefined;

            } else if (vm.device.activation == "otaa" && vm.device.MACVersion === '1.1.1') {
                devregobj.appkey = vm.device.appkey != "" ? vm.device.appkey : "";
                devregobj.nwkkey = vm.device.nwkkey != "" ? vm.device.nwkkey : "";
                devregobj.joineui = vm.device.joineui != "" ? vm.device.joineui : "";
            } else {
                devregobj.appkey  = vm.device.appkey  != "" ? vm.device.appkey : "";
                devregobj.joineui = vm.device.joineui != "" ? vm.device.joineui : "";
            }
            angular.copy(devregobj, vm.resultDevice);
            vm.changed = dif;
            return vm.debug ? JSON.stringify(obj) : "";
        };

        vm.resultModifications = {
            deveui: false,
            comment: false,
            appeui: false,
            joineui:false,
            qos_class: false,
            activation: false,
            devaddr: false,
            appkey: false,
            appskey: false,
            nwkskey: false,
            nwkkey: false,
            snwksintkey: false,
            fnwksintkey: false,
            nwksenckey: false,
            device_properties: false,
            max_allowed_dutycycle: false,
            expected_avr_dutycycle: false,
            redundant_uplink_cnt: false,
            lora_device_class: false,
            lora_rx2_sf: false,
            lora_rx_delay1: false,
            lora_fcnt_32bit: false,
            expiry_time_downlink: false,
            expiry_time_uplink: false,
            options: false,
            activated: false,
            downlink_allowed: false,
            MaxEIRP: false,
            RegParamsRevision: false,
            MACVersion: false
        };

        vm.title = device == null ? $translate('ADD_NEW_DEVICE_BTN') : $translate('EDIT_DEVICE_BTN');
        vm.okbutton = device == null ? $translate('ADD_DEVICE_BTN') : $translate('UPDATE_DEVICE_BTN');
        vm.appeui = device != null ? device.appeui : null;
        vm.joineui = device != null ? device.joineui : null;
        vm.loraloc_ena = vm.owner.loraloc_enable == true;
        if (device != null) {

            var i;
            var cnv = {
                "static": function () {
                    dev_prop_mobility = 1;
                },
                "mobile": function () {
                    dev_prop_mobility = 2;
                },
                "outdoor": function () {
                    dev_prop_location = 1;
                },
                "indoor": function () {
                    dev_prop_location = 2;
                },
            };

            if (typeof device.device_properties === "string") {
                var lst = device.device_properties.split(",");
                for (i = 0; i < lst.length; i++) {
                    if (cnv[lst[i].toLowerCase()]) {
                        cnv[lst[i].toLowerCase()]();
                    }
                }
            }

            vm.device.device_prop_mobility = dev_prop_mobility;
            vm.device.device_prop_location = dev_prop_location;

            device.deveui = device.deveui || "";
            device.appeui = device.appeui || "";
            device.joineui = device.joineui || "";
            device.comment = device.comment || "";
            device.appkey = device.appkey || "";
            device.appskey = device.appskey || "";
            device.nwkskey = device.nwkskey || "";
            device.nwkkey = device.nwkkey || "";
            device.fnwksintkey = device.fnwksintkey || "";
            device.snwksintkey = device.snwksintkey || "";
            device.nwksenckey = device.nwksenckey || "";

            if (device.nwkskey.length > 0 && device.nwkskey.length < 32) {
                device.nwkskey = "";
            }

            if (typeof device.devaddr === "number") {
                device.devaddr = toHex32(device.devaddr).toUpperCase();
            }

            if (typeof device.options === "number") {
                device.options = device.options.toString(16);
                device.options = (("00000000" + device.options).slice(-8)).toUpperCase();
                if(!device.deveui){device.options = ''};
            } else {
                device.options = "";
            }

            if(!device.devaddr){device.devaddr = '00000000'};
            if(!device.deveui){device.devaddr = ''};
            vm.device.deveui = device.deveui.toUpperCase();
            vm.device.appeui = device.appeui.toUpperCase();
            vm.device.joineui = device.joineui.toUpperCase();
            vm.device.comment = device.comment;
            vm.device.ownerid = device.ownerid;
            vm.device.userid = device.ownerid;
            vm.device.activation = device.activation || 'otaa';
            vm.device.appkey = device.appkey.toUpperCase();
            vm.device.appskey = device.appskey.toUpperCase();
            vm.device.nwkskey = device.nwkskey.toUpperCase();
            vm.device.nwkkey = device.nwkkey.toUpperCase();
            vm.device.fnwksintkey = device.fnwksintkey.toUpperCase();
            vm.device.snwksintkey = device.snwksintkey.toUpperCase();
            vm.device.nwksenckey = device.nwksenckey.toUpperCase();
            vm.device.devaddr = device.devaddr;

            vm.device.expiry_time_uplink = device.expiry_time_uplink;
            vm.device.expiry_time_downlink = device.expiry_time_downlink;

            vm.device.max_allowed_dutycycle = device.max_allowed_dutycycle;
            vm.device.expected_avr_dutycycle = device.expected_avr_dutycycle;
            vm.device.redundant_uplink_cnt = device.redundant_uplink_cnt;
            vm.device.qos_class = device.qos_class;

            vm.device.lora_device_class = device.lora_device_class;
            vm.device.lora_fcnt_32bit = device.lora_fcnt_32bit;
            vm.device.lora_rx_delay1 = device.lora_rx_delay1;
            vm.device.lora_rx2_sf = device.lora_rx2_sf.toUpperCase();
            vm.device.options = device.options;
            vm.device.activated = nullOrUndefined(device.activated) ? true : device.activated; // true if not set
            vm.device.downlink_allowed = nullOrUndefined(device.downlink_allowed) ? true : device.downlink_allowed;
            vm.device.MaxEIRP = device.MaxEIRP;
            vm.device.RegParamsRevision = device.RegParamsRevision;
            vm.device.MACVersion = device.MACVersion;
            device.lora_location = (typeof device.lora_location === "boolean" ? device.lora_location : false);

            vm.device.lora_location = device.lora_location;
            vm.device.tags = Object.assign({}, device.tags);
        }

    }

    vm.compare = function (obj, warn) {
        if (!vm.deviceToEdit) {
            return true;
        }

        var diff_fatal = false;
        var diff_reregister = false;
        var diff_danger = false;
        var diff_safe = false;

        warn.diff_fatal = (vm.resultModifications.deveui = keycmp(obj, "deveui", "deveui"));

        vm.resultModifications.appkey      = keycmp(obj, "appkey");
        vm.resultModifications.appskey     = keycmp(obj, "appskey");
        vm.resultModifications.nwkskey     = keycmp(obj, "nwkskey");
        vm.resultModifications.nwkkey      = keycmp(obj, "nwkkey");
        vm.resultModifications.fnwksintkey = keycmp(obj, "fnwksintkey");
        vm.resultModifications.snwksintkey = keycmp(obj, "snwksintkey");
        vm.resultModifications.nwksenckey  = keycmp(obj, "nwksenckey");
        vm.resultModifications.activation  = valcmp(obj, "activation");
        vm.resultModifications.devaddr     = valcmp(obj, "devaddr");

        warn.diff_reregister = vm.resultModifications.appkey || vm.resultModifications.appskey || vm.resultModifications.nwkskey ||
            vm.resultModifications.activation || vm.resultModifications.devaddr ||
            vm.resultModifications.fnwksintkey || vm.resultModifications.snwksintkey || vm.resultModifications.nwkkey || vm.resultModifications.nwksenckey;

        vm.resultModifications.lora_fcnt_32bit = valcmp(obj, "lora_fcnt_32bit");
        vm.resultModifications.lora_rx_delay1  = valcmp(obj, "lora_rx_delay1");
        vm.resultModifications.lora_rx2_sf     = keycmp(obj, "lora_rx2_sf");

        warn.diff_danger = vm.resultModifications.lora_fcnt_32bit || vm.resultModifications.lora_rx_delay1 || vm.resultModifications.lora_rx2_sf;

        vm.resultModifications.comment = valcmp(obj, "comment", "comment");
        vm.resultModifications.expiry_time_uplink   = valcmp(obj, "expiry_time_uplink");
        vm.resultModifications.expiry_time_downlink = valcmp(obj, "expiry_time_downlink");

        vm.resultModifications.max_allowed_dutycycle  = valcmp(obj, "max_allowed_dutycycle");
        vm.resultModifications.expected_avr_dutycycle = valcmp(obj, "expected_avr_dutycycle");
        vm.resultModifications.lora_device_class      = valcmp(obj, "lora_device_class");
        vm.resultModifications.qos_class              = valcmp(obj, "qos_class");
        vm.resultModifications.redundant_uplink_cnt   = valcmp(obj, "redundant_uplink_cnt");
        vm.resultModifications.lora_location          = valcmp(obj, "lora_location");
        vm.resultModifications.options                = valcmp(obj, "options");

        vm.resultModifications.downlink_allowed  = valcmp(obj, "downlink_allowed");
        vm.resultModifications.activated         = valcmp(obj, "activated");
        vm.resultModifications.MaxEIRP           = valcmp(obj, "MaxEIRP");
        vm.resultModifications.RegParamsRevision = valcmp(obj, "RegParamsRevision");
        vm.resultModifications.MACVersion        = valcmp(obj, "MACVersion");

        vm.resultModifications.appeui  = vm.device.activation === "abp"  && valcmp(obj, "appeui");
        vm.resultModifications.joineui = vm.device.activation === "otaa" && valcmp(obj, "joineui");

        // compare tags
        vm.resultModifications.tags = false;
        const keys = Object.keys(vm.deviceToEdit.tags || {}).concat(Object.keys(vm.device.tags || {}));
        console.log(keys);
        for (const key of keys) {
            if ((vm.deviceToEdit.tags || {})[key] !== (vm.device.tags || {})[key])
                vm.resultModifications.tags = true;
        }
        if (vm.resultModifications.tags) {
            obj.tags = Object.assign({}, vm.device.tags);
        }

        vm.resultModifications.device_properties = false;
        if (vm.device.device_prop_mobility != dev_prop_mobility || vm.device.device_prop_location != dev_prop_location) {
            var prop1 = ["", "static", "mobile"][vm.device.device_prop_mobility];
            var prop2 = ["", "outdoor", "indoor"][vm.device.device_prop_location];

            obj.device_properties = prop1 + (prop1 != "" && prop2 != "" ? "," : "") + prop2;
            vm.resultModifications.device_properties = true;
        }

        warn.diff_safe = vm.resultModifications.comment || vm.resultModifications.expiry_time_uplink || vm.resultModifications.expiry_time_downlink || vm.resultModifications.max_allowed_dutycycle ||
            vm.resultModifications.expected_avr_dutycycle || vm.resultModifications.lora_device_class || vm.resultModifications.qos_class || vm.resultModifications.redundant_uplink_cnt || vm.resultModifications.device_properties ||
            vm.resultModifications.lora_location || vm.resultModifications.appeui || vm.resultModifications.joineui || vm.resultModifications.options || vm.resultModifications.activated || vm.resultModifications.MaxEIRP || vm.resultModifications.MACVersion ||
            vm.resultModifications.RegParamsRevision | vm.resultModifications.downlink_allowed;

        return warn.diff_fatal || warn.diff_reregister || warn.diff_safe || warn.diff_danger;
    };

    vm.ok = function () {
    };

    // ---- end ----

    this.$onChanges = function (changesObj) {
        if (vm.deviceToEdit
            && vm.resultDevice
            && vm.resultModifications
            && vm.showStatus
            && vm.constants) {
            vm.init(vm.deviceToEdit && vm.deviceToEdit.deveui && vm.deviceToEdit || null); // Device only if not null and has a deveui
        }
    };
}
console.log("Registering device form component");
app.component('deviceForm', {
    templateUrl: 'device_form.html',
    controller: ['$filter', '$scope', '$rootScope', '$window', '$timeout', '$element', '$attrs', "DeviceService", "UserService", "$uibModal", "ToastService", "DataService", DeviceFormController],
    controllerAs: "vm",
    bindings: {
        batch: '<',
        batchDevices: '<',
        valid: '=',
        validMessage: '=',
        editMode: '<',
        deviceToEdit: '<',
        changed: '=',
        showStatus: '=',
        resultDevice: '=',
        resultModifications: '=',
        owner: '<',
        constants: '<',
        readonly: "=",
        onAccept: '&',
        onCancel: '&',
        myModalInstance: '<',
    }
});
