(function (angular) {

    var app = angular.module('dassUiModule');
    app.factory('AppService', AppServiceFn);

    AppServiceFn.$inject = ['$http', "$q"];

    function AppServiceFn($http, $q) {
        // List all apps
        function getAppsImpl(include_push_status = true) {
            const defer = $q.defer();
            let url = '/uiapi/rest/applications';
            if (include_push_status) {
                url += '?all=true';
            }
            $http.get(url).then(
                function (response) {
                    var apps = response.data;
                    defer.resolve(apps);
                },
                function (response) {
                    defer.reject(response);
                }
            );
            return defer.promise;
        }

        // Get individual app data
        async function getAppImpl(appid, includePushConfig = false) {
            let appInfo = await $http.get('/uiapi/rest/applications/' + appid + "?all=true");
            appInfo._pushConfig = appInfo;
            return appInfo;
        }

        // Sort a list of apps
        function sortAppsImpl(apps, column = 'appid', reverse = false) {
            return sortByColumn(apps, column, reverse);
        }

        // Delete an app
        function deleteAppImpl(appid) {
            return $http.delete('/uiapi/rest/applications/' + appid, null)
        }

        // Delete multiple apps
        function deleteAppsImpl(apps) {
            var promises = apps.map(app => deleteAppImpl(app.accountid));
            return Promise.all(promises);
        }

       function validatePushSettings(settings) {
            return settings &&
            settings.host && settings.host !== "" &&
            settings.cleartext_password && settings.cleartext_password !== "" &&
            settings.username && settings.username !== "";
        }

        // Add an app
        function addAppImpl(app) {
            if(!app.port || app.port == null){
                app.port = 0;
            }
            return $http.post('/uiapi/rest/applications', app);
        }

        // Update an app
        function updateAppImpl(app) {
            return $http.put('/uiapi/rest/applications/' + app.accountid, app);
        }

        function pushModeStartImpl(app, updateOnly = false) {
            return $http.put('/uiapi/rest/applications/' + app.accountid, { enabled: true });
        }

        function pushModeStopImpl(app) {
            return $http.put('/uiapi/rest/applications/' + app.accountid, { enabled: false });
        }

        function pushModeStatusImpl() {
            return $http.get(`/uiapi/rest/pushmode/status/${app.accountid}`, app);
        }


        // Start multiple apps
        function batchStartPushImpl(apps) {
            var promises = apps.map(app => pushModeStartImpl(app));
            return Promise.all(promises);
        }

        // Stop multiple apps
        function batchStopPushImpl(apps) {
            var promises = apps.map(app => pushModeStopImpl(app));
            return Promise.all(promises);
        }

        function b64DecodeUnicode(str) {
            try {
                // if the string is correctly decoded, then encode it and return the encoded (real) value
                // Going backwards: from bytestream, to percent-encoding, to original string.
                window.atob(str);

                return decodeURIComponent(atob(str).split('').map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));
            } catch(e) {
                // if the string is not decoded, just return the value
                return str;
            }
        }

        function getGlobalPushImpl() {
            const defer = $q.defer();

            $http.get('/uiapi/rest/pushmode/status').then(response => {
                let { data } = response;
                if (data.auth_string) {
                    const auto_string_split = data.auth_string.split(":");
                    if(auto_string_split.length > 1){
                        const auth_decoded = b64DecodeUnicode(auto_string_split[1].slice(6,-2)).split(":");
                        data.username = auth_decoded[0];
                        data.cleartext_password = auth_decoded[1];
                    } else {
                        const auth_decoded = b64DecodeUnicode(data.auth_string.slice(6)).split(":");
                        data.username = auth_decoded[0];
                        data.cleartext_password = auth_decoded[1];
                    }
                }
                defer.resolve(data);
            }).catch(err => defer.reject(err))

            return defer.promise;
        }

        function startGlobalPushImpl(app, updateOnly=false) {
            let url = `/uiapi/rest/pushmode/start`;
            if (updateOnly) {
                url += '?update_only=true';
            }

            return $http.put(url, app);
        }

        function stopGlobalPushImpl() {
            return $http.put('/uiapi/rest/pushmode/stop');
        }

        function saveGlobalPushConfigImpl(config) {
            return startGlobalPushImpl(config, true);
        }

        return {
            getApps: getAppsImpl,
            getApp: getAppImpl,
            addApp: addAppImpl,
            updateApp: updateAppImpl,
            deleteApp: deleteAppImpl,
            deleteApps: deleteAppsImpl,
            saveGlobalPushConfig: saveGlobalPushConfigImpl,
            getGlobalPush: getGlobalPushImpl,
            startGlobalPush: startGlobalPushImpl,
            stopGlobalPush: stopGlobalPushImpl,
            pushModeStart: pushModeStartImpl,
            pushModeStop: pushModeStopImpl,
            pushModeStatus: pushModeStatusImpl,
            batchStartPush: batchStartPushImpl,
            batchStopPush: batchStopPushImpl,
            sortApps: sortAppsImpl,
        }
    }
})(angular);
