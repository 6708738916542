var app = angular.module('dassUiModule');

function MulticastActivityController(ToastService, MessageService, UserService, $filter, $uibModal, MulticastService, GroupService, $window, $timeout) {
	const $translate = $filter("translate");
	const formatPayload = $filter("formatPayload");
	const dateString = $filter('dateString');

    let vm = this;

	const hexRegex = /^[0-9a-fA-F]+$/;
	vm.workingCount = 0;
	vm.currentGroup = null;
	vm.device_status = undefined;
	vm.activation = undefined;
	vm.payloads = [];

	vm.customFilter = {
		mac_msg: false
	};

	vm.can_view_mac_msg = true;
	vm.lorawanVersion = undefined;
	vm.show_mac_msg = false;
	vm.RFRegion = "";
	vm.RegParamsRevision = "";
	vm.groupMulticastsMessage = $translate('GROUP_MULTICASTS_MESSAGE', {
		number: 0
	});

	vm.changeVisibility = (show_mac_msg) => {
		vm.show_mac_msg = !show_mac_msg
		vm.customFilter.mac_msg = !show_mac_msg;
		vm.initDataTable();
	}

	vm.updateDataType = () => {
		if (!vm.currentGroup) {
			return;
		}
		vm.dataType = "/uiapi/rest/multicast/" + vm.currentGroup + "/payloads/dl";

	}

	vm.progressCb = (ctrl, done) => {
		if (done) {
			vm.groupMulticastsMessage = $translate('GROUP_MULTICASTS_MESSAGE', {
				number: ctrl.pageData && ctrl.pageData.total || 0,
			});
		}
	}

	vm.progressCb = (ctrl, done) => {
		if (done) {
			vm.groupMulticastsMessage = $translate('GROUP_MULTICASTS_MESSAGE', {
				number: ctrl.pageData && ctrl.pageData.total || 0,
			});
		}
	}

	vm.loadMulticasts = () => {
		UserService.getUserData().then(
            (userData) => {
                vm.user = userData.user;
                vm.currentDevice = userData.currentDevice;
                vm.workingCount -= 1;
            },
            (response) => {
                console.log("error getting user data:" + response.status);
                vm.workingCount -= 1;
            }
        );

		if (!vm.reloadData) return;
		var reloadPromise = vm.reloadData();
	};

	vm.tableSelectionChanged = (payload) => {
		console.log("Selection changed ", payload);
		vm.selectedPayloads = payload;
	};

	vm.deletePayload = (groupid, packet) => {
		MessageService.showMessage({
			title: $translate('MSG_DELETE_PACKET_TITLE'),
			body: $translate('MSG_DELETE_PACKET_BODY_GROUP', {
				groupid: groupid
			})
		}).then((ok) => {
			if (ok == "ok") {
				MulticastService.deleteGroupPayload(groupid, packet.id).then(
					(response) => {
						ToastService.showMessage($translate('MSG_DELETE_PACKET_SUCCESS_BODY'), "success");

						vm.loadMulticasts();
					},
					(response) => {
						ToastService.showMessage($translate('MSG_DELETE_PACKET_FAIL_BODY'), "error");
					}
				);
			}
		});
	};

	vm.deletePayloads = () => {
		MessageService.showMessage({
			title: $translate('MSG_DELETE_PACKETS_TITLE'),
			body: $translate('MSG_DELETE_PACKETS_BODY_GROUP', {
				groupid: vm.currentGroup,
				count: vm.selectedPayloads.length
			})
		}).then((ok) => {
			MulticastService.deleteGroupPayloads(vm.currentGroup, vm.selectedPayloads).then(
				(response) => {
					ToastService.showMessage($translate('MSG_DELETE_PACKETS_GROUP_SUCCESS_BODY', {
						groupid: vm.currentGroup,
						count: vm.selectedPayloads.length
					}), "success");
					vm.loadMulticasts();
				},
				(response) => {
					ToastService.showMessage($translate('MSG_DELETE_PACKETS_GROUP_FAIL_BODY', {
						groupid: vm.currentGroup,
						count: vm.selectedPayloads.length
					}), "error");
				}
			);
		});
	};

	const sendPayloadToDass = (groupId, payload) => {
		var groupObj = {
			"groupid": groupId
		};
		GroupService.sendMulticast(groupObj, payload.data, payload.port, payload.fcnt).then(
			(response) => {
				ToastService.showMessage($translate('MSG_SEND_DL_PACKET_SUCCESS_BODY', {
					deveui: groupId,
					transaction_id: response.data.id
				}), "success");

				vm.loadMulticasts();
			},
			(response) => {
				let message = response.data;
				switch (response.status) {
					case 409:
						message = $translate('MSG_SEND_DL_PACKET_FAIL_BODY');
						break;
				
					default:
						message = response.data;
						break;
				}
				ToastService.showMessage(message, "error");

				console.log(response.data);
			}
		);
	}

	vm.sendData = (groupId) => {
		if (groupId == null && vm.currentGroup == "") {
			MessageService.showMessage({
				title: $translate('MSG_TITLE_OOPS'),
				body: $translate('MSG_SELECT_DEVICE_BODY')
			});
			return;
		}
		if (groupId == null) {
			groupId = vm.currentGroup;
		}

		const modalInstance = $uibModal.open({
			backdrop: "static",
			animation: vm.animationsEnabled,
			templateUrl: 'sendDataModalContent.html',
			controller: 'SendDataModalCtrl',
			size: "",
			resolve: {
				items: () => {
					return {
						isMulticast: true
					};
				}
			}
		});
		modalInstance.result.then((payload) => {
			sendPayloadToDass(groupId, payload);
		}, () => {
			
		});
	};

	vm.selectGroup = () => {
		const modalInstance = $uibModal.open({
			backdrop: "static",
			animation: true,
			templateUrl: 'showUsers.html',
			controller: 'DataSelectController',
			size: "lg",
			resolve: {
				items: () => {
					return {
						dataType: '/uiapi/rest/groups',
						title: "NAV_MANAGE_GROUPS",
						actions: [],
						bulkActions: [],
						columns: [{
							key: "groupid",
							type: "text",
							title: "Group ID",
							filterable: true,
							filterField: "search_id",
							filterType: "text",
							filterParams: {
								mapper: (x) => x || undefined
							},
							sortable: false
						},
						{
							key: "title",
							title: "Title",
							type: "text",
							filterable: true,
							filterField: "search_title",
							filterType: "text",
							sortable: false
						},
						{
							key: "comment",
							title: "COMMENT",
							type: "text",
							filterable: false,
							sortable: false
						}],

					}
				}
			}
		});

		modalInstance.result.then((selectedGroups) => {
			console.log("Outside modal: ", selectedGroups);
			if (selectedGroups.length > 0) {
				const group = selectedGroups[0];
				vm.currentGroup = group.groupid;
				$window.localStorage.setItem('currentGroup', vm.currentGroup);
				vm.updateDataType();
				$timeout(() => vm.reloadData());
			}
		}, () => {
			console.log("Dismissed");
		});
	};

	vm.$onInit = function() {
		if (window.location.hash) {
			var parts = window.location.hash.split('#');

			window.location.hash = '';

			if (parts[1]) {
				vm.currentGroup = parts[1];
				$window.localStorage.setItem('currentGroup', vm.currentGroup);

				vm.loadGroupActivity();
			}
			else {
				vm.currentGroup = null;
				$window.localStorage.removeItem('currentGroup');
			}
		}
		else if ($window.localStorage.getItem('currentGroup')) {
			vm.currentGroup = $window.localStorage.getItem('currentGroup');

			vm.loadGroupActivity();
		}
	};

	vm.loadGroupActivity = () => {
		vm.updateDataType();
		vm.reloadData();
	};

	vm.initDataTable = () => {
		vm.actions = [{
			type: "action",
			text: "DATA_DELETE_PACKET",
			icon: "fa fa-trash-o fa-fw",
			action: (packet) => vm.deletePayload(vm.currentGroup, packet)
		}];

		vm.bulkActions = [{
			type: "action",
			text: "DATA_DELETE_PACKETS",
			icon: "fa fa-trash-o fa-fw",
			action: vm.deletePayloads
		}];

		vm.columns = [{
			key: "timestamp",
			title: "TIME",
			type: "text",
			render: (x) => dateString(x.timestamp),
			filterable: true,
			filterField: "date",
			filterType: 'daterange',
			filterParams: {
				startField: "from_date",
				endField: "to_date",
				mapper: (x) => x && x.format()
			},
			sortable: true,
			sortKey: "sort_by_timestamp",
			initialSorting: 2,							// default descending sort
			validSortDirections: [false, true, true],	// always sort
		}, {
			key: "fcnt",
			title: "FCNT",
			type: "text",
			filterable: false,
            filterType: 'text',
            filterField: 'fcnt',
            filterParams: {
                mapper: (x) => x || undefined
            },
			render: (x) => x.fcnt != undefined ? x.fcnt.toString() : ''
		}, {
			key: "port",
			title: "PORT",
			type: "text",
			filterable: true,
            filterType: 'text',
			filterField: 'search_port',
			filterSearchMinLength: 1,
            filterParams: {
                mapper: (x) => x || undefined
			},
			render: (x) => x.port != undefined ? x.port.toString() : ''
		},
		{
			key: "transmissionStatus",
			title: "STATUS",
			type: "text",
			filterable: false,
			filterField: 'transmissionStatus',
			filterType: 'select',
			filterParams: {
				options: [
					{
						label: "GATEWAY_STATUS_ANY",
						value: ""
					},
					{
						label: "PACKET_STATUS_0",
						value: 0
					},
					{
						label: "PACKET_STATUS_1",
						value: 1
					},
					{
						label: "PACKET_STATUS_2",
						value: 2
					},
					{
						label: "PACKET_STATUS_3",
						value: 3
					},
					{
						label: "PACKET_STATUS_4",
						value:4
					},
					{
						label: "PACKET_STATUS_5",
						value: 5
					}
				]
			},
			render: (x) => (
				x.rssi && "" || 
				x.transmissionStatus == 0 && $translate("PACKET_STATUS_0") ||
				(x.transmissionStatus == 1 && x.confirmed == true) && $translate("PACKET_STATUS_1") ||
				(x.transmissionStatus == 1 && x.confirmed == false) && $translate("PACKET_STATUS_1_1") ||
				x.transmissionStatus == 2 && $translate("PACKET_STATUS_2") ||
				x.transmissionStatus == 3 && $translate("PACKET_STATUS_3") || 
				x.transmissionStatus == 4 && $translate("PACKET_STATUS_4") ||
				x.transmissionStatus == 5 && $translate("PACKET_STATUS_5"))
		}, {
			key: "decrypted",
			title: "DECRYPTED",
			render: (x) => x.decrypted ? $translate("DECRYPTED") : $translate("ENCRYPTED")
		}, {
			key: "data",
			title: "DATA",
			type: "text",
			render: (x) => (x.dataFrame && formatPayload(x.dataFrame) || x.data && formatPayload(x.data))
		}];

		if (vm.show_mac_msg) {
			vm.columns.splice(4, 0, {
				key: "mac_msg",
				title: "MAC_MSG",
				type: "text",
				visible: (entry, payload) => vm.can_view_mac_msg,
				render: (packet) => packet.mac_msg && vm.can_view_mac_msg === true ? (
					getDecodedLorawanMsgAsText(packet.mac_msg, hexRegex.test(packet.mac_msg), vm.lorawanVersion, vm.RFRegion, vm.RegParamsRevision)) : ""
			})
		}

		vm.reloadData();
	};

	vm.reloadData = () => {
        console.log("This should get overwritten");
	};
	
	Promise.all([vm.loadMulticasts()]).then(vm.initDataTable);
};

app.controller("MulticastActivityController", ["ToastService", "MessageService", "UserService", "$filter", "$uibModal", "MulticastService", "GroupService", "$window", "$timeout", MulticastActivityController])
