
const typeToUrlMap = {
	customers: "/uiapi/rest/customers",
    organisations: "/uiapi/rest/organisations",
    users: "/uiapi/rest/users",
	tenants: "/uiapi/rest/tenants",
	groups: "/uiapi/rest/groups",

	"channel-profiles": "/uiapi/rest/channel-profiles",
	"connectivity-profiles": "/uiapi/rest/connectivity-profiles",
	"device-profiles": "/uiapi/rest/device-profiles",
	"qos-profiles": "/uiapi/rest/qos-profiles",
	"roaming-profiles": "/uiapi/rest/roaming-profiles",
	"service-profiles": "/uiapi/rest/service-profiles",
}

type GetDataType = "customers" | "organisations" | "users" | "tenants" | "groups" | "device-profiles" |
		"service-profiles" | "connectivity-profiles" | "roaming-profiles" | "channel-profiles" | "qos-profiles";


interface IProfileFilterColumn {
    colName: string;
    colValue: string;
}

interface IProfileSortColumn {
    sortName: string;
    sortOrder: string;
}

interface IGetDataOptions {
	query: any;
}

export function objToQueryString(queryObj: {[index: string]: string }) {
    let query = "";
    for (const key of Object.keys(queryObj || {})) {
        if (queryObj[key] != null) {
            query += (query ? "&" : "?") + key + "=" + encodeURIComponent(queryObj[key] + "");
        }
    }
    return query;
}

export const getDassInfo = async () => {
	const response = await fetch("/dass_info", {
		credentials: "same-origin",
		headers: {
			"Content-Type": "application/json",
		},
		method: "GET",
	});

	if (response.status > 299) {
		throw { status: response.status, message: await response.text() };
	}
	return { status: response.status, data: await response.json() };
};

function assertType(type: GetDataType) {
	if (typeToUrlMap[type] == null) {
		const message = "Unknown datatype " + type;
		console.log(message);
		throw { message };
	}
}


// Use get unpaged list to GET standard list from any DASS endpoint

export const getUnpagedList = async (type: GetDataType, options?: IGetDataOptions) => {

	assertType(type);

	const url = typeToUrlMap[type] + objToQueryString(options && options.query);
	const response = await fetch(url, {
		credentials: "same-origin",
		headers: {
			"Content-Type": "application/json",
		},
		method: "GET",
	});

	if (response.status == 401) { // the status should be changed!!!
		window.location.href = '/app/signout?resignin';
	}

	if (response.status > 299) {
		throw { status: response.status, message: await response.text() };
	}

	return { status: response.status, data: await response.json() };
};


export const getDataElementById = async (type: GetDataType, id: string, options?: IGetDataOptions) => {

	assertType(type);

	const url = typeToUrlMap[type] + "/" + id + objToQueryString(options && options.query);
	const response = await fetch(url, {
		credentials: "same-origin",
		headers: {
			"Content-Type": "application/json",
		},
		method: "GET",
	});

	if (response.status == 401) { // the status should be changed!!!
		window.location.href = '/app/signout?resignin';
	}

	if (response.status > 299) {
		throw { status: response.status, message: await response.text() };
	}

	return { status: response.status, data: await response.json() };
};







export const getPageData = async (
	type: GetDataType,
	LimitPerPage: number,
    PageState: string,
	Filtering: IProfileFilterColumn[] | null,
	Sorting: IProfileSortColumn | null,
	options?: IGetDataOptions,
) => {

	assertType(type);

	const url = typeToUrlMap[type];
	const query: any = {
		limit: LimitPerPage,
		page_state: PageState,
	};

	if (Sorting && Sorting.sortName) {
		query["sort_by_" + Sorting.sortName] = Sorting.sortOrder;
	}
	for (const flt of Filtering || []) {
		query["search_" + flt.colName] = flt.colValue;
	}
	if (options && options.query) { Object.assign(query, options.query); }

	const response = await fetch(url + objToQueryString(query), {
		credentials: "same-origin",
		method: "GET",
	});

	if (response.status == 401) { // the status should be changed!!!
		window.location.href = '/app/signout?resignin';
	}

	if (response.status > 299) {
		throw { status: response.status, message: await response.text() };
	}

	const data = await response.json();
	return ({ status: response.status, data });
};


export const getPagination = async (
    type: GetDataType,
	LimitPerPage: number,
	Filtering: IProfileFilterColumn[] | null,
	Sorting: IProfileSortColumn | null,
	options?: IGetDataOptions,
) => {

	assertType(type);

	const url = typeToUrlMap[type];
	const query: any = {
		limit: LimitPerPage,
		get_pages: true,
	};
	if (Sorting && Sorting.sortName) {
		query["sort_by_" + Sorting.sortName] = Sorting.sortOrder;
	}
	for (const flt of Filtering || []) {
		query["search_" + flt.colName] = flt.colValue;
	}
	if (options && options.query) { Object.assign(query, options.query); }

	const response = await fetch(url + objToQueryString(query), {
		credentials: "same-origin",
		method: "GET",
	});

	if (response.status == 401) { // the status should be changed!!!
		window.location.href = '/app/signout?resignin';
	}

	if (response.status > 299) {
		throw { status: response.status, message: await response.text() };
	}

	const data = await response.json();
	return { status: response.status, data };
};


