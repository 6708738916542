
/*jslint node: true, sloppy: true, bitwise: true, vars: true, eqeq: true, plusplus: true, nomen: true, es5:true */
/*global angular, atob, btoa, google, sortByColumn, showMessage, dateString */

var app = angular.module('dassUiModule');


// ------------------------------------------------------------------------------------------------------------------------------------
// MODEL CONTROLLERS
// ------------------------------------------------------------------------------------------------------------------------------------

function parseHexPayload(str) {

	if (typeof str !== "string") {
		return null;
	}
	var i, c, s = str.toLowerCase(),
		n, nc = 0,
		cv = 0;
	var res = "";

	for (i = 0; i < s.length; i++) {
		c = s.charCodeAt(i);

		n = -1;
		if (c >= 97 && c <= 102) {
			n = c - 97 + 10;
		} else if (c >= 48 && c <= 57) {
			n = c - 48;
		} else if (c == 32) {

			if (nc % 2 == 0) {
				nc = 0;
			} else if (nc == 1) {
				res += String.fromCharCode(cv);
				nc = 0;
			} else {
				return null;
			}

		} else {
			return null;
		}

		if (n >= 0) {
			if (nc % 2 == 0) {
				cv = n;
			} else {
				cv = cv * 16 + n;
				res += String.fromCharCode(cv);
			}
			nc++;
		}

	}

	if (nc == 1) {
		res += String.fromCharCode(cv);
	} else if (nc % 2 == 1) {
		return null;
	}

	return res;
}




app.directive('hexString', function () {
	return {

		// limit usage to argument only
		restrict: 'A',

		// require NgModelController, i.e. require a controller of ngModel directive
		require: 'ngModel',

		// create linking function and pass in our NgModelController as a 4th argument
		link: function (scope, element, attr, ctrl) {

			function hexStringValidator(str) {
				ctrl.$setValidity('hexStringValidator', parseHexPayload(str) != null);
				return str;
			}

			ctrl.$parsers.push(hexStringValidator);
		}
	};
});



// ------------------------------------------------------------------------------------------------------------------------------------
// MODEL CONTROLLERS
// ------------------------------------------------------------------------------------------------------------------------------------


//
// Modal controller for the Sign In dialog box

app.controller('SignInModalCtrl', ["$scope", "$uibModalInstance", "items", function ($scope, $uibModalInstance, items) {

	$scope.credentials = {
		userid: "",
		password: ""
	};
	$scope.items = items;
	$scope.enable_sign_up = $scope.items[0].enable_sign_up;
	$scope.selected = {
		item: $scope.items[0]
	};
	vm.constants = constants;
	vm.autocomplete = (constants.disable_autocomplete_for_credentials === true) ? "off" : "on";
	$scope.ok = function () {
		$uibModalInstance.close($scope.credentials);
	};

	$scope.cancel = function () {
		$uibModalInstance.dismiss('cancel');
	};
}]);

app.controller('SelectTenantController', ["$scope", "$uibModalInstance", "items", "$filter", "ToastService", "TenantService", function ($scope, $uibModalInstance, items, $filter, ToastService, TenantService) {
	console.log("Select tenant controller");
	const $translate = $filter("translate");
	const vm = $scope;
	vm.loading = false;
	vm.selectedTenant = {
		tenantid: -1
	};

	vm.loadTenants = () => {
		vm.loading = true;
		TenantService.getTenants().then((tenants) => {
			vm.tenants = tenants;
			vm.loading = false;
		}).catch((err) => {
			vm.loading = false;
			ToastService.showMessage($translate("MSG_ERROR_LOADING_TENANTS"), "error");
		});
	}

	// TODO: Fix this hack
	vm.tenantSelected = (tenant) => {
		console.log("tenant selected", tenant);
		vm.selectedTenant = tenant;
	}

	vm.ok = function () {
		console.log("Closing tenant modal with tenant ", vm.selectedTenant);
		$uibModalInstance.close(vm.selectedTenant);
	};

	vm.cancel = function () {
		$uibModalInstance.dismiss('cancel');
	};

	vm.loadTenants();
}]);

app.controller('SendDataModalCtrl', ["$scope", "$uibModalInstance", "items", function ($scope, $uibModalInstance, items) {
	$scope.payload = {
		data: "",
		port: 1,
		fnct: "",
		confirmed: true
	};

	$scope.items = items;

	$scope.getsize = function () {
		var data = parseHexPayload($scope.payload.data);
		return data == null ? 0 : data.length;
	};

	$scope.selected = {
		item: $scope.items[0]
	};

	$scope.isMulticast = items.isMulticast;

	$scope.ok = function () {
		var pldata = parseHexPayload($scope.payload.data);
		if (pldata == null || $scope.payload.port == null) {
			return;
		}

		$scope.payload.data = pldata;
		$uibModalInstance.close($scope.payload);
	};

	$scope.cancel = function () {
		$uibModalInstance.dismiss('cancel');
	};
}]);



app.controller('AddUserModalCtrl', ["$scope", "$uibModalInstance", "items", "$filter", "TenantService", "ToastService", "UserService", function ($scope, $uibModalInstance, items, $filter, TenantService, ToastService, UserService) {
	var $translate = $filter('translate');
	var vm = this;

	console.log("Add/Edit user ", items);

	vm.items = items;

	vm.userTypeStr = items.usertype == "customer" ? $translate('USER_TYPE_CUSTOMER') : items.usertype == "organization" ? $translate('USER_TYPE_ORGANIZATION') : $translate('USER_TYPE_USER');

	vm.userType = items.usertype;

	vm.owner = items.owner;
	vm.olduser = items.user;

	vm.org_admin = false;
	vm.customer_admin = false;
	if (vm.userType == 'organization') {
		// vm.customer_admin = true;
	}

	vm.defaultUser = {
		userid: "",
		password: "",
		administrator: false,
		customer_admin: vm.customer_admin,
		can_register: false,
		can_access_gtw_info: false,
		gtw_admin: false,
		can_own_gtw: false,
		can_add_gtw: false,
		can_mng_gtw: false,
		sys_admin: false,
		verbose_log: false,
		token_manager: false,
		loraloc_enable: false,
		tenant_id: undefined,
		max_num_devices : undefined,
		max_num_gateways: undefined,
		can_view_mac_msg: undefined,
		can_access_gtw_loc_info: false,
		tenant_admin: false,
		can_view_mac_msg: false,
		org_admin: vm.org_admin,
		email_alarm_subscription: "",
		can_register_joinserver: false,

		data_archive_admin: false,
		can_access_logs: vm.owner.can_access_logs,
		auto_archive_data: "default",
		auth_server: vm.owner.auth_server,
	};
	if (items.userDefaults) {
		Object.assign(vm.defaultUser, items.userDefaults);
	}

	vm.signUpPresets = [
		{
			preset_name: 'Gateway admin',
			user_data: {
				userid: "gtw_admin",
				password: "",
				administrator: false,
				can_register: true,
				can_access_gtw_info: false,
				gtw_admin: true,
				can_own_gtw: false,
				can_add_gtw: false,
				can_mng_gtw: false,
				customer_admin: false,
				sys_admin: false,
				verbose_log: false,
				loraloc_enable: false,
			}
		},
		{
			preset_name: 'Global Admin',
			user_data: {
				userid: "global_admin",
				password: "",
				administrator: true,
				can_register: true,
				can_access_gtw_info: false,
				gtw_admin: true,
				can_own_gtw: false,
				can_add_gtw: false,
				can_mng_gtw: false,
				customer_admin: false,
				sys_admin: false,
				verbose_log: false,
				loraloc_enable: false,
			}
		}
	]

	vm.passwordConf = "";
	vm.editmode = vm.olduser != null;
	vm.canadmin = items.owner.administrator == true;
	vm.cangtwadmin = items.owner.gtw_admin == true;
	vm.cancustadmin = items.owner.customer_admin == true;
	vm.cansysadmin = items.owner.sys_admin == true;
	vm.dataarchiveadmin = vm.owner.data_archive_admin == true;
	vm.canaccesslogs = vm.owner.can_access_logs == true;
	vm.cansysadmin_or_dataarchiveadmin = vm.cansysadmin || vm.dataarchiveadmin || vm.canaccesslogs;
	vm.canloraloc = items.owner.loraloc_enable == true;
	vm.title = (vm.olduser == null ? $translate('ADD_USER_ADD') : $translate('ADD_USER_EDIT')) + vm.userTypeStr;
	vm.okbutton = (vm.olduser == null ? $translate('ADD_USER_ADD_NEW') : $translate('ADD_USER_UPDATE')) + vm.userTypeStr;

	vm.user = (vm.olduser && angular.copy(vm.olduser)) || vm.defaultUser;

	// Set undefiend to some default.
	// for (const key of Object.keys(vm.defaultUser)) {
	// 	if (!vm.user[key]) vm.user[key] = vm.defaultUser[key];
	// }

	vm.readOnlyCustom = true;

	vm.addUserOnDass = (user, usertype) => {
		let ut = (usertype === "customer") ? $translate('USER_TYPE_CUSTOMER').toLowerCase() : (usertype === "organization") ?
						$translate('USER_TYPE_ORGANIZATION').toLowerCase() : $translate('USER_TYPE_USER').toLowerCase();

		UserService.addUserOnDass(user, usertype).then(response => {
			ToastService.showMessage($translate('MSG_USER_CREATE_SUCCESS_BODY', {
					ut: usertype[0].toUpperCase() + usertype.slice(1)
				}), "success"
			);
			$uibModalInstance.close();
			// vm.loadUsers(usertype);
		}).catch(err => {
			if(err.status === 412 || err.status === 400) {
				if (err.data == "field 'tenant_id' of unexpected type" ||
					err.data == "tenant_id not valid") {

					ToastService.showMessage($translate('MSG_USER_UPDATE_INCORRECT_TENANT_ID'), "error");
				}
				else {
					ToastService.showMessage($translate('PASSWORD_FAILED'), "error");
				}
			} else if (err.status === 403) {

				// ToastService.showMessage($translate('MSG_USER_CREATE_FORBIDDEN_BODY', { ut }), "error");
				ToastService.showMessage(err.data, "error");

			} else {
				ToastService.showMessage($translate('MSG_USER_CREATE_FAIL_BODY', {
					ut: usertype[0].toUpperCase() + usertype.slice(1)
					}) , "error"
				);
			}
		});
	}

	vm.checkTagsValidity = function () {
		let regexTagName = /^[a-zA-Z\d\-_]*$/;
		const tags = vm.user.tags;
		const newTagName = vm.user.newTagName || "",
			  newTagDesc = vm.user.newTagDesc || "",
			  EditingTag = vm.user.EditingTag || false;
		for (var tagName in tags) {
			let result = tagName.match(regexTagName);
			if (!result) {
				return false;
			}
		}

		if (vm.user.newTagName) {
			let result = vm.user.newTagName.match(regexTagName);
			if (!result) {
				return false;
			}
		}

		if ((newTagName.length > 0) || (newTagDesc.length > 0) || EditingTag){
			return false;
    	}

		return true;
	}

	vm.isOk = () => {

		const err =
			(vm.editmode && vm.readOnlyCustom === true) ||
			(!vm.checkTagsValidity() ||
				(vm.user.userid == null) ||		// null means error, otherwise it would be ""
				(!vm.user.userid && vm.user.auth_server.indexOf("subject") < 0) ||
				(!vm.editmode    && !vm.user.password && vm.user.auth_server.indexOf("password") >= 0) ||
				(!vm.editmode    && !vm.user.subject  && vm.user.auth_server.indexOf("subject")  >= 0) ||
				(vm.user.password && vm.user.password != vm.user._passwordConf) ||
				(vm.user.max_num_devices < 0 || vm.user.max_num_gateways < 0) ||
				(vm.user.validationResult && vm.user.password &&
					(!vm.user.validationResult.ok_length || !vm.user.validationResult.complex)));

		return !err;
	}

	vm.ok = function () {

		if (vm.isOk()) {

			vm.user.verbose_log    = vm.cansysadmin  ? vm.user.verbose_log    : undefined;
			vm.user.customer_admin = vm.cancustadmin ? vm.user.customer_admin : undefined;
			vm.user.administrator  = vm.canadmin     ? vm.user.administrator  : undefined;
			vm.user.loraloc_enable = vm.canloraloc   ? vm.user.loraloc_enable : undefined;
			if (!vm.cangtwadmin) {
				vm.user.can_own_gtw = undefined;
				vm.user.can_add_gtw = undefined;
				vm.user.can_mng_gtw = undefined;
			}
			vm.user.auto_archive_data  = vm.dataarchiveadmin ? vm.user.auto_archive_data  : undefined;
			vm.user.data_archive_admin = vm.dataarchiveadmin ? vm.user.data_archive_admin : undefined;

			console.log(vm.user);
			const user = {... vm.user};
			["_passwordConf", "validationResult", "new_userid", "newTagName", "newTagDesc", "EditingTag",
			 "auth_server"].forEach((key) => delete user[key]);

			if (!user.password) {
				delete user.password;
			}
			if (!user.subject) {
				delete user.subject;
			}
			if (!user.userid) {
				delete user.userid;
			}


			if (vm.olduser == null) {
				// create new user
				vm.addUserOnDass(user, vm.userType);

			} else {
				// modify existing user.
				let modifiedUser = { };
				let change = false;

				for (let property of Object.keys(user)) {
					if (user[property] != null && user[property] != vm.olduser[property])  {
						modifiedUser[property] = user[property];
						change = true;
					} else if((property == "max_num_devices" || property == "max_num_gateways") && (user[property] != vm.olduser[property])){
						if(user[property] == null){
							modifiedUser[property] = 0;
							change = true;
						}
					}
				}
				if (change) {

					UserService.getUserData().then((response) => {
						let currentUserId = response.loginuserid;

						let loggedInUsers = currentUserId.split('/');
						if (loggedInUsers.length > 0) {
							currentUserId = loggedInUsers[0];
						}
						UserService.updateUser(vm.olduser.userid, modifiedUser).then(
							(response) => {
								ToastService.showMessage($translate('MSG_USER_UPDATE_SUCCESS_BODY'),
									"success"
								);

								if (currentUserId == modifiedUser.userid) {
									if (modifiedUser.password != null) {
										// if I'm the user, and the user is not the forwarded user
										// and the password was changed, we need to relogin (silently)
										// password is changed to the logged in user
										UserService.signInOnDass(modifiedUser).then((response) => {
											if (currentUserId != vm.owner.userid) {
												// if the logged in user has forwarded user
												UserService.signInForward(vm.owner.userid).then(response)
												.catch(
													error => ToastService.showMessage($translate('MSG_LOGIN_FORWARD_FAILED'), "error")
												);
											}

											$uibModalInstance.close();
										});

									} else {
										$uibModalInstance.close();
									}
								} else {
									$uibModalInstance.close();
								}

								vm.cancel();
							},
							(err) => {
								if (err.status === 412 || err.status === 400) {
									if (err.data == "field 'tenant_id' of unexpected type" ||
										err.data == "tenant_id not valid") {

										ToastService.showMessage($translate('MSG_USER_UPDATE_INCORRECT_TENANT_ID'), "error");
									}
									else {
										ToastService.showMessage($translate('PASSWORD_FAILED'), "error");
									}
								} else if (err.status === 403) {
									// ToastService.showMessage($translate('MSG_USER_UPDATE_FORBIDDEN_BODY'), "error");
									ToastService.showMessage(err.data, "error");
								}
								else {
									ToastService.showMessage($translate('MSG_USER_UPDATE_FAIL_BODY', {
										ut: vm.userType[0].toUpperCase() + vm.userType.slice(1)
									}), "error");
								}
							}
						);
					}).catch((response) => {
						ToastService.showMessage($translate("ERROR"), "error");
					});
				} else {
					ToastService.showMessage($translate('MSG_USER_UPDATE_SUCCESS_BODY'), "success");
					vm.cancel();
				}
			}
		}
	};

	vm.cancel = function () {
		$uibModalInstance.dismiss('cancel');
	};

}]);

app.controller('SelectDeviceModalCtrl', ["$scope", "$uibModalInstance", "$filter", "DeviceService", "items", "$http", function ($scope, $uibModalInstance, $filter, DeviceService, items, $http) {
	var dateString = $filter('dateString');
	var sortByColumn = $filter('sortByColumn');

	$scope.pscope = items;
	$scope.devices = $scope.pscope.devices;
	$scope.formatDeveui = $filter("formatDeveui");
	$scope.sortedDevices = [];
	$scope.filteredDevices = [];
	$scope.deviceSortColumn = "deveui";
	$scope.deviceSortReverse = false;

	$scope.currentPage = 1;
	$scope.itemsPerPage = 10;
	$scope.searchString = "";

	$scope.sortDevices = function () {
		$scope.sortedDevices = sortByColumn($scope.devices, $scope.deviceSortColumn, $scope.deviceSortReverse);
		console.log($scope.sortedDevices);
	};


	$scope.loadDevices = function () {

		DeviceService.getDevices().then(
			function (devices) {
				$scope.devices = devices;

				var i;
				for (i = 0; i < $scope.devices.length; i++) {
					$scope.devices[i].last_reception_str = dateString($scope.devices[i].last_reception);
				}

				$scope.sortDevices();
				$scope.pscope.devices = $scope.devices;
			}
		);
	};



	$scope.select = function (dev) {
		$uibModalInstance.close(dev);
	};

	$scope.cancel = function () {
		$uibModalInstance.dismiss('cancel');
	};
}]);


function validKey(key, length) {

	if (typeof key !== "string") {
		return false;
	}

	if (length == 4) {
		return key.match(/(^(0[x])?[0-9a-f]{8}$)|(^([0-9a-f]{2}-){3}[0-9a-f]{2}$)/i) != null;
	} else if (length == 8) {
		return key.match(/(^(0[x])?[0-9a-f]{16}$)|(^([0-9a-f]{2}-){7}[0-9a-f]{2}$)/i) != null;
	} else if (length == 16) {
		return key.match(/(^(0[x])?[0-9a-f]{32}$)|(^([0-9a-f]{2}-){8}[0-9a-f]{2}$)/i) != null;
	}
	return false;
}

function es(str) {
	console.log(typeof str);
	return typeof str === "string" ? str : "";
}


function toHex32(val) {
	if (typeof val !== "number") {
		return null;
	} else {
		return "00000000".substring(val.toString(16).length) + val.toString(16).toUpperCase();
	}
}


app.controller('AddDeviceModalCtrl', ["$scope", "$uibModalInstance", "items", "$filter", "MessageService", function ($scope, $uibModalInstance, items, $filter, MessageService) {
	var $translate = $filter('translate');
	$scope.items = items;
	$scope.devices = items.devices;
	$scope.batch = items.devices ? true : false;
	$scope.initialDevice = items.device || {};
	$scope.editMode = items.device != undefined;
	$scope.readonly = $scope.editMode || $scope.batch;
	$scope.valid = false;
	$scope.device = {};
	$scope.mod = {};
	$scope.changed = false;
	$scope.edit_rights = items.owner;
	$scope.myModalInstance = $uibModalInstance;
	$scope.deviceTags = items.deviceTags;

	$scope.showStatus = function() {
	};


	/*

	$scope.$on('updateDeviceTagsSecond', function(event, data) {	// when there is a change from the child component
		$scope.deviceTags = data;
		$scope.tagsValidity = $scope.checkTagsValidity($scope.deviceTags) && $scope.newTagNameValidity;
	});

	$scope.$on('updateDeviceNewTagNameSecond', function(event, data) {	// when there is a change from the child component
		let regexTagName = /^[a-zA-Z\d\-_]*$/;
		let result = data.match(regexTagName);
		if (!result) {
			$scope.newTagNameValidity = false;
		}
		else {
			$scope.newTagNameValidity = true;
		}

		$scope.tagsValidity = $scope.checkTagsValidity($scope.deviceTags) && $scope.newTagNameValidity;
    });

	*/

	$scope.tagsValidity = true;
	$scope.newTagNameValidity = true;
	$scope.checkTagsValidity = function (tags) {
		let regexTagName = /^[a-zA-Z\d\-_]*$/;
		for (var tagName in tags) {
			let result = tagName.match(regexTagName);
			if (!result) {
				return false;
			}
		}

		return true;
	}

	$scope.accept = () => {
		if (!$scope.valid) return;
		console.log("Modal accept device", {...$scope.device});
		$uibModalInstance.close($scope.device);
	};

	$scope.cancel = () => {
		$uibModalInstance.dismiss('cancel');
	}
}]);


app.controller('MessageModalCtrl', ["$scope", "$uibModalInstance", "items", function ($scope, $uibModalInstance, items) {

	$scope.title = items.title;
	$scope.body = items.body;

	$scope.ok = function () {
		$uibModalInstance.close('ok');
	};

	$scope.cancel = function () {
		$uibModalInstance.dismiss('cancel');
	};
}]);



app.controller('SelectPositionOnMapModalCtrl', ["$scope", "$uibModalInstance", "items", function ($scope, $uibModalInstance, items) {
	var vm = $scope; // TODO: Figure out how to use vm here instead of scope.
	var origCenter = items;
	console.log('maps market',origCenter);

	vm.center = {
		lat: origCenter.latitude,
		lng: origCenter.longitude,
		alt: origCenter.altitude
	};

	vm.markers = [{
		layer: 'devices',
		lat: origCenter.latitude || 0,
		lng: origCenter.longitude || 0,
		alt: origCenter.altitude,
		latVal: origCenter.latitude,
		lngVal: origCenter.longitude,
		altVal: origCenter.altitude
	}];

	vm.marker = vm.markers[0];

	vm.dragEnd = function (marker) {
		vm.marker = angular.copy(marker);
		vm.marker.latVal = vm.marker.lat;
		vm.marker.lngVal = vm.marker.lng;
		vm.marker.altVal = vm.marker.alt;
		console.log("Map view drag end 2", vm.marker);
	}

	vm.recenter = function () {
		console.log('recenter',vm.marker);
		vm.marker.lat = vm.marker.latVal || 0;
		vm.marker.lng = vm.marker.lngVal || 0;
		vm.marker.alt = vm.marker.altVal;
		vm.center = {
			lat: vm.marker.lat,
			lng: vm.marker.lng,
			alt: vm.marker.alt
		};

		if (!vm.marker.latVal || !vm.marker.lngVal) {
			vm.center.zoom = 1;
		}
		
		vm.markers = [vm.marker];
	}

	vm.ok = function () {
		$uibModalInstance.close(vm.marker);
	};

	vm.cancel = function () {
		$uibModalInstance.dismiss('cancel');
	};
	console.log("Vm", vm);
	console.log(items)
}]);




app.controller('ViewGatewayOnMapModalCtrl', ["$scope", "$uibModalInstance", "items", function ($scope, $uibModalInstance, items) {
	var vm = $scope; // TODO: Figure out how to use vm here instead of scope.
	var origCenter = items;

	vm.latVal = origCenter.latitude || null;
	vm.lngVal = origCenter.longitude || null;
	vm.user      = items.user;
	vm.constants = constants;

	var disableLatLng = false;
	if (items.status == 'NEVER_SEEN' || !vm.user.can_mng_gtw) {
		disableLatLng = true;
	}

	vm.center = {
		lat: origCenter.latitude,
		lng: origCenter.longitude
	};

	vm.markers = [{
		layer: origCenter.layer,
		lat: origCenter.latitude || 0,
		lng: origCenter.longitude || 0,
		name: origCenter.name,
		disableLatLng: disableLatLng,
		disableName: !vm.user.can_mng_gtw && vm.user.can_access_gtw_unfiltered,
	}];

	vm.marker = vm.markers[0];

	vm.dragEnd = function (marker) {
		vm.marker = angular.copy(marker);
		vm.latVal = marker.lat;
		vm.lngVal = marker.lng;
		console.log("Map view drag end 3", vm.marker);
	}

	vm.recenter = function () {
		vm.marker.lat = vm.latVal || 0;
		vm.marker.lng = vm.lngVal || 0;
		vm.center = {
			lat: vm.marker.lat,
			lng: vm.marker.lng
		};

		if (!vm.latVal || !vm.lngVal) {
			vm.center.zoom = 1;
		}

		vm.markers = [vm.marker];
	}

	vm.ok = function () {
		console.log("closing with ", vm.marker)
		$uibModalInstance.close(vm.marker);
	};

	vm.cancel = function () {
		$uibModalInstance.dismiss('cancel');
	};
}]);

app.controller('AddGatewayModalCtrl', ["$scope", "$uibModalInstance", "items", function ($scope, $uibModalInstance, items) {

	$scope.gateway = {
		id: "",
	};
	$scope.items = items;

	$scope.ok = function () {

		var h = parseHexPayload($scope.gateway.id);

		if (h == null || h.length != 8) {
			return;
		}

		$uibModalInstance.close($scope.gateway);
	};

	$scope.cancel = function () {
		$uibModalInstance.dismiss('cancel');
	};
}]);
