import * as React from "react";
import {
    Button,
    Modal,
    Row,
    Col,
    FormGroup,
    ControlLabel,
    FormControl,
    HelpBlock
} from "react-bootstrap";
import * as L from "leaflet";

import { strings } from "./../../../services/Localization";

import "leaflet/dist/leaflet.css";

import MarkerIconDefault from "./Map/marker-icon-2x.png";

export default class EditPositionModal extends React.Component <any, any> {
    Map;
    Marker;
    constructor(props) {
        super(props);
        this.state = {
            coordinates: {
                latitude: "",
                longitude: "",
                altitude: "",
            },
            MapCenterPosition: [0, 0],
            zoom: 2,
            DeviceData: "",
            DevTitle: "",
        }
    }

    componentDidMount() {
        const { Data }: any = this.props;
        const { coordinates } = this.state;
        if (Data) {
            Object.keys(coordinates).map((pos) => {
                coordinates[pos] = ((Data[pos] !== null) && (Data[pos] !== undefined)) ? Data[pos] : "";
            });
            const DeviceData = {
                data: {
                    type: (Data ? Data.hasOwnProperty("deveui") ? "Devices" : "Gateways" : ""),
                    id: (Data ? Data.hasOwnProperty("deveui") ? Data.deveui : Data.id : null),
                    name: (Data ? Data.hasOwnProperty("deveui") ? Data.comment : Data.name : "")
                },
                position: coordinates,
            }
            this.setState({
                coordinates,
                MapCenterPosition: [coordinates.latitude, coordinates.longitude],
                zoom: (coordinates.latitude && coordinates.longitude) ? 14 : 2,
                DeviceData
            });
        }

        setTimeout(() => {
            let { MapCenterPosition, zoom } = this.state;
            // Initialize Map
            this.Map = L.map("leafletMapModal", {
                center: MapCenterPosition,
                zoom: zoom,
                maxZoom: 18,
                zoomControl: false,
                layers: [
                    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                        attribution: "&amp;copy <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                    })
                ]
            });

            // Zoom Control
            L.control.zoom({
                position: "topleft"
            }).addTo(this.Map);
            const marker = {
                id: (Data ? Data.hasOwnProperty("deveui") ? Data.deveui : Data.id : 0),
                latitude: (coordinates.latitude === "") ? 0 : coordinates.latitude,
                longitude: (coordinates.longitude === "") ? 0 : coordinates.longitude,
                type: (Data ? Data.hasOwnProperty("deveui") ? "Devices" : "Gateways" : ""),
            }
            this.CreateMarker(marker);
        }, 500);
    }

    CreateMarker = (marker) => {
        this.Marker = L.marker([marker.latitude, marker.longitude], {
            icon: (marker.type === "Gateways") ? this.GatewayMarkerIcon : (marker.type === "Devices") ? this.DevicesMarkerIcon : this.DefaultMarkerIcon,
            draggable: this.props.ReadOnly ? false : true,
            data: {
                id: marker.id,
                type: marker.type
            }
        }).on("dragend", (e) => {
            const pos = e.target.getLatLng();
            const { options } = e.target;
            this.onDragEnd({
                "position": {
                    altitude: this.state.coordinates.altitude,
                    latitude: pos.lat,
                    longitude: pos.lng
                },
                "data": options.data
            });
        }).addTo(this.Map);
    }

    onDragEnd = (obj) => {
        this.setState(prevState => ({
            coordinates: {
                ...this.state.coordinates,
                latitude: obj.position.latitude,
                longitude: obj.position.longitude
            },
            DeviceData: {
                ...prevState.DeviceData,
                position: {
                    ...prevState.DeviceData.position,
                    latitude: obj.position.latitude,
                    longitude: obj.position.longitude
                }
            }
        }));
    }

    getValidationState = (type) => {
        const { coordinates } = this.state;
        if (coordinates[type].length === 0) {
            return null;
        } else if (type === "latitude") {
            if ((coordinates[type] >= -90) && (coordinates[type] <= 90)) {
                return "success";
            } else {
                return "error";
            }
        } else if (type === "longitude") {
            if ((coordinates[type] >= -180) && (coordinates[type] <= 180)) {
                return "success";
            } else {
                return "error";
            }
        } else {
            return "error";
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            coordinates: {...this.state.coordinates, [name]: value || ""},
            DeviceData: {
                ...prevState.DeviceData,
                position: {
                    ...prevState.DeviceData.position,
                    [name]: parseFloat(value) || ""
                }
            }
        }));
        if (name === "latitude") {
            this.Marker.setLatLng(new L.LatLng(value, this.state.coordinates.longitude));
        } else if (name === "longitude") {
            this.Marker.setLatLng(new L.LatLng(this.state.coordinates.latitude, value));
        }
    }

    DefaultMarkerIcon = L.icon({
        iconUrl: MarkerIconDefault,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -41],
        className: "default_icon"
    });

    GatewayMarkerIcon = L.divIcon({
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -41],
        className: "gateway_icon",
        html: '<div class="marker_pin"><i class="fa fa-broadcast-tower"></div>'
    });

    DevicesMarkerIcon = L.divIcon({
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -41],
        className: "device_icon",
        html: '<div class="marker_pin"><i class="fa fa-cog"></div>'
    });

    validateValues = () => {
        const { latitude, longitude } = this.state.coordinates;
        if (latitude && longitude && ((latitude >= -90) && (latitude <= 90)) && ((longitude >= -180) && (longitude <= 180))) {
            return false;
        } else {
            return true;
        }
    }

    render() {
        const {
            latitude,
            longitude,
            altitude
        } = this.state.coordinates;
        const {
            DeviceData,
        } = this.state;
        const ReadOnly = this.props.ReadOnly ? true : false;
        return(
            <Modal
                show={this.props.ShowModal || false}
                onHide={() => {
                    if (this.props.CloseModal) {
                        this.props.CloseModal();
                    }
                }}
                bsSize="lg"
                className="ReactModal network_map_modal"
                keyboard={this.props.CloseOnEsc || false}
                backdrop={this.props.CloseWhenClickOutside || false}
            >
                <Modal.Header closeButton={false}>
                    <Modal.Title>
                        {strings.POSITION_ON_MAP_MODAL_TITLE}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12} className="change_device_title">
                            <FormGroup>
                                <ControlLabel>
                                    {(DeviceData && DeviceData.data.type === "Devices") ?
                                        strings.CHANGE_DEVICE_COMMENT_MODAL_LABEL :
                                        strings.CHANGE_GATEWAY_NAME_MODAL_LABEL
                                    }
                                </ControlLabel>
                                <FormControl
                                    type="text"
                                    value={(DeviceData && DeviceData.data.name) ? DeviceData.data.name : ""}
                                    onChange={(e:any) => {
                                        const { value } = e.target;
                                        this.setState(prevState => ({
                                            ...prevState,
                                            DeviceData: {
                                                ...prevState.DeviceData,
                                                data: {
                                                    ...prevState.DeviceData.data,
                                                    name: value
                                                }
                                            }

                                        }));
                                    }}
                                    disabled={ReadOnly}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={12} className="network_map_wrapper">
                            <p>{strings.VIEW_GATEWAY_ON_MAP_MODAL_LABEL}</p>
                            <div className="map_wrapper">
                                <div id="leafletMapModal"></div>
                            </div>
                        </Col>
                        <Col sm={12} className="coordinates_inputs_wrapper">
                            <div className="coordinates_inputs new_style_modal_bg_color padding_top_bottom new_style_coordinates">
                                <p>{strings.COORDINATES}</p>
                                <Row>
                                    <Col sm={4}>
                                        <FormGroup
                                            validationState={this.getValidationState("latitude")}
                                        >
                                            <FormControl
                                                type="text"
                                                name="latitude"
                                                value={latitude}
                                                onChange={this.handleChange}
                                                disabled={ReadOnly}
                                            />
                                            <FormControl.Feedback />
                                            <HelpBlock>{strings.LATITUDE}</HelpBlock>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                        <FormGroup
                                            validationState={this.getValidationState("longitude")}
                                        >
                                            <FormControl
                                                type="text"
                                                name="longitude"
                                                value={longitude}
                                                onChange={this.handleChange}
                                                disabled={ReadOnly}
                                            />
                                            <FormControl.Feedback />
                                            <HelpBlock>{strings.LONGITUDE}</HelpBlock>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                        <FormGroup
                                            // validationState={this.getValidationState("altitude")}
                                        >
                                            <FormControl
                                                type="text"
                                                name="altitude"
                                                value={altitude}
                                                onChange={this.handleChange}
                                                disabled={ReadOnly}
                                            />
                                            <FormControl.Feedback />
                                            <HelpBlock>{strings.ALTITUDE}</HelpBlock>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => this.props.OnConfirm(DeviceData)}
                        className="React_new_style_btn_ok"
                        disabled={this.validateValues() || ReadOnly}
                    >
                        {strings.POSITION_ON_MAP_MODAL_SAVE_POSITION}
                    </Button>
                    <Button
                        onClick={this.props.CloseModal}
                        className="React_new_style_btn_cancel"
                    >
                        {strings.CANCEL}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
