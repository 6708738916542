export const userData = (username: string | object, password?: string) => {
    return new Promise(async (resolve, reject) => {

        try {
            const tz = new Date().getTimezoneOffset();
            const tf = new Date().toLocaleTimeString().match(/am|pm/i) ? "ampm" : "24h";

            const response = await fetch("/signin?tz=" + tz + "&tf=" + tf, {
                body: JSON.stringify({
                    password: password || undefined,
                    userid: typeof username === "string" ? username : undefined,
                    code:   typeof username === "object" ? (username as any).code : undefined,
                    state:  typeof username === "object" ? (username as any).state : undefined,
                }),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });
            if (response.status === 200) {
                const data = await response.json();
                resolve(data);
            }
            else {
                reject(response);
            }
        } catch (error) {
            reject(error);
        }
    });
};

const emptyUuid = "00000000-0000-0000-0000-000000000000";


export const Whoami = () => {
    return new Promise(async (resolve, reject) => {

        try {
            const response = await fetch("/whoami", {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
            });

            const data = await response.json();
            if (data && data.user) {
                data.user.is_tenant = !(!data.user.tenant_id || data.user.tenant_id === emptyUuid);
            }
            resolve(data);
        } catch (e) {
            reject(e);
        }
    });
};

export const SignInForward = (userid) => {
    return new Promise(async (resolve, reject) => {

        try {
            const response = await fetch("/signinforward" + (userid ? "?userid=" + userid : ""), {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });
            if (response.status === 200) {
                const data = await response.json();
                resolve(data);
            }
            else if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }
            else {
                reject(response);
            }
        } catch (e) {
            reject(e);
        }
    });
};

export const SessionInfo = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/session_info", {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
            });
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
