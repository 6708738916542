import * as React from "react";
import {
  Button,
  Col,
  Modal,
  Row,
  Panel,
  Table,
} from "react-bootstrap";

import { strings } from "./../../services/Localization";
import { GetGatewayConfig } from "./../../services/Gateways";
import "./EnterpriseGatewayConfig.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "../../utils/Toaster";
import * as moment from "moment";
import { LocaleDate } from "./../../controllers/GlobalController";

export class EnterpriseGatewayConfig extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {
      ShowModal: true,
      Gateway: {},
    };
  }

  componentDidMount() {
    this.GetGatewayConfigFunc();
  }

  public LocaleDateFunc = (date) => {
    if(moment(date).isValid()){
      return moment(date).format(LocaleDate().DateTimeFormat);
    } else {
      return date;
    }
  }

  public GetGatewayConfigFunc = async () => {
    const { GatewayId } = this.props;
    try {
      const response: any = await GetGatewayConfig(GatewayId);
      if(response.status === 200) {
        const data = await response.json();
        this.setState({
          Gateway: data,
        });
      } else {
        const data = await response.text();
        toast.error(data);
      }
    } catch (e) {
      console.log(e);
      toast.error(strings.GATEWAY_ERROR_CONFIG);
    }
  }


  public closeModal = () => {
    this.setState({ ShowModal: false });
  }

  public render() {
    const {
      ShowModal,
      Gateway
    } = this.state;

    return (
      <div>
        <Modal
          show={ShowModal}
          onHide={this.closeModal}
          bsSize="sm"
          className="ReactModal enterprise-gateway-config"
          backdrop="static"
        >
          <Modal.Header closeButton={true}>
            <Modal.Title>
              {strings.formatString(strings.GATEWAY_CONFIGURATION_TITLE, {
                gatewayId: this.props.GatewayId
              })}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="padding_0_all">
            <Row>
              <Col sm={12} className="form-horizontal">
                <Panel>
                  <Panel.Heading>{strings.GATEWAY_VERSIONS}</Panel.Heading>
                  <Panel.Body>
                    <Row>
                      <Col md={6}>{strings.GATEWAY_TYPE}</Col>
                      <Col md={6}>{Gateway.gateway_type}</Col>
                    </Row>
                    <Row>
                      <Col md={6}>{strings.GATEWAY_FIRMWARE_VERSION}</Col>
                      <Col md={6}>{Gateway.gateway_firmware_version}</Col>
                    </Row>
                    <Row>
                      <Col md={6}>{strings.GATEWAY_SOFTWARE_VERSION}</Col>
                      <Col md={6}>{Gateway.gateway_software_version}</Col>
                    </Row>
                  </Panel.Body>
                </Panel>

                <Panel>
                  <Panel.Heading>{strings.GATEWAY_TX_POWER_AND_ANTENNA_GAIN}</Panel.Heading>
                  <Panel.Body>
                    <Row>
                      <Col md={6}>{strings.GATEWAY_RX1_POWER} (dBm)</Col>
                      <Col md={6}>{Gateway.rx1_tx_power}</Col>
                    </Row>
                    <Row>
                      <Col md={6}>{strings.GATEWAY_RX2_POWER} (dBm)</Col>
                      <Col md={6}>{Gateway.rx2_tx_power}</Col>
                    </Row>
                    <Row>
                      <Col md={6}>{strings.GATEWAY_CLASSB_TX_POWER} (dBm)</Col>
                      <Col md={6}>{Gateway.classb_tx_power}</Col>
                    </Row>
                    <br />
                    <Row>
                      <Col md={6}>{strings.GATEWAY_ANTENNA_GAIN} (dB)</Col>
                      <Col md={6}>{Gateway.antenna_gain}</Col>
                    </Row>
                    <Row>
                      <Col md={6}>{strings.GATEWAY_CABLE_LOSS} (dB)</Col>
                      <Col md={6}>{Gateway.cable_loss}</Col>
                    </Row>
                  </Panel.Body>
                </Panel>

                <Panel>
                  <Panel.Heading>{strings.GATEWAY_BEACON_CONFIGURATION}</Panel.Heading>
                  <Panel.Body>
                    <Row>
                      <Col md={6}>{strings.GATEWAY_BEACON_FREQUENCY} (MHz)</Col>
                      <Col md={6}>{Gateway.beacon_frequency}</Col>
                    </Row>
                    <Row>
                      <Col md={6}>{strings.GATEWAY_BEACON_ENABLE}</Col>
                      <Col md={6}>{`${Gateway.beacon_enabled}`}</Col>
                    </Row>
                  </Panel.Body>
                </Panel>

                <Panel>
                  <Panel.Heading>{strings.GATEWAY_FREQUENCY_CONFIGURATION}</Panel.Heading>
                  <Panel.Body>
                    <Table bordered condensed>
                      <thead>
                        <tr>
                          <th>
                            {strings.GATEWAY_FREQUENCY} (MHz)
                          </th>
                          <th>
                            {strings.GATEWAY_MINIMUM_DATA_RATE}
                          </th>
                          <th>
                            {strings.GATEWAY_MAXIMUM_DATA_RATE}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {(Gateway.hasOwnProperty("channels") && (Gateway.channels.constructor === Array) && Gateway.channels.length > 0) ?
                          Gateway.channels.map((channel, index) => (
                            <tr key={index}>
                              <td>{channel.frequency}</td>
                              <td>{channel.min_dr}</td>
                              <td>{channel.max_dr}</td>
                            </tr>
                          )) :
                          (<tr>
                            <td colSpan={3} className="text-center">{strings.GATEWAY_NO_CHANNELS}</td>
                          </tr>)
                        }
                      </tbody>
                    </Table>
                  </Panel.Body>
                </Panel>

                <Panel>
                  <Panel.Heading>{strings.GATEWAY_CONNECTION_STATUS}</Panel.Heading>
                  <Panel.Body>
                    <Row>
                      <Col md={6}>{strings.GATEWAY_LAST_REST_KA}</Col>
                      <Col md={6}>{Gateway.last_ns_udp_keepalive_date ? this.LocaleDateFunc(Gateway.last_ns_udp_keepalive_date) : ""}</Col>
                    </Row>
                    <Row>
                      <Col md={6}>{strings.GATEWAY_LAST_OMC_UDP_KA}</Col>
                      <Col md={6}>{Gateway.last_omc_udp_keepalive_date ? this.LocaleDateFunc(Gateway.last_omc_udp_keepalive_date) : ""}</Col>
                    </Row>
                    <Row>
                      <Col md={6}>{strings.GATEWAY_LAST_ANSWERED_PING}</Col>
                      <Col md={6}>{Gateway.last_rest_udp_keepalive_date ? this.LocaleDateFunc(Gateway.last_rest_udp_keepalive_date) : ""}</Col>
                    </Row>
                  </Panel.Body>
                </Panel>

              </Col>
            </Row>

          </Modal.Body>

          <Modal.Footer className="new_style_modal_footer">
            <Button
              onClick={this.closeModal}
              className="React_new_style_btn_cancel"
            >
              {strings.CLOSE}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
