var app = angular.module('dassUiModule');

function GatewayController(GatewayService, ToastService, UserService, MessageService, $timeout, $filter, $uibModal, $log) {
    const $translate = $filter("translate");
    const formatDeveui = $filter("formatDeveui");

    let vm = this;
	vm.workingCount = 0;
	vm.dataType = '/uiapi/rest/gateways';
	vm.selectedGateways = 0;
	vm.numberOfGateways = 0;
	vm.scanning         = 0;
	vm.constants = constants;

	vm.progressCb = (ctrl, done) => {
		vm.scanning         = done ? 0 : ctrl.scanned;
		vm.numberOfGateways = ctrl.pageData && ctrl.pageData.total || 0;
	}

	vm.loadGateways = (gateway) => {
		if (!vm.reloadData) return;
        var reloadPromise = vm.reloadData();
        if (reloadPromise) {

            vm.workingCount += 1;
            reloadPromise.then(() => {
				vm.type = type;
				vm.workingCount -= 1;

            }).catch(err => {
                vm.workingCount -= 1;
            });
		}
	};

	vm.tableSelectionChanged = (gateway) => {
        console.log("Selection changed ", gateway);

        vm.selectedGateways = gateway;
	};

	vm.gatewayConfiguration = (gateway) => {
		window.eGatewayConfigModal.showEnterpriseConfigModal(gateway.id);
	};

	vm.viewGatewayOnMap = (gateway) => {
		window.gatewayModals.showPositionOnMap(gateway);
	};

	vm.addGateway = () => {
		const modalInstance = $uibModal.open({
			backdrop: "static",
			animation: vm.animationsEnabled,
			templateUrl: 'addGatewayModalContent.html',
			controller: 'AddGatewayModalCtrl',
			size: "",
			resolve: {
				items: () => {
					return [];
				}
			}
		});

		modalInstance.result.then((gateway) => {
            GatewayService.addGateway(gateway).then(
				(response) => {
					ToastService.showMessage($translate('MSG_GATEWAY_ADDED_SUCCESS_BODY', gateway), "success")

					vm.loadGateways();
				},
				(response) => {
					if(response.status === 408){
						ToastService.showMessage($translate(response.data), "error")
					}
					if(response.status === 412){
                        ToastService.showMessage($translate('MAX_NUM_REACHED', {
                            item: "gateways"
                        }), "error");
					}
					if((response.status != 408) && (response.status != 412)) {
						ToastService.showMessage($translate('MSG_GATEWAY_ADDED_FAIL_BODY'), "error")
					}
				}
			);
		}, () => {
			$log.info('Modal dismissed at: ' + new Date());
		});
	};

	vm.deleteGateway = (gateway) => {
		MessageService.showMessage({
			title: $translate('MSG_DELETE_GATEWAY_TITLE'),
			body: $translate('MSG_DELETE_GATEWAY_BODY', gateway)
		}).then((ok) => {
			console.log("sending delete gateway");
			GatewayService.deleteGateway(gateway.id).then(
				(response) => {
					ToastService.showMessage($translate('MSG_DELETE_GATEWAY_SUCCESS_BODY'), "success");
					vm.loadGateways();
				},
				(response) => {
					ToastService.showMessage($translate('MSG_DELETE_GATEWAY_FAIL_BODY'), "error");
				}
			);
		});
	};

	vm.deleteGateways = () => {
		MessageService.showMessage({
			title: $translate('MSG_DELETE_GATEWAYS_TITLE'),
			body: $translate('MSG_DELETE_GATEWAYS_BODY', {
				count: vm.selectedGateways.length
			})
		}).then((ok) => {
			console.log("sending delete gateway");

			GatewayService.deleteGateways(vm.selectedGateways).then(
				(response) => {
					ToastService.showMessage($translate('MSG_DELETE_GATEWAYS_SUCCESS_BODY', vm.selectedGateways), "success");
					vm.loadGateways();
				},
				(response) => {
					ToastService.showMessage($translate('MSG_DELETE_GATEWAYS_FAIL_BODY',vm.selectedGateways), "error");
				}
			);
		});
	};

	vm.open = function () {
		var modalInstance = $uibModal.open({
		  backdrop: "static",
		  animation: true,
		  ariaLabelledBy: 'modal-title',
		  ariaDescribedBy: 'modal-body',
		  templateUrl: 'customErrorModal.html',
		  controller: 'CustomErrorModalController as vm',
		  size: 'error-modal-class',
		  resolve: {
			items: () => {
				return {
					error: "The User does not have permission to see this page.",
					title: "Error"
				};
			}
		   }
		});

		modalInstance.result.then(function () {
			window.location.href = "/app";
		});
	};

    vm.loadUserData = () => {
        vm.workingCount += 1;
        UserService.getUserData().then(
            (userData) => {
				vm.user = userData.user;

				if(!userData.user.can_own_gtw){
					vm.open();
				}
                vm.currentDevice = userData.currentDevice;
                vm.workingCount -= 1;
            },
            (response) => {
                console.log("error getting user data:" + response.status);
                vm.workingCount -= 1;
            }
        )
	}

	vm.openEnterpriseAddGatewayModal = () => {

        // calling the modal functionality written in React
        window.eAddGatewayModal.showEnterpriseAddModalModal({});
	}

	vm.initDataTable = () => {

		vm.loadGateways();
		vm.actions= [
		{
			type: "action",
			text: "GATEWAY_CONFIGURATION",
			icon: "fa fa-cog fa-fw",
			action: (gateway)=> vm.gatewayConfiguration(gateway),
			visible: (entry, gateway) => gateway.altitude != null &&
									     vm.user.account_status==="active", // the altitude field should always be there
		},{
			type: "action",
			text: "VIEW_GATEWAY",
			icon: "fa fa-map-o fa-fw",
			action: (gateway)=> vm.viewGatewayOnMap(gateway),
			visible: (entry, gateway) => gateway.altitude != null &&
										 vm.user.account_status==="active",	// the altitude field should always be there
		},{
			type: "separator",
			visible: (entry, gateway) => gateway.altitude != null && (vm.user.can_mng_gtw || vm.user.can_add_gtw) &&
										 vm.user.account_status==="active",
		},{
			type: "action",
			text: "DELETE_GATEWAY",
			icon: "fa fa-trash-o fa-fw",
			action: (gateway)=> vm.deleteGateway(gateway),
			visible: (entry, gateway) => vm.user.can_mng_gtw || vm.user.can_add_gtw,
		}];

		vm.bulkActions= [{
			type: "action",
			text: "DELETE_GATEWAYS",
			icon: "fa fa-trash-o fa-fw",
			action: vm.deleteGateways
		}];

		vm.columns= [{
			key: "id",
			type: "text",
			title: "GATEWAY_ID",
			filterable: true,
			filterField: 'search_id',
			filterType: 'text',
			filterParams: {
				mapper: (x) => x || undefined
			}
		}, {
			key: "name",
			title: "GATEWAY_NAME",
			type: "text",
			filterable: true,
			filterField: 'search_name',
			filterType: 'text',
			filterParams: {
				mapper: (x) => x || undefined
			}
		}, {
			key: "status",
			title: "STATUS",
			type: "text",
			filterable: true,
			filterField: 'search_status',
			filterType: 'select',
			filterParams: {
				options: [
					{
						label: "GATEWAY_STATUS_ANY",
						value: ""
					},
					{
						label: "GTW_STATUS_NEVER_SEEN",
						value: "NEVER_SEEN"
					},
					{
						label: "GTW_STATUS_GTW_INIT",
						value: "GTW_INIT"
					},
					{
						label: "GTW_STATUS_OK",
						value: "OK"
					},
					{
						label: "GTW_STATUS_OFF",
						value: "OFF"
					},
					{
						label: "GTW_STATUS_BACKHAUL_ISSUE",
						value: "BACKHAUL_ISSUE"
					},
					{
						label: "GTW_STATUS_OFF_OR_BACKHAUL_ISSUE",
						value: "OFF_OR_BACKHAUL_ISSUE"
					},
					{
						label: "GTW_STATUS_RADIO_OFF",
						value: "RADIO_OFF"
					}
				]
			},
			render: (x) => (x.status === "NEVER_SEEN" && $translate("GTW_STATUS_NEVER_SEEN") || x.status === "GTW_INIT" && $translate("GTW_STATUS_GTW_INIT")
							|| x.status === "OK" && $translate("GTW_STATUS_OK") || x.status === "OFF" && $translate("GTW_STATUS_OFF") ||
							x.status === "BACKHAUL_ISSUE" && $translate("GTW_STATUS_BACKHAUL_ISSUE") || x.status === "OFF_OR_BACKHAUL_ISSUE" &&
							$translate("GTW_STATUS_OFF_OR_BACKHAUL_ISSUE") || x.status === "RADIO_OFF" && $translate("GTW_STATUS_RADIO_OFF"))
		}]
	};
	vm.loadUserData();
	Promise.all([vm.loadGateways()]).then(vm.initDataTable);
};

app.controller("GatewayController", ["GatewayService", "ToastService", "UserService", "MessageService", "$timeout", "$filter", "$uibModal", "$log", GatewayController])
