import { error } from "util";

var app = angular.module('dassUiModule');


// Copy supported regions from DASS.
const validRegionsStr = "^EU868$|^US915$|^CN779$|^EU433$|^AU915$|^CN470$|^AS923$|^KR920$|^IN865$|^RU864$|"
                                + "^JP923$|^China779$|^Australia915$|^China470$|^INDIA$";
const validRegions = new RegExp(validRegionsStr, "i");


function BatchRegistrationController(BatchRegDataService, UserService, MessageService, ToastService, Upload, DeviceService, $filter, $timeout, $log) {
	var $translate = $filter("translate");
	var formatDeveui = $filter("formatDeveui");
	var sortColumn = $filter('sortByColumn');

	var vm = this;
	vm.regDevProgress = "";
	vm.regDevProgressMax = 0;
	vm.regDevProgressCurrent = 0;
	vm.regDevProgressErrors = 0;
	vm.currentPage = 1;
	vm.itemsPerPage = 25;
	vm.tableOffsetIdx = 0;
	vm.tableData = null;
	vm.pageData = null;
	vm.registered_devices = 0;
	var websoc = null;
	var pendingStatus = null;
	vm.actionOn = false;
	vm.duplicate = false;
	vm.allSelected = true;
	vm.selectedItems = [];

	DeviceService.getDevices().then(res => {
		vm.registered_devices = res.length;
	}).catch(err => {
		console.log(err);
	})

	const allowedProperties = [
		"appeui",
		"joineui",
		"comment",
		"deveui",
		"devaddr",
		"device_status",
		"registration_status",
		"groups",
		"applications",
		"appkey",
		"appskey",
		"snwksintkey",
		"fnwksintkey",
		"nwksenckey",
		"expiry_time_downlink",
		"expiry_time_uplink",
		"lora_device_class",
		"last_reception",
		"join_nonce_cnt",
		"dl_fcnt",
		"ownerid",
		"nwkskey",
		"lora_major",
		"lora_rx_delay1",
		"lora_rx_delay2",
		"lora_fcnt_32bit",
		"lora_rx2_sf",
		"redundant_uplink_cnt",
		"max_allowed_dutycycle",
		"expected_avr_dutycycle",
		"activation",
		"options",
		"device_properties",
		"qos_class",
		"lora_location",
		"activated",
		"downlink_allowed",
		"tenant_id",
		"MACVersion",
		"RegParamsRevision",
		"MaxEIRP",
		"RFRegion",
		"ul_fcnt",
	]

	vm.deviceProfiles = function (array) {
		if (array.includes("deveui") && (array.includes("device_profile_uuid") ||
			(!array.includes("device_profile_uuid") && !array.includes("service_profile_uuid")))) {
			return true;
		} else if (!array.includes("device_profile_uuid") || !array.includes("service_profile_uuid") || !array.includes("deveui")){
			return false;
		} else {
			return false;
		}
	}

	vm.tableIdx = function (idx) {
		return (vm.currentPage - 1) * vm.itemsPerPage + vm.tableOffsetIdx + idx;
	};

	vm.tableLength = function () {
		return vm.tableData != null ? vm.tableData.length - vm.tableOffsetIdx : 0;
	};

	vm.pageChangeCb = function () {
		console.log("page change");
		vm.updateTablePage();
	};

	vm.updateTablePage = function () {
		try {
			var f = (vm.currentPage - 1) * vm.itemsPerPage + vm.tableOffsetIdx;
			vm.pageData = vm.tableData.slice(f, f + vm.itemsPerPage);
			vm.registerDisable = true;
		} catch (e) {
			vm.pageData = [];
			vm.registerDisable = false;
			vm.duplicate = false;
			console.log("no data currently set");
		}
	}

	vm.setTableData = function (table) {

		console.log("Setting table data");
		vm.tableData = table;
		vm.updateTablePage();
	};

	var createWebSocket = function (path, protocols) {
		var protocolPrefix = (location.protocol === 'https:') ? 'wss://' : 'ws://';
		var ws = null;

		try {
			console.log(protocolPrefix + location.host + path);
			ws = new WebSocket(protocolPrefix + location.host + path, protocols);
		} catch (e) {
			console.log(e);
		}
		return ws;
	}

	var startRegDataWebSocket = function () {
		if (websoc == null) {
			websoc = createWebSocket('/regdataws', ['json']);

			if (websoc == null) {
				return;
			}

			console.log(websoc);

			websoc.onopen = function () {
				console.log("socket open");
				websoc.send('Ping'); // Send the message 'Ping' to the server
			};

			websoc.onmessage = function (msg) {
				console.log("Socket message");
				var o = msg.data;
				if (typeof o === "string") {
					o = JSON.parse(o);
				}

				if (typeof o.total === "number" && typeof o.completed === "number") {
					$timeout(function () {
						var i;
						vm.regDevProgressMax     = o.total;
						vm.regDevProgressCurrent = o.completed + o.skipped;
						vm.regDevProgressErrors  = o.errors;
						vm.regDevProgress        = vm.regDevProgressCurrent + "/" + vm.regDevProgressMax;

						if (o.status && vm.regData) {
							for (i = 0; i < o.status.length; i++) {
								if (typeof o.status[i].index === "number" && o.status[i].status != null) {
									vm.regData.status[o.status[i].index] = o.status[i].status;
								}
							}
						}

						if (o.status && pendingStatus) {
							for (i = 0; i < o.status.length; i++) {
								pendingStatus.push(o.status[i]);
							}
						}
					});
				}
			};

			websoc.onclose = function (msg) {
				console.log("Socket close");

				websoc = null;
			};
		}
	}

	vm.hasDuplicates = function (array) {
		return (new Set(array).size !== array.length)
	}

	vm.loadRegData = function () {
		// open the websocket
		if (!vm.duplicate) {
			vm.allSelected = true;
			startRegDataWebSocket();

			console.log("Reading data");

			pendingStatus = [];

			BatchRegDataService.loadRegData().then(
				function (data) {
					console.log("got the data");
					vm.regData = data;
					if (data && vm.hasDuplicates(data.data[0])) {
						vm.duplicate = true;
						ToastService.showMessage($translate('DUPLICATE_HEADERS'), "error");
						return;
					} else {
						// Apply any updates to the status that was received while still loading the data.
						var i;
						for (i = 0; i < pendingStatus.length; i++) {
							if (typeof pendingStatus[i].index === "number" && pendingStatus[i].status != null) {
								vm.regData.status[pendingStatus[i].index] = pendingStatus[i].status;
							}
						}
						pendingStatus = null;

						vm.tableOffsetIdx = 1;
						vm.setTableData(vm.regData && vm.regData.data);
					}
				},
				function (response) {
					vm.regData = null;
					pendingStatus = null;
					vm.setTableData(null);
				}
			);
		}
	};

	vm.changeTotal = function (index) {

/*
		console.log("changeTotal");
		if (isNumber(vm.regDevProgress)) {
			if (vm.regData.selected[index] === false) {
				vm.regDevProgress--;
			} else {
				vm.regDevProgress++;
			}
		} else {
			vm.regDevProgress.split("/");
			var change = parseInt(vm.regDevProgress.split("/")[1]);
			if (vm.regData.selected[index] === false) {
				change -= 1;
			} else {
				change += 1;
			}
			vm.regDevProgress = change;
		}
*/
	}

	vm.refreshDevices = function () {
		vm.actionOn = true;
		vm.regDevProgressCurrent = 0;
		vm.regDevProgressErrors  = 0;

		BatchRegDataService.refreshDevices({ selected: vm.regData && vm.regData.selected }).then(
			(response) => {
				vm.actionOn = false;
				if (response.data === null) {
					vm.regDevProgress = "";
				} else {
					vm.regDevProgressCurrent = response.data.completed + response.data.skipped;
					vm.regDevProgressErrors  = response.data.errors;
					vm.regDevProgress = response.data.completed + "/" + response.data.total;
					vm.loadRegData();
				}
				console.log("Got reponce from getstatus_devices");
			},
			(response) => {
				console.log("Failed regstatus_devices");
			}
		);

	};

	vm.checkDataType = (inputHeader, dataValues, validators, validatorProperties) => {
		const validatableProperties = Object.keys(validatorProperties);
		const errors = [];

		for (let i = 0; i < inputHeader.length; i++) {
			const property = inputHeader[i];
			if (!validatableProperties.includes(property)) {
				continue;
			}

			const validatorProperty = validatorProperties[property];
			const validator = validators[validatorProperty.type];

			validator.params = validatorProperty.params;
			const input = dataValues[i];
			if (!input) { continue; }	// skip empty fields
			const valid = validator.validate(input);
			let errorMessage = validator.errorMessage && validator.errorMessage(property, input);

			if (!errorMessage) {
				errorMessage = $translate('BATCH_FIELD') + `"${property}"` + $translate('BATCH_INVALID_VALUE');
			}

			if (!valid) {
				errors.push(errorMessage)
			}
		}

		return errors;
	}

	const validatorProperties = {
		"appeui": { type: "hex8" },
		"joineui": { type: "hex8" },
		"comment": { type: "string" },
		"deveui": { type: "hex8", required: true },
		"devaddr": { type: "hex" },
		"device_status": { type: "range", params: { from: 0, to: 3, fromInclusive: true, toInclusive: true } },
		"registration_status": { type: "range", params: { from: 0, to: 3, fromInclusive: true, toInclusive: true } },
		"groups": { type: "string" },
		"applications": { type: "string" },
		"appkey": { type: "hex16" },
		"snwksintkey": { type: "hex16" },
		"fnwksintkey": { type: "hex16" },
		"nwksenckey": { type: "hex16" },
		"appskey": { type: "hex16" },
		"expiry_time_downlink": { type: "range", params: { from: 1, to: 1000000, fromInclusive: true, toInclusive: true } },
		"expiry_time_uplink": { type: "range", params: { from: 1, to: 1000000, fromInclusive: true, toInclusive: true } },
		"lora_device_class": { type: "range", params: { from: 0, to: 2, fromInclusive: true, toInclusive: true } },
		"dl_fcnt": { type: "number" },
		"ul_fcnt": { type: "number" },
		"join_nonce_cnt": { type: "range", params: { from: 0, to: 0xffffff, fromInclusive: true, toInclusive: true } },

		"nwkskey": { type: "hex16" },
		"lora_major": { type: "zero" },
		"lora_rx_delay1": { type: "range", params: { from: 1, to: 5, fromInclusive: true, toInclusive: true } },
		"lora_fcnt_32bit": { type: "bool", required: true },
		"lora_rx2_sf": { type: "sfrange", required: true },
		"redundant_uplink_cnt": { type: "range", params: { from: 0, to: 6, fromInclusive: true, toInclusive: true } },
		"max_allowed_dutycycle": { type: "procents" },
		"expected_avr_dutycycle": { type: "procents" },
		"activation": { type: "activation" },
		"device_properties": { type: "properties" },
		"qos_class": { type: "range", params: { from: 0, to: 3, fromInclusive: true, toInclusive: true } },
		"lora_location": { type: "bool" },
		"activated": { type: "bool" },
		"downlink_allowed": { type: "bool" },
		"tenant_id": { type: "not_supported" },
		"device_profile_uuid": { type: "ProfileUUID", required: true },
		"service_profile_uuid": { type: "ProfileUUID", required: true },
		"options": { type: "options" },
		"tags": { type: "tags" },

/*
		"MACVersion": { type: "MACVersion", required: true },
		"RegParamsRevision": { type: "RegParamsRevision", required: true },
		"RFRegion": { type: "RFRegion" },
		"MaxEIRP": { type: "number", required: true },
*/

		// case-less versions
		"maxeirp": { type: "number", required: true },
		"regparamsrevision": { type: "RegParamsRevision", required: true },
		"macversion": { type: "MACVersion", required: true },
		"rfregion": { type: "RFRegion" },
	}

	const validators = {
		"hex8": {
			validate: function (arg) {
				return typeof arg === "string" && arg.match(/^[0-9a-fA-F]{16}$/) != null;
			},
			errorMessage: function (header, data) {
				return `${header}` + $translate('BATCH_MUST_8');
			}
		},
		"string": {
			validate: function (arg) {
				return typeof arg === "string" || arg === null || arg === "";
			},
			errorMessage: function (header, data) {
				return `${header}` + $translate('BATCH_MUST_BE_A_STRING');
			}
		},
		"hex16": {
			validate: function (arg) {
				return typeof arg === "string" && arg.match(/^[0-9a-fA-F]{32}$/) != null;
			},
			errorMessage: function (header, data) {
				return `${header}` + $translate('BATCH_ERROR_MUST_16BIT');
			}
		},
		"hex": {
			validate: function (arg) {
				return typeof arg === "string" && arg.match(/^((0[xX][0-9a-fA-F]{1,8})|([0-9a-fA-F]{8}))$/) != null;
			},
			errorMessage: function (header, data) {
				return `${header}` + $translate('BATCH_ERROR_MUST_32BIT');
			}
		},
		"range": {
			params: {
				fromInclusive: true,
				toInclusive: true,
				from: null,
				to: null
			},
			validate: function (arg) {
				let valid = true;
				arg = parseInt(arg);
				if (!arg && arg !== 0) {
					return false;
				}

				if (arg < this.params.from || arg > this.params.to) {
					return false;
				}

				if (this.params.from !== null) {
					valid = this.params.fromInclusive ? arg >= this.params.from : arg > this.params.from;
				}

				if (this.params.to !== null) {
					valid = this.params.toInclusive ? arg <= this.params.to : arg < this.params.to;
				}

				return valid;
			},
			errorMessage: function (header, data) {
				return `${header}` + $translate('BATCH_VALID_VALIE_BETWEEN') + `${this.params.from}` + $translate('BATCH_TO') + `${this.params.to}`;
			}
		},
		"not_supported": {
			validate: function () { return false },
			errorMessage: function (header, data) {
				return `${header}` + $translate('BATCH_VALID_IS_NOT_SUPPORTED');
			}
		},
		"bool": {
			validate: function (arg) {
				if (arg != null || arg != "") {
					return arg.toLowerCase() === "false" || arg.toLowerCase() === "true";
				}
			},
			errorMessage: function (header, data) {
				return `${header}` + $translate('BATCH_MUST_BE_BOOLEN');
			}
		},
		"number": {
			validate: function (arg) {
				let number = parseInt(arg);
				return isFinite(number);
			},
			errorMessage: function (header, data) {
				return `${header}` + $translate('BATCH_CAN_ONLY_BE_A_NUMBER');
			}
		},
		"sfrange": {
			validate: function (arg) {
				return /^sf[7-9](bw125|bw250|bw500)?$|^sf1[0-2](bw125|bw250|bw500)?$/i.test(arg);
			},
			errorMessage: function (header, data) {
				return $translate('BATCH_ALLOWED_VALUES_FOR') + `${header}` + $translate('BATCH_ALLOWED_VALUES_FOR_IS');
			}
		},
		"properties": {
			validate: function (arg) {
				return arg === "static" || arg === "mobile" || arg === "indoor" || arg === "outdoor" || arg === "static,indoor" || arg === "static,outdoor" || arg === "mobile,indoor" || arg === "mobile,outdoor";
			},
			errorMessage: function (header, data) {
				return $translate('BATCH_ALLOWED_VALUES_FOR') + `${header}` + $translate('BATCH_ALLOWED_VALUES_FOR_STATIC');
			}
		},
		"activation": {
			validate: function (arg) {
				arg = arg.toLowerCase();
				return arg === "otaa" || arg === "abp";
			},
			errorMessage: function (header, data) {
				return `${header}` + $translate('BATCH_OTAA_ABP');
			}
		},
		"procents": {
			validate: function (arg) {
				return arg >= 0 && arg <= 100;
			},
			errorMessage: function (header, data) {
				return `${header}` + $translate('BATCH_0_9');
			}
		},
		"zero": {
			validate: function (arg) {
				if (arg != null && arg != "") {
					return arg == 0;
				}
			},
			errorMessage: function (header, data) {
				return `${header}` + $translate('BATCH_0');
			}
		},
		"MACVersion": {
			validate: function (arg) {
				return arg === "1.1.1" || arg === "1.0.4" || arg === "1.0.3" || arg === "1.0.2" || arg === "1.0.1"
					|| arg === "1.0.0";
			},
			errorMessage: function (header, data) {
				return `${header}` + $translate('BATCH_CAN_ONLY_VERSION');
			}
		},
		"RegParamsRevision": {
			validate: function (arg) {
				return arg === "A" || arg === "B" || arg === "RP002-1.0.0" || arg === "RP002-1.0.1" || arg === "RP002-1.0.2";
			},
			errorMessage: function (header, data) {
				return `${header}` + $translate('BATCH_CAN_A_B');
			}
		},
		"RFRegion": {
			validate: function (arg) {
				return validRegions.test(arg);
			},
			errorMessage: function (header, data) {
				return `${header}` + $translate('BATCH_CAN_EU_US_CH');
			}
		},
		"ProfileUUID": {
			validate: function (arg) {
				let pattern = new RegExp("[0-9a-zA-Z]{8}(-[0-9a-zA-Z]{4}){3}-[0-9a-zA-Z]{12}");
				return pattern.test(arg);
			},
			errorMessage: function (header, data) {
				return `${header}` + $translate('BATCH_WRONG_FORMAT');
			}
		},
		"options": {
			validate: function (arg) {
				if (arg === null) {
					arg = "";
				}
				return arg.length < 9;
			},
			errorMessage: function (header, data) {
				return `${header}` + $translate('BATCH_WRONG_FORMAT');
			}
		},
		"tags": {
			validate: (arg) => {
				try {
					const tagsObj = JSON.parse(arg)
					const tagsKeys = Object.keys(tagsObj);
					for (const key of tagsKeys) {
						const type = typeof tagsObj[key];
						if (type !== "string" && type !== "number") {
							return false;
						}
					}
					return true;
				} catch (e) {
					return false;
				}
			},
			errorMessage: function (header, data) {
				return `${header}` + " invalid JSON"; // $translate('BATCH_ERROR_MUST_32BIT');
			}
		}
	}

	vm.checkMandatoryFields = (inputHeader, validatorProperties) => {
		const validatableProperties = Object.keys(validatorProperties);
		const errors = [];

		// if 'service_profile_uuid' and 'device_profile_uuid' are present in the headers,
		// that means that device with profiles are added and no further mandatory checks are needed
		if (inputHeader.includes('service_profile_uuid') || inputHeader.includes('device_profile_uuid')) {
			return true;
		}

		let bFlag = true;
		angular.forEach(validatorProperties, function(value, key){
			if (value.required &&
				!inputHeader.includes(key) &&
				(key != 'device_profile_uuid' && key != 'service_profile_uuid')) {

				errors.push(key);
				bFlag = false;
			}
		});

		angular.forEach(errors, function(key){
			ToastService.showMessage($translate('BATCH_REGISTER_DEVICES_MISSING_REQUIRED_FIELDS', {
				field_name: key
			}), "error");
		});

		return bFlag;
	}

	vm.registerDevices = () => {
		let column = null;
		let column_num = 0;

		// copy header to lower case for easier checking
		const inputHeader = [];
		for (const h of vm.regData.data[0]) {
			inputHeader.push(h.toLowerCase());
		}

		const valid = inputHeader.every(property => {
			column_num++;
			column = property;
			return allowedProperties.includes(property);
		});

		const bHasAllMandatoryFields = vm.checkMandatoryFields(inputHeader, validatorProperties);

		if (!bHasAllMandatoryFields) {
			return;
		}

		if (vm.regData.total - vm.registered_devices > vm.user.max_num_devices) {
			ToastService.showMessage($translate('BATCH_REGISTER_MAX_DEVICES'), "error");
			return;
		}
		vm.actionOn = true;
		vm.regDevProgressCurrent = 0;
		vm.regDevProgressErrors  = 0;
		if (!valid && column === "") {
			ToastService.showMessage($translate('BATCH_REGISTER_DEVICES_ERROR1', { column_num }), "error");
			return;
		} else if (!valid && !vm.deviceProfiles(inputHeader)) {
			ToastService.showMessage($translate('BATCH_REGISTER_DEVICES_ERROR2'), "error");
			return;
		} else if (!vm.deviceProfiles(inputHeader)) {
			ToastService.showMessage($translate('BATCH_REGISTER_DEVICES_ERROR3'), "error");
			return;
		}

		for (let i = 1; i < vm.regData.data.length; i++) {
			if (vm.regData.selected[i] === true) {
				const errors = vm.checkDataType(vm.regData.data[0], vm.regData.data[i], validators, validatorProperties);

				if (errors.length > 0) {

					errors.forEach((error) => {
						ToastService.showMessage($translate(error), "error");
					});
					ToastService.showMessage(`${errors.length}` + $translate('BATCH_ERROR_ROW') + `${i}.`, + $translate('BATCH_ERROR'));
					return;
				}
			}
		}

		BatchRegDataService.registerDevices({ selected: vm.regData && vm.regData.selected }).then(
			(response) => {
				if (response.data.status === 400) {
					var index = response.data.error_registering_index;
					let message_error = response.data.message_error;
					ToastService.showMessage($translate('BATCH_REGISTER_DEVICES_SERVER_ERROR', { index,  message_error}), "error");
					// return;
				}
				vm.loadRegData();
				vm.actionOn = false;
				vm.regDevProgressCurrent = response.data.completed + response.data.skipped;
				vm.regDevProgressErrors  = response.data.errors;
				vm.regDevProgress = response.data.completed + "/" + response.data.total;
				console.log("Got reponce from register_devices");
			},
			(response) => {
				ToastService.showMessage($translate('BATCH_ERROR_REGISTERING'), "error");
				console.log("Failed register_devices");
			}
		);
	};

	vm.updateDevices = () => {
		vm.actionOn = true;
		vm.regDevProgressCurrent = 0;
		vm.regDevProgressErrors  = 0;
		if (vm.regData) {
			for (let i = 1; i < vm.regData.data.length; i++) {
				if (vm.regData.selected[i] === true) {
					const errors = vm.checkDataType(vm.regData.data[0], vm.regData.data[i], validators, validatorProperties);
					if (errors.length > 0) {

						errors.forEach((error) => {
							ToastService.showMessage($translate(error), "error");
						});
						ToastService.showMessage(`${errors.length}` + $translate('BATCH_ERROR_ROW') + `${i}.`, + $translate('BATCH_ERROR'));
						// return;
					}
				}
			}
			BatchRegDataService.updateDevices({ selected: vm.regData && vm.regData.selected }).then(
				(response) => {
					if (response.data.status >= 400) {
						var index = response.data.error_registering_index;
						let message_error = response.data.message_error;
						ToastService.showMessage($translate('BATCH_UPDATE_DEVICES_SERVER_ERROR', { index,  message_error}), "error");
						// return;
					}
					vm.loadRegData();
					vm.actionOn = false;
					vm.regDevProgressCurrent = response.data.completed  + response.data.skipped;
					vm.regDevProgressErrors  = response.data.errors;
					vm.regDevProgress = response.data.completed + "/" + response.data.total;
					console.log("Got reponce from update_devices");
				},
				(response) => {
					ToastService.showMessage($translate('BATCH_ERROR_UPDATE'), "error");
				}
			);
		}
	};

	vm.deleteDevices = () => {
		vm.actionOn = true;
		vm.regDevProgressCurrent = 0;
		vm.regDevProgressErrors  = 0;
		if (vm.regData) {
			BatchRegDataService.deleteDevices({ selected: vm.regData && vm.regData.selected }).then(
				(response) => {
					vm.loadRegData();
					vm.actionOn = false;
					vm.regDevProgressCurrent = response.data.completed + response.data.skipped;
					vm.regDevProgressErrors  = response.data.errors;
					vm.regDevProgress = response.data.completed + "/" + response.data.total;
					console.log("Got reponce from delete_devices");
				},
				(response) => {
					console.log("Failed delete_devices");
				}
			);
		}
	};
	vm.clearDevices = function () {
		BatchRegDataService.clearDevices().then(
			function (response) {

				vm.regData = null;
				vm.setTableData(null);
				vm.regDevProgress = "";
				vm.regDevProgressMax = 0;
				vm.regDevProgressCurrent = 0;
				vm.regDevProgressErrors  = 0;
				console.log("Got reponce from delete_devices");
			},
			function (response) {
				console.log("Failed delete_devices");
			}
		);
		vm.registerDisable = false;
	};



	vm.file = null;
	vm.fileChanged = function () {
		vm.upload(vm.file);
		vm.registerDisable = true;
	}
	vm.upload = function (file) {
		console.log("Invoked upload " + file);
		var i;

		if (file && !file.$error) {
			Upload.upload({
				url: '/upload_registration_file',
				data: {
					file: file
				}
			}).then(function (resp) {
				vm.loadRegData();
				if (!vm.duplicate) {
					vm.refreshDevices();
				}
			}, function (err) {
				console.log(err);
			}, function (evt) {
				vm.regDevProgressMax = evt.total;
				vm.regDevProgressCurrent = evt.loaded;
				vm.regDevProgressErrors  = 0;
				vm.regDevProgress = "";
			});
		}
	};

	vm.loadUserData = function () {
		vm.workingCount += 1;
		UserService.getUserData().then(
			function (userData) {
				vm.user = userData.user;
				vm.currentDevice = userData.currentDevice;
				vm.workingCount -= 1;
			},
			function (response) {
				console.log("error getting user data:" + response.status);
				vm.workingCount -= 1;
			}
		)
	}

	vm.selectAll = () => {
		let j = 1 + (vm.currentPage - 1) * vm.itemsPerPage;
		for (let i = 0; i < vm.pageData.length; i++) {
			vm.regData.selected[j] = true;
			j++;
		}
		vm.allSelected = true;
	}

	vm.toggleItemsSelected = () => {
		if (!vm.allSelected) vm.deselectAll();
		else vm.selectAll();
	}

	vm.deselectAll = () => {
		for (let i = 0; i < vm.regData.data.length; i++) {
			vm.regData.selected[i] = false
		}
		vm.allSelected = false;
	}

	vm.loadUserData();
	vm.refreshDevices();
};

app.controller("BatchRegistrationController", ["BatchRegDataService", "UserService", "MessageService", "ToastService", "Upload", "DeviceService", "$filter", "$timeout", "$log", BatchRegistrationController])
