// tslint:disable:max-line-length
import * as React from "react";
import {
    Button,
    Col,
    Modal,
    Row,
    Panel,
    FormGroup,
    ControlLabel,
    FormControl,
    PanelGroup,
    HelpBlock
} from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { toast } from "../../utils/Toaster";

import { GetGatewaysModels, InstallGateway } from "../../services/Gateways";
import { strings } from "./../../services/Localization";

import "react-toastify/dist/ReactToastify.css";
import "./EnterpriseAddGateway.css";
import "./MultiCarousel.css";
declare const constants;

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2,
    },
};
export class EnterpriseAddGateway extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            InstallCmdCode: "",
            LoadingModels: true,
            ShowInstallCommandCode: false,
            ShowModal: true,
            gtwModels: [],
            gtwName: "",
            listGtwModels: [],
            selectedGtwModel: null,
            selectedGtwModelId: -1,
            activeAccordionKey: "0",
            collapseIcon: "glyphicon-plus",
            modelDefaults: {
                antenna_gain: 0,
                cable_loss: 0,
                classb_tx_power: 0,
                rx1_tx_power: 0,
                rx2_tx_power: 0
            },
            modelDefaultsShow: false,
            disableButton: false,
            errors: {
                antenna_gain: {
                    hasError: false,
                    messageError: "",
                },
                cable_loss: {
                    hasError: false,
                    messageError: "",
                },
                classb_tx_power: {
                    hasError: false,
                    messageError: "",
                },
                rx1_tx_power: {
                    hasError: false,
                    messageError: "",
                },
                rx2_tx_power: {
                    hasError: false,
                    messageError: "",
                },
            },
        };
    }

    public componentDidMount() {
        this.GetGatewaysModelsFunc();
    }

    public GetGatewaysModelsFunc = async () => {
        const response: any = await GetGatewaysModels();

        if (response.status === 200) {
            const data = await response.json();
            let gtwModels: any = [];
            if (data && data.hasOwnProperty("gateway_models") && (data.gateway_models.constructor === Array)) {
                gtwModels = data.gateway_models;
            }

            if (gtwModels.length > 0) {
                const gtwModelsArray = await gtwModels.map(async (value, index) => {
                    const modelObj = value;
                    modelObj.id = index;
                    modelObj.image = "/uiapi/rest/gateways/models/images/" + value.image;

                    return modelObj;
                });
                Promise.all(gtwModelsArray)
                .then(async (models) => {
                    this.setState({
                        gtwModels: (models) ? models : [],
                    });
                })
                .catch((error) => {
                    console.log("Get gateway models error => ", error);
                })
                .then(() => {
                    this.setState({
                        LoadingModels: false,
                    });
                });
            } else {
                this.setState({
                    LoadingModels: false,
                    gtwModels,
                });
            }
        } else {
            this.setState({
                LoadingModels: false,
                gtwModels: [],
            });
        }
    }

    public InstallGatewayFunc = async () => {
        try {
            let data: any = {
                model: this.state.selectedGtwModel,
                name: this.state.gtwName,
            };

            const {modelDefaults} = this.state;
            if((modelDefaults.constructor === Object) && (Object.keys(modelDefaults).length > 0)){
                Object.keys(modelDefaults).map((key) => {
                    data[key] = typeof modelDefaults[key] === "string" ? parseFloat(modelDefaults[key]) : modelDefaults[key];
                });
            }

            const response: any = await InstallGateway(data);

            if (response.status === 200) {
                data = await response.json();

                if (data.hasOwnProperty("install_cmd")) {
                    this.setState({
                        InstallCmdCode: data.install_cmd,
                        ShowInstallCommandCode: true,
                    });
                }
            } else {
                let message = "";
                switch (response.status) {
                    case 400:
                        message = strings.ADD_GATEWAY_CONFIG_ERROR_400;
                        break;
                    default:
                        message = await response.json();
                        break;
                }
                toast.error(message);
            }
        } catch (error) {
            toast.error(strings.ADD_GATEWAY_ERROR);
        }
    }

    public CopyToClipboard = async () => {
        const textarea: any = document.getElementById("clipboard-text-holder");
        if (textarea) {
            textarea.disabled = false;
            textarea.select();
            const copy = await document.execCommand("copy");
            textarea.disabled = true;
            if (copy) {
                toast.success(strings.ADD_GATEWAY_SUCCESS);
            } else {
                toast.error(strings.ADD_GATEWAY_BROWSER_NOT_SUPPORTED_COPY_TO_CLIPBOARD);
            }
        }
    }

    public selectGtwModel = (gtwId) => {
        let gtwModel = "";
        let modelDefaults = {
            antenna_gain: 0,
            cable_loss: 0,
            classb_tx_power: 0,
            rx1_tx_power: 0,
            rx2_tx_power: 0
        };
        for (const key in this.state.gtwModels) {
            if (this.state.gtwModels[key].id === gtwId) {
                gtwModel = this.state.gtwModels[key].model;
                if (this.state.gtwModels[key].hasOwnProperty("defaults")) {
                    modelDefaults = this.state.gtwModels[key].defaults;
                }
                break;
            }
        }

        this.setState({
            InstallCmdCode: "",
            selectedGtwModel: gtwModel,
            selectedGtwModelId: gtwId,
            modelDefaultsShow: true,
            modelDefaults,
        }, () => {
            this.InstallGatewayFunc();
        });
    }

    public isGtwModelSelected = (gtwModel) => {
        let gtwModelClass = "";
        if (gtwModel.id === this.state.selectedGtwModelId) {
            gtwModelClass = "selected";
        }

        return gtwModelClass;
    }

    public isGtwModelChecked = (gtwModel) => {
        let isChecked = false;
        if (gtwModel.id === this.state.selectedGtwModelId) {
            isChecked = true;
        }

        return isChecked;
    }

    public closeModal = () => {
        this.setState({ ShowModal: false });
    }

    public gtwNameChange = (event) => {
        this.setState({
            InstallCmdCode: "",
            ShowInstallCommandCode: false,
            gtwName: event.target.value,
        });
    }

    public handleAccordionSelect = (activeAccordionKey) => {
        let newCollapseIcon = "glyphicon-plus";
        if (this.state.collapseIcon === "glyphicon-plus") {
            newCollapseIcon = "glyphicon-remove";
        }

        this.setState({
            activeAccordionKey,
            collapseIcon: newCollapseIcon,
        });
    }

    handleChange(e, name) {
        const {errors} = this.state;
        const valueInt = parseInt(e.target.value);
        const value = e.target.value;
        let disableButton = false;
        if(value.match(/\d/)){
            errors[name].hasError = false;
            errors[name].messageError = "";
            switch (name) {
                case "antenna_gain":
                    if((valueInt < -5) || (valueInt > 10)) {
                        errors[name].hasError = true;
                        errors[name].messageError = strings.formatString(strings.ADD_GATEWAY_NUMBER_RANGE_ERROR, {
                            min: "-5",
                            max: "10"
                        });
                    }
                    break;
                case "cable_loss":
                    if((valueInt < 0) || (valueInt > 10)){
                        errors[name].hasError = true;
                        errors[name].messageError = strings.formatString(strings.ADD_GATEWAY_NUMBER_RANGE_ERROR, {
                            min: "0",
                            max: "10"
                        });
                    }
                    break;
                default:    // all tx powers
                    if((valueInt < 0) || (valueInt > 30)){
                        errors[name].hasError = true;
                        errors[name].messageError = strings.formatString(strings.ADD_GATEWAY_NUMBER_RANGE_ERROR, {
                            min: "0",
                            max: "30"
                        });
                    }
                    break;
            }
        } else {
            errors[name].hasError = true;
            errors[name].messageError = strings.ADD_GATEWAY_NUMBER_ONLY_ERROR;
        }

        Object.keys(errors).map((key) => {
            if(errors[key].hasError){
                disableButton = true;
            }
        });

        this.setState({
            modelDefaults: {...this.state.modelDefaults, [name]: value},
            errors,
            disableButton,
        }, () => {
            if (!disableButton) {
                this.InstallGatewayFunc();
            }
        });
    }

    public render() {
        const {
            gtwModels,
            InstallCmdCode,
            LoadingModels,
            ShowInstallCommandCode,
            selectedGtwModel,
            modelDefaults,
            errors,
            disableButton,
        } = this.state;

        return (
            <div>
                <Modal
                    show={this.state.ShowModal}
                    onHide={this.closeModal}
                    bsSize="sm"
                    className="ReactModal default-push-config enterprise-add-gateway"
                    backdrop="static"
                >
                    <Modal.Header closeButton={true}>
                        <Modal.Title>
                            {strings.ADD_GATEWAY}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="padding_0_all">
                        <Row>
                            <Col sm={12} className="form-horizontal">
                                <div className="desc">
                                    <p className="info">
                                        {strings.E_GTW_INFO}
                                    </p>

                                    <p>
                                        {strings.E_GTW_INFO_MSG_1}
                                    </p>
                                    <p>
                                        {strings.E_GTW_INFO_MSG_2}
                                    </p>
                                    <p>
                                        {strings.E_GTW_INFO_MSG_3}
                                    </p>
                                    <p>
                                        {strings.E_GTW_INFO_MSG_4}
                                    </p>
                                    <p>
                                        {strings.E_GTW_INFO_MSG_5}
                                    </p>
                                    <p>
                                        {strings.E_GTW_INFO_MSG_6}
                                    </p>
                                    {constants.enterpriseModeGtwInfoLink && (<p>
                                        {strings.E_GTW_INFO_MSG_7}
                                        <a href={constants.enterpriseModeGtwInfoLink} target="_blank"> {strings.E_GTW_INFO_MSG_8}</a>
                                    </p>)}
                                </div>
                            </Col>
                        </Row>

                        <div className="custom-background">
                            <Row className="name-input-container">
                                <Col sm={12} className="form-horizontal">
                                    <p className="gtw-name-label">
                                        {strings.GATEWAY_NAME}
                                    </p>
                                </Col>
                            </Row>

                            <Row className="name-input-container">
                                <Col sm={12} className="form-horizontal">
                                    <input type="text"
                                        className="form-control new_style_focus"
                                        value={this.state.gtwName}
                                        id="testId2"
                                        onChange={this.gtwNameChange} />
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12}>
                                    <p className="gtw-models">
                                        {strings.E_GTW_MODELS}
                                    </p>
                                </Col>
                            </Row>

                            <Row className="models-container">
                                <Col sm={12} className="carousel-container">
                                    {(LoadingModels) ? (
                                        <div className="loading_models">
                                            <i className="fa fa-spinner fa-spin fa-4x"></i>
                                        </div>
                                    ) : (
                                        (gtwModels.length > 0) ? (
                                            <Carousel
                                                swipeable={true}
                                                draggable={false}
                                                showDots={false}
                                                responsive={responsive}
                                                ssr={false}
                                                infinite={true}
                                                keyBoardControl={false}
                                            >
                                                {
                                                    gtwModels.map((gtwModel, i) => {
                                                        const modelName = gtwModel.full_model_names.map((fullModelName, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    {fullModelName}
                                                                </li>
                                                            );
                                                        });
                                                        return (
                                                            <div className="model-parent-container" key={i} onClick={() => this.selectGtwModel(gtwModel.id)}>
                                                                <div className={`${this.isGtwModelSelected(gtwModel)} model-container`} key={gtwModel.id}>
                                                                    <div className="row name-container">
                                                                        <div className="col-md-12">
                                                                            <h4 className="gtw-name">
                                                                                { gtwModel.manufacturer }
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row image-container">
                                                                        <div className="col-md-12">
                                                                            {(gtwModel.image) && <img src={gtwModel.image}/>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="line">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12 names-container">
                                                                            <ul className="full-names">
                                                                                { modelName }
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row check-box-container">
                                                                    <div className="col-md-12">
                                                                        <input
                                                                            checked={this.isGtwModelChecked(gtwModel)}
                                                                            readOnly={true}
                                                                            type="checkbox"
                                                                            name="gtw-model"
                                                                            value={gtwModel.model}
                                                                            onClick={() => this.selectGtwModel(gtwModel.id)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </Carousel>
                                        ) : (
                                            <div className="no_gtw_models">{strings.E_GTW_NO_MODELS}</div>
                                        )
                                    )}
                                </Col>
                            </Row>
                            {this.state.modelDefaultsShow && (
                                <Row>
                                    <Col sm={12}>
                                        <PanelGroup
                                            accordion
                                            id="wrapper-accordion-settings"
                                            activeKey={this.state.activeAccordionKey}
                                            onSelect={this.handleAccordionSelect}
                                        >
                                            <Panel eventKey="1" id="collapsible-panel">
                                                <Panel.Heading>
                                                    <Panel.Title toggle>
                                                        <div className="row wrapper-accordion">
                                                            <div className="title-modify-settings">
                                                                <strong className="auth-methods">
                                                                    {strings.E_GTW_MODIFY_SETTINGS}
                                                                </strong>
                                                            </div>

                                                            <div className="collapse-container-sign">
                                                                <button type="button" className="btn btn-default btn-sm">
                                                                    <span className={`glyphicon ${this.state.collapseIcon}`}></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Panel.Title>
                                                </Panel.Heading>

                                                <Panel.Body collapsible>
                                                    <form className="form-wrapper">
                                                        <Row>
                                                            <Col sm={6}>
                                                                <FormGroup controlId="antenna-gain" validationState={errors.antenna_gain.hasError ? "error" : null}>
                                                                    <ControlLabel>{strings.ADD_GATEWAY_ANTENNA_GAIN}</ControlLabel>
                                                                    <FormControl
                                                                        type="number"
                                                                        min="-5"
                                                                        max="10"
                                                                        step="0.1"
                                                                        value={modelDefaults.antenna_gain}
                                                                        onChange={(e) => this.handleChange(e,"antenna_gain")}
                                                                    />
                                                                    <HelpBlock>{errors.antenna_gain.messageError}</HelpBlock>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm={6}>
                                                                <FormGroup controlId="cable-loss" validationState={errors.cable_loss.hasError ? "error" : null}>
                                                                    <ControlLabel>{strings.ADD_GATEWAY_CABLE_LOSS}</ControlLabel>
                                                                    <FormControl
                                                                        type="number"
                                                                        min="0"
                                                                        max="10"
                                                                        step="0.1"
                                                                        value={modelDefaults.cable_loss}
                                                                        onChange={(e) => this.handleChange(e,"cable_loss")}
                                                                    />
                                                                    <HelpBlock>{errors.cable_loss.messageError}</HelpBlock>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col sm={4}>
                                                                <FormGroup controlId="rx1-tx-power">
                                                                    <ControlLabel>
                                                                        {strings.ADD_GATEWAY_RX1_TX_POWER} <br/> {strings.ADD_GATEWAY_DBM}
                                                                    </ControlLabel>
                                                                    <FormControl
                                                                        componentClass="select"
                                                                        placeholder="select"
                                                                        value={modelDefaults.rx1_tx_power} 
                                                                        onChange={(e) => this.handleChange(e,"rx1_tx_power")}>
                                                                        <option value="30">30</option>
                                                                        <option value="29">29</option>
                                                                        <option value="28">28</option>
                                                                        <option value="27">27</option>
                                                                        <option value="26">26</option>
                                                                        <option value="25">25</option>
                                                                        <option value="24">24</option>
                                                                        <option value="23">23</option>
                                                                        <option value="22">22</option>
                                                                        <option value="21">21</option>
                                                                        <option value="20">20</option>
                                                                        <option value="19">19</option>
                                                                        <option value="18">18</option>
                                                                        <option value="17">17</option>
                                                                        <option value="16">16</option>
                                                                        <option value="15">15</option>
                                                                        <option value="14">14</option>
                                                                        <option value="13">13</option>
                                                                        <option value="12">12</option>
                                                                        <option value="11">11</option>
                                                                        <option value="10">10</option>
                                                                        <option value="9">9</option>
                                                                        <option value="8">8</option>
                                                                        <option value="7">7</option>
                                                                        <option value="6">6</option>
                                                                        <option value="5">5</option>
                                                                        <option value="4">4</option>
                                                                        <option value="3">3</option>
                                                                        <option value="2">2</option>
                                                                        <option value="1">1</option>
                                                                        <option value="0">0</option>
                                                                    </FormControl>
                                                                </FormGroup>
                                                            </Col>

                                                            <Col sm={4}>
                                                            <FormGroup controlId="rx2-tx-power">
                                                                    <ControlLabel>
                                                                        {strings.ADD_GATEWAY_RX2_TX_POWER} <br/> {strings.ADD_GATEWAY_DBM}
                                                                    </ControlLabel>
                                                                    <FormControl
                                                                        componentClass="select"
                                                                        placeholder="select"
                                                                        value={modelDefaults.rx2_tx_power} 
                                                                        onChange={(e) => this.handleChange(e,"rx2_tx_power")}>
                                                                        <option value="30">30</option>
                                                                        <option value="29">29</option>
                                                                        <option value="28">28</option>
                                                                        <option value="27">27</option>
                                                                        <option value="26">26</option>
                                                                        <option value="25">25</option>
                                                                        <option value="24">24</option>
                                                                        <option value="23">23</option>
                                                                        <option value="22">22</option>
                                                                        <option value="21">21</option>
                                                                        <option value="20">20</option>
                                                                        <option value="19">19</option>
                                                                        <option value="18">18</option>
                                                                        <option value="17">17</option>
                                                                        <option value="16">16</option>
                                                                        <option value="15">15</option>
                                                                        <option value="14">14</option>
                                                                        <option value="13">13</option>
                                                                        <option value="12">12</option>
                                                                        <option value="11">11</option>
                                                                        <option value="10">10</option>
                                                                        <option value="9">9</option>
                                                                        <option value="8">8</option>
                                                                        <option value="7">7</option>
                                                                        <option value="6">6</option>
                                                                        <option value="5">5</option>
                                                                        <option value="4">4</option>
                                                                        <option value="3">3</option>
                                                                        <option value="2">2</option>
                                                                        <option value="1">1</option>
                                                                        <option value="0">0</option>
                                                                    </FormControl>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm={4}>
                                                            <FormGroup controlId="class-b-tx-power">
                                                                    <ControlLabel>
                                                                        {strings.ADD_GATEWAY_CLASSB_TX_POWER} <br/> {strings.ADD_GATEWAY_DBM}
                                                                    </ControlLabel>
                                                                    <FormControl
                                                                        componentClass="select"
                                                                        placeholder="select"
                                                                        value={modelDefaults.classb_tx_power} 
                                                                        onChange={(e) => this.handleChange(e,"classb_tx_power")}>
                                                                        <option value="30">30</option>
                                                                        <option value="29">29</option>
                                                                        <option value="28">28</option>
                                                                        <option value="27">27</option>
                                                                        <option value="26">26</option>
                                                                        <option value="25">25</option>
                                                                        <option value="24">24</option>
                                                                        <option value="23">23</option>
                                                                        <option value="22">22</option>
                                                                        <option value="21">21</option>
                                                                        <option value="20">20</option>
                                                                        <option value="19">19</option>
                                                                        <option value="18">18</option>
                                                                        <option value="17">17</option>
                                                                        <option value="16">16</option>
                                                                        <option value="15">15</option>
                                                                        <option value="14">14</option>
                                                                        <option value="13">13</option>
                                                                        <option value="12">12</option>
                                                                        <option value="11">11</option>
                                                                        <option value="10">10</option>
                                                                        <option value="9">9</option>
                                                                        <option value="8">8</option>
                                                                        <option value="7">7</option>
                                                                        <option value="6">6</option>
                                                                        <option value="5">5</option>
                                                                        <option value="4">4</option>
                                                                        <option value="3">3</option>
                                                                        <option value="2">2</option>
                                                                        <option value="1">1</option>
                                                                        <option value="0">0</option>
                                                                    </FormControl>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </form>
                                                </Panel.Body>
                                            </Panel>
                                        </PanelGroup>
                                    </Col>
                                </Row>
                            )}

                            {(ShowInstallCommandCode) && (
                                <React.Fragment>
                                    <Row>
                                        <Col sm={12}>
                                            <p className="install">
                                                {strings.E_GTW_INSTALLATION}
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row className="clipboard-btn-container">
                                        <Col sm={12}>
                                            <FormControl
                                                id="clipboard-text-holder"
                                                componentClass="textarea"
                                                value={InstallCmdCode}
                                                onChange={(e) => e}
                                                rows={12}
                                                disabled={true}
                                            />
                                            <Button
                                                onClick={this.CopyToClipboard}
                                                className="clipboard-btn"
                                                disabled={(selectedGtwModel && !disableButton) ? false : true}
                                            >
                                                <img src={`/theme/images/fa-copy.png`} />
                                                {strings.E_GTW_COPY_TO_CLIPBOARD}
                                            </Button>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            )}
                        </div>
                    </Modal.Body>

                    <div className="modal-footer">
                        <div className="modal_footer_btns">
                            <Button
                                onClick={this.closeModal}
                                className="React_new_style_btn_ok"
                            >
                                {strings.CLOSE}
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
