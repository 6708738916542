// tslint:disable:max-line-length
import * as React from "react";
import { Button, Col, Modal, Nav, NavItem, Row, Tab, Table, Tabs } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { addDevice, editDevice } from "../../services/Device";

import { EditXProfile, SearchXProfileDescription, SearchXProfileName,
         SearchXProfileUUID, XProfilesList } from "../../services/Profiles/Profiles";

import { getDataElementById, getPagination } from "../../services/BasicDassQueries";

import { toast } from "../../utils/Toaster";
// import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { DataTable } from "../DataTable/DataTable";
import { SelectDropdown } from "../DropdownComponent/SelectDropdown";
import { strings } from "./../../services/Localization";
declare const constants;
import { RemoveDuplicateValues } from "../../controllers/Profiles/RemoveDupliceteValues";
import { Whoami } from "./../../services/Login";

interface IProfile {
    profile_name: string;
    profile_uuid: string;
    description?: string;
}

interface IDeviceProfile extends IProfile {
    content: {
        MACVersion: string;
        activation: "ABP" | "OTAA";
        SupportsClassB: boolean;
        SupportsClassC: boolean;
    }
}


interface IDeviceModalProps {
    EditDevice?: any;
    editMode?: boolean;
    Device: any;
}

export class DeviceModal extends React.Component<IDeviceModalProps, any> {

    private newTagNameRef: React.RefObject<any>;
    private newTagDescRef: React.RefObject<any>;
    private inputRefs;
    constructor(props) {
        super(props);
        this.state = {
            DeviceProfileUUID: "",
            DisableDeviceProfile: false,
            InvalidDeviceProfileUUID: false,
            InvalidServiceProfileUUID: false,
            Loading: true,
            LoggedUser: null,
            MACVersion: "",                 // 1.0 or 1.1. Only the first 3 characters of the actual MACVersion
            RootUser: undefined,
            ShowModal: constants.enable_profiles,
            SupportsClassB: true,
            SupportsClassC: true,
            activation: "",
            additional: false,
            tagsTab: false,
            device: {
                activated: true,
                appeui: "",
                joineui: "",
                appkey: "",
                appskey: "",
                comment: "",
                devaddr: "",
                deveui: "",
                device_profile_uuid: "",
                fnwksintkey: "",
                lora_device_class: 0,
                nwkkey: "",
                nwksenckey: "",
                nwkskey: "",
                options: "",
                service_profile_uuid: "",
                snwksintkey: "",
            },
            DeviceOldValues: {},
            deviceProfiles: [{
                description: "",
                profile_name: "",
                profile_uuid: "",
            }],
            error_appeui: false,
            error_joineui: false,
            error_appkey: false,
            error_appskey: false,
            error_devaddr: false,
            error_deveui: this.props.EditDevice ? false : true,
            error_fnwksintkey: false,
            error_nwkkey: false,
            error_nwksenckey: false,
            error_nwkskey: false,
            error_snwksintkey: false,
            errors: false,
            keys: false,
            open: false,
            options_error: false,
            profiles: true,
            readOnly: this.props.EditDevice ? true : false,
            serviceProfiles: [{
                description: "",
                profile_name: "",
                profile_uuid: "",
            }],
            showUsers: false,
            users: [{
                administrator: "",
                can_register: "",
                userid: "",
            }],
            users_exist: false,
            tagsData: [],
            newTagName: "",
            newTagDesc: "",
            isTagNameValid: true,
            globalTagNameValid: true,
            oldTagName: "",
            oldTagDesc: "",
            initialTagsLength: 0,
            sameTagName: false,
            hasSameTagName: false,
        };

        this.newTagNameRef = React.createRef();
        this.newTagDescRef = React.createRef();

        this.inputRefs = [];
    }

    public setRef = (ref) => {
        this.inputRefs.push(ref);
    }

    public async componentDidMount() {
        // document.addEventListener("mousedown", this.handleClickOutside);

        try {

            const devTags = this.props.EditDevice && this.props.EditDevice.device.tags;
            let counter = 0;
            const tagsArray = [] as any;
            for (const key in devTags) {
                const tagObj = {
                    id: counter++,
                    name: key,
                    desc: devTags[key],
                    editable: "hide-input-container",
                    validName: true,
                    isSameName: false,
                };

                tagsArray.push(tagObj);
            }

            this.setState({
                initialTagsLength: tagsArray.length,
                tagsData: tagsArray,
            });

        } catch (error) {
            console.log(error);
        }
    }

    public componentWillUnmount() {
        // document.removeEventListener("mousedown", this.handleClickOutside);
    }

    public handleClickOutside = (e: any) => {
        try {
            const parts = e.target.className.split(" ");
            let matches = 0;
            let onotherCheck = 0;
            for (let i = 0; i < parts.length; i++) {
                if (parts[i] === "edit-icon" || parts[i] === "edit-tag") {
                    matches++;
                }

                if (parts[i] === "tag-input-container") {
                    onotherCheck++;
                }
            }

            if (matches < 2 && onotherCheck <= 0) {  // we have cliked outside the edit icon or the input fields itself and therefore hide the input text for the tags
                const tags = this.state.tagsData;
                for (let i = 0; i < tags.length; i++) {
                    if (tags[i].validName) {
                        tags[i].editable = "hide-input-container";
                    }
                }

                this.setState({
                    tagsData: tags,
                });
            }

            return;
        } catch (error) {

        }
    }

    public closeModal = () => {
        this.setState({ ShowModal: false });
    }

    public handleSubmit = (event) => {
        if (this.props.editMode) {
            this.editDevice(event);
        } else {
            this.addDevice(event);
        }
    }

    public limitOperatingClass(device: any, deviceProfile: IDeviceProfile) {
        if (deviceProfile && deviceProfile.content) {

            const SupportsClassB = (deviceProfile.content.SupportsClassB !== undefined) ? deviceProfile.content.SupportsClassB : false;
            const SupportsClassC = (deviceProfile.content.SupportsClassC !== undefined) ? deviceProfile.content.SupportsClassC : false;
            this.setState({
                SupportsClassB,
                SupportsClassC,
            });

            let LoraDeviceClass = device.lora_device_class;
            if ((this.state.device.lora_device_class === 1) && (!SupportsClassB)) {
                LoraDeviceClass = 0;
            } else if ((this.state.device.lora_device_class === 2) && (!SupportsClassC)) {
                LoraDeviceClass = 0;
            }
            device.lora_device_class = LoraDeviceClass;
        }
    }

    public getDeviceProfileName = async (ProfileUuid) => {

        let deviceProfile: IDeviceProfile;
        try {
            deviceProfile = await EditXProfile("device-profiles", ProfileUuid) as any;
        } catch (error) {
            deviceProfile = {
                profile_name: ProfileUuid,
                profile_uuid: ProfileUuid,
            } as any;
        }
        return deviceProfile;
    }


    public addDevice = async (event) => {

        try {
            const deviceToSend = {};
            Object.keys(this.state.device).map((element) => {
                if (this.state.device[element] != null && this.state.device[element] !== "") {
                    if (element === "options") {
                        deviceToSend[element] = parseInt(this.state.device[element], 16);
                    } else {
                        deviceToSend[element] = this.state.device[element];
                    }
                }
            });

            // tslint:disable-next-line:no-string-literal
            deviceToSend["tags"] = this.transformTags();

            const response: any = await addDevice(deviceToSend);
            if (response.status === 200) {
                this.setState({ ShowModal: false });
                this.props.Device.loadDevices();
                toast.success(strings.formatString(strings.MSG_DEVICE_ADDED_SUCCESS_BODY,
                    { deveui: this.state.device.deveui || "" }));
            } else {
                const ResponseMessage = await response.text();
                let message: any = "";

                const responseStatusStr = response.status + "";
                message = ResponseMessage;

                if (responseStatusStr.match(/^5[0-9]{2}$/g)) {
                    message = strings.MSG_DEVICE_ADDED_FAIL_BODY;
                }
                toast.error(strings.formatString(message,
                    { deveui: this.state.device.deveui || "" }));
            }
        } catch (error) {
            this.setState({ ShowModal: false });
            toast.error(strings.MSG_DEVICE_ADDED_FAIL_BODY);
        }
    }

    public transformTags() {

        const tags = this.state.tagsData;
        const tagsArray = {};
        for (let i = 0; i < tags.length; i++) {
            tagsArray[tags[i].name] = tags[i].desc;
        }

        return tagsArray;
    }

    public editDevice = async (event) => {

        try {
            const deviceToSend: any = {};
            Object.keys(this.state.device).map((element) => {
                if (element === "deveui") {
                    deviceToSend[element] = this.state.device[element];
                }
                if ((element === "options") && (this.state.device[element] === "")) {
                    if (this.state.device[element] !== this.state.DeviceOldValues[element]) {
                        deviceToSend[element] = parseInt("0", 16);
                    }
                } else if (element === "comment") {
                    if (this.state.device[element] !== this.state.DeviceOldValues[element]) {
                        deviceToSend[element] = this.state.device[element];
                    }
                } else if (element === "appkey" || element === "joineui") {
                    if (this.state.activation === "OTAA" && this.state.device[element] !== this.state.DeviceOldValues[element]) {
                        deviceToSend[element] = this.state.device[element];
                    }
                } else if (element === "appskey") {
                    if (this.state.activation === "ABP" && this.state.device[element] !== this.state.DeviceOldValues[element]) {
                        deviceToSend[element] = this.state.device[element];
                    }
                } else if (element === "nwkkey") {
                    if (this.state.activation === "OTAA" && this.state.MACVersion === "1.1" &&
                        this.state.device[element] !== this.state.DeviceOldValues[element]) {
                            deviceToSend[element] = this.state.device[element];
                    }
                } else {
                    if (this.state.device[element] !== null && this.state.device[element] !== "") {
                        if (element === "options") {
                            if (this.state.device[element] !== this.state.DeviceOldValues[element]) {
                                deviceToSend[element] = parseInt(this.state.device[element], 16);
                            }
                        } else {
                            deviceToSend[element] = this.state.device[element];
                        }
                    }
                }
            });

            if ((event.AssignToParent !== undefined) && (event.AssignToParent === true)) {
                deviceToSend.userid = event.userid;
            }

            if (this.state.tagsData.length > 0 || this.state.initialTagsLength > 0) {
                deviceToSend.tags = this.transformTags();
            }

            const response: any = await editDevice(deviceToSend);

            // status === 200 means all devices were updated
            // status === 202 means some devices failed
            // status === 4xx meams all devices failed
            if (response.status === 200 || response.status === 202) {
                const SuccessMessage = await response.text();

                this.setState({ ShowModal: false });
                this.props.Device.loadDevices();
                if (this.props.EditDevice && this.props.EditDevice.MigrateDevice) {
                    toast.success(SuccessMessage);
                } else {
                    if (this.state.device.deveui.split(",").length > 1) {
                        let message: any = "";
                        switch (response.status) {
                            case 202:
                                const NumOfSucFail = SuccessMessage.match(/^OK, updated (\d) devices, (\d) devices failed$/);
                                message = strings.formatString(strings.MSG_DEVICE_UPDATE_BULK_SUCCESS_BODY_WITH_FAILS_BULK, {
                                    fail: NumOfSucFail[2],
                                    success: NumOfSucFail[1],
                                });
                                break;
                            default:
                                const NumOfSucUPD = SuccessMessage.match(/^OK, updated (\d) devices$/);
                                message = strings.formatString(strings.MSG_DEVICE_UPDATE_BULK_SUCCESS_BODY_BULK, {
                                    success: NumOfSucUPD[1],
                                });
                                break;
                        }
                        toast.success(message);
                    } else {
                        toast.success(strings.formatString(strings.MSG_DEVICE_UPDATE_SUCCESS_BODY, {
                            deveui: this.state.device.deveui || "",
                        }));
                    }
                }
            } else {
                const ResponseMessage = await response.text();
                let message: any = "";
                switch (response.status) {
                    case 400:
                        const InvalidDeveUI = ResponseMessage.match(/^invalid deveui (.*)$/);
                        const PayloadDecError = ResponseMessage.match(/^error in payload decoder: (.*)$/);
                        const SetProfileErr = ResponseMessage.match(/^setting (\w*)_profile_uuid is not allowed$/);
                        const MandatoryForABPMode = ResponseMessage.match(/^(.*) and devaddr mandatory when switching to ABP activation mode$/);
                        if (InvalidDeveUI) {
                            message = strings.formatString(strings.ERROR_MSG__DEVICE_INVALID_DEVEUI, {
                                deveui: InvalidDeveUI[1],
                            });
                        } else if (ResponseMessage.match(/^field deveui not allowed in update$/)) {
                            message = strings.ERROR_MSG_DEVEUI_CANT_BE_UPDATED;
                        } else if (ResponseMessage.match(/^lora_rx_delay2 must be lora_rx_delay1 \+ 1$/)) {
                            message = strings.ERROR_MSG_DEVICE_LORA_RX_DELAY;
                        } else if (ResponseMessage.match(/^invalid option value$/)) {
                            message = strings.ERROR_MSG_DEVICE_INVALID_OPTION_VALUE;
                        } else if (PayloadDecError) {
                            message = strings.formatString(strings.ERROR_MSG_DEVICE_PAYLOAD_DECODER, {
                                message: PayloadDecError[1],
                            });
                        } else if (SetProfileErr) {
                            message = strings.formatString(strings.ERROR_MSG_DEVICE_SET_PROFILE_UUID_NOT_ALLOWED, {
                                profile: SetProfileErr[1],
                            });
                        } else if (MandatoryForABPMode) {
                            message = strings.formatString(strings.ERROR_MSG_MANDATORY_WHEN_SWITCH_TO_ABP, {
                                fields: MandatoryForABPMode[1],
                            });
                        } else if (ResponseMessage.match(/^invalid MAC version \/ revision$/)) {
                            message = strings.ERROR_MSG_DEVICE_INVALID_MAC_VERSION;
                        } else if (ResponseMessage.match(/^please specify only one device when using the non_owner_access option$/)) {
                            message = strings.ERROR_MSG_SPECIFY_ONLY_ONE_DEVICE;
                        } else {
                            message = ResponseMessage;
                        }
                        break;
                    case 403:
                        const Match = ResponseMessage.match(/^(Device|Service)\W? profile doesn't \W?(support|allow)\w? class \W?(B|C)$/);
                        if (Match) {
                            message = strings.formatString(strings.PROFILE_DOES_NOT_SUPPORT_CLASS_B_C, {
                                class: ResponseMessage.substring(ResponseMessage.lastIndexOf(" ") + 1),
                                profile: strings[(Match[1] === "Device" ? "DEVICE_PROFILE" : "SERVICE_PROFILE")],
                                support: strings[(Match[2] === "support" ? "SUPPORT" : "ALLOW")],
                            });
                        } else if (ResponseMessage.match(/^account doesn't have right to modify suspended status$/)) {
                            message = strings.ERROR_MSG_CANT_MODIFY_SUSPENDED_STATUS;
                        } else if (ResponseMessage.match(/^must be user admin to set userid field$/)) {
                            message = strings.ERROR_MSG_MUST_HAVE_USERS_ADMIN_TO_SET_USERID;
                        } else if (ResponseMessage.match(/^must be customer admin to move to other customer$/)) {
                            message = strings.ERROR_MSG_DEVICE_MUST_BE_CUSTOMER_ADMIN;
                        } else {
                            message = ResponseMessage;
                        }
                        break;
                    case 404:
                        const UsrIdNotFound = ResponseMessage.match(/^userid (.*) not found$/);
                        const InvDeveui = ResponseMessage.match(/^unknown groupid or invalid deveui (.*)$/);
                        if (UsrIdNotFound) {
                            message = strings.formatString(strings.ERROR_MSG_DEVICE_USER_ID_NOT_FOUND, {
                                userid: UsrIdNotFound[1],
                            });
                        } else if (InvDeveui) {
                            message = strings.formatString(strings.ERROR_MSG_DEVICE_INVALID_DEVEUI, {
                                deveui: InvDeveui[1],
                            });
                        } else if (ResponseMessage.match(/^can't resolve devices profiles$/)) {
                            message = strings.ERROR_MSG_CANT_RESOLVE_DEVICE_PROFILES;
                        } else {
                            message = ResponseMessage;
                        }
                        break;
                    case 406:
                        const MatchRegType = ResponseMessage.match(/^(OTAA|ABP)\W? device cannot be migrated to \W?(OTAA|ABP)\W? profile$/);
                        const DevicesUpdateFail = ResponseMessage.match(/^update of (\d) devices failed$/);
                        const RetKey = ResponseMessage.match(/^cannot change device-profile. value of (.*) is not compabile.$/);
                        if (ResponseMessage.match(/^both device and service profile are required to migrate device$/)) {
                            message = strings.ERROR_MSG_BOTH_PROFILES_ARE_REQUIRED_TO_MIGRATE_DEVICE;
                        } else if (ResponseMessage.match(/^Changing device profile of device is not allowed$/)) {
                            message = strings.MSG_DEVICE_CHANGING_DEVICE_PROFILE_NOT_ALLOWED;
                        } else if (MatchRegType) {
                            message = strings.formatString(strings.MSG_DEVICE_OTAA_CANT_MIGRATE_TO_ABP_PROFILE, {
                                device: MatchRegType[1],
                                profile: MatchRegType[2],
                            });
                        } else if (ResponseMessage.match(/^device cannot be migrated to profile with different MAC version$/)) {
                            message = strings.MSG_DEVICE_CANT_MIGRATE_TO_DIFFERENT_MAC_VERSION_PROFILE;
                        } else if (ResponseMessage.match(/^device cannot be migrated to profile with different RegParamsRevision$/)) {
                            message = strings.MSG_DEVICE_CANT_MIGRATE_TO_DIFFERENT_REG_PARAM_REVISION;
                        } else if (ResponseMessage.match(/^device cannot be migrated to profile with different FCNT 16\/32-bit setting$/)) {
                            message = strings.ERROR_MSG_MIGRATE_DIFFERENT_FCNT;
                        } else if (ResponseMessage.match(/^device cannot be migrated to profile with different rx1_delay$/)) {
                            message = strings.ERROR_MSG_MIGRATE_DIFFERENT_RX1_DELAY;
                        } else if (ResponseMessage.match(/^device cannot be migrated to profile with different default rx2 datarate$/)) {
                            message = strings.MSG_DEVICE_CANT_MIGRATE_TO_DIFFERENT_RX2_DATA_RATE_PROFILE;
                        } else if (ResponseMessage.match(/^assigning profiles to direct-registered device is not allowed$/)) {
                            message = strings.ERROR_MIGRATE_ASSIGN_PROFILE_TO_DIRECT_REG_DEVICE;
                        } else if (DevicesUpdateFail) {
                            message = strings.formatString(strings.ERROR_NOT_UPDATED_DEVICES, {
                                num: DevicesUpdateFail[1],
                            });
                        } else if (ResponseMessage.match(/^cannot resolve current device-profile$/)) {
                            message = strings.ERROR_MSG_CANT_RESOLVE_CURRENT_DEVICE_PROFILES;
                        } else if (RetKey) {
                            message = strings.formatString(strings.ERROR_MSG_DEVICE_CANT_CHANGE_DEVICE_PROFILE, {
                                key: RetKey[1],
                            });
                        } else {
                            message = ResponseMessage;
                        }
                        break;
                    case 409:
                        if (ResponseMessage.match(/^Error, setting devaddr and nwkskey on multiple devices not allowed$/)) {
                            message = strings.ERROR_MSG_CANT_SET_DEVADDR_AND_NWKSKEY_MULTIPLE_DEVICES;
                        } else if (ResponseMessage.match(/^Error, devaddr and nwkskey pair already registered with other deveui$/)) {
                            message = strings.ERROR_MSG_DEVADDR_NWKSKEY_ALREADY_REGISTERED;
                        } else {
                            message = ResponseMessage;
                        }
                        break;
                    case 410:
                        if (ResponseMessage.match(/^this device registration seems corrupted$/)) {
                            message = strings.ERROR_MSG_CORRUPTED_DEVICE;
                        } else {
                            message = ResponseMessage;
                        }
                        break;
                    case 423:
                        if (ResponseMessage.match(/^device is busy, please try again later$/)) {
                            message = strings.ERROR_MSG_DEVICE_IS_BUSY;
                        } else {
                            message = ResponseMessage;
                        }
                        break;
                    default:
                        message = ResponseMessage;
                        break;
                }
                toast.error(strings.formatString(message,
                    { device: this.state.device.deveui.split(",").length > 1 ?
                        strings.MSG_DEVICE_DEVICES : `${strings.MSG_DEVICE_DEVICE} ${this.state.device.deveui}` || "",
                    },
                ));
            }
        } catch (error) {
            console.log(error);
            toast.error(strings.formatString(strings.MSG_DEVICE_UPDATE_BULK_FAIL_BODY,
                { device: this.state.device.deveui.split(",").length > 1 ?
                    strings.MSG_DEVICE_DEVICES : strings.MSG_DEVICE_DEVICE || "",
                },
            ));
        }
    }

    public CheckErrors = async () => {

        // do setState with promise to be sure state is updated before doing the validation
        await new Promise<void>((resolve, reject) => {
            this.setState({ errors: this.state.errors }, resolve);
        });

        let errors = (
            this.state.error_deveui || this.state.error_appeui || this.state.error_joineui || this.state.error_devaddr
            || this.state.error_nwkskey || this.state.error_nwkkey || this.state.error_snwksintkey
            || this.state.error_fnwksintkey || this.state.error_nwksenckey || this.state.error_appskey
            || this.state.error_appkey) ? true : false;

        if (!this.props.EditDevice || (this.props.EditDevice && !this.props.EditDevice.EditDeviceBulk)) {
            errors = errors || !this.state.device.device_profile_uuid || !this.state.device.service_profile_uuid;
        }

        this.setState({ errors });
    }

    public async componentWillMount() {
        try {
            const LoggedUser = await this.CheckLoggedUser();
            let deviceProfiles: IDeviceProfile[];
            let serviceProfiles: IProfile[];
            [deviceProfiles, serviceProfiles] = await Promise.all([this.getDeviceProfiles(LoggedUser), this.getServiceProfiles(LoggedUser)]);


            if (this.props.EditDevice) {

                const DeviceOldValues: any = {};

                if (this.props.EditDevice.MigrateDeviceBulk || this.props.EditDevice.EditDeviceBulk) {

                    // Bulk edit
                    //
                    const DisableDeviceProfile = this.state.DisableDeviceProfiles;
                    if (this.props.EditDevice.EditDeviceBulk) {
                        // DisableDeviceProfile = true;
                    }

                    const DeviceValues = this.state.device;
                    Object.keys(DeviceValues).map((property) => {
                        if (property === "deveui") {
                            DeviceValues[property] = this.props.EditDevice.device.toString();
                        } else {
                            DeviceOldValues[property] = "";
                            DeviceValues[property] = "";
                        }
                    });
                    this.setState({
                        DeviceOldValues,
                        DisableDeviceProfile,
                        device: DeviceValues,
                    });

                } else {

                    // Individual Device edit
                    //

                    const EditValues = {};
                    Object.keys(this.state.device).map((element) => {
                        if (this.props.EditDevice.device[element] != null) {
                            if (element === "options" || element === "devaddr") {
                                const val = ("00000000" + this.props.EditDevice.device[element].toString(16).toUpperCase()).slice(-8);
                                EditValues[element] = DeviceOldValues[element] = val;

                            } else if (element === "nwkskey" || element === "appskey" || element === "appkey" ||  element === "nwkkey" ||
                                       element === "snwksintkey" || element === "fnwksintkey" || element === "nwksenckey") {
                                EditValues[element] = DeviceOldValues[element] = this.props.EditDevice.device[element].toString().toUpperCase();

                            } else if (element === "appeui" || element === "joineui") {
                                EditValues[element] = DeviceOldValues[element] = this.props.EditDevice.device[element].toString().toUpperCase();

                            } else {
                                EditValues[element] = this.props.EditDevice.device[element];
                                DeviceOldValues[element] = this.props.EditDevice.device[element];
                            }
                        } else {
                            EditValues[element] = this.state.device[element];
                            DeviceOldValues[element] = this.state.device[element];
                        }
                    });

                    if (!this.props.EditDevice.hasOwnProperty("MigrateDevice")) {

                        const deviceProfile = await this.getDeviceProfileName(this.props.EditDevice.device.device_profile_uuid);
                        const serviceProfile = this.findProfileInList(this.props.EditDevice.device.service_profile_uuid, serviceProfiles);

                        this.limitOperatingClass(EditValues, deviceProfile);
                        this.setValidation(deviceProfile, EditValues);
                        this.setState({
                            deviceProfile,
                            activation: (deviceProfile.content && deviceProfile.content.activation) || "OTAA",
                            MACVersion: ((deviceProfile.content && deviceProfile.content.MACVersion) || "1.0").substring(0, 3),
                            serviceProfile,
                            DisableDeviceProfile: false,
                        });

                    } else {
                        this.setState({
                            DisableDeviceProfile: false,
                        });
                    }

                    this.setState({
                        DeviceOldValues,
                        device: EditValues,
                    });
                }
            } else {

                const EditValues = {...this.state.device};
                if (serviceProfiles.length >= 1) {
                    const serviceProfile = {
                        profile_name: serviceProfiles[0].profile_name,
                        profile_uuid: serviceProfiles[0].profile_uuid,
                    }
                    EditValues.service_profile_uuid = serviceProfile.profile_uuid;
                    this.setState({ serviceProfile, device: EditValues });
                } else {
                    this.setState({ InvalidServiceProfileUUID: true });
                }

                if (deviceProfiles.length === 1) {

                    const deviceProfile = await this.getDeviceProfileName(deviceProfiles[0].profile_uuid);
                    EditValues.device_profile_uuid = deviceProfile.profile_uuid;

                    this.limitOperatingClass(EditValues, deviceProfile);
                    this.setValidation(deviceProfile, EditValues);
                    this.setState({
                        deviceProfile,
                        device: EditValues,
                        activation: (deviceProfile.content && deviceProfile.content.activation) || "OTAA",
                        MACVersion: ((deviceProfile.content && deviceProfile.content.MACVersion) || "1.0").substring(0, 3),
                    });

                } else {
                    this.setState({ InvalidDeviceProfileUUID: true });
                }
            }

            this.setState({
                LoggedUser,
                deviceProfiles,
                serviceProfiles,
                Loading: false,
            });
            this.CheckErrors();

        } catch (error) {
            console.log(error);
        }
    }

    public findProfileInList(profileUuid: string, profileList: IProfile[]): IProfile {
        for (const profile of profileList) {
            if (profileUuid === profile.profile_uuid) {
                return profile;
            }
        }
        return {
            profile_uuid: profileUuid,
            profile_name: profileUuid,
        }
    }


    public getDeviceProfiles = async (User) => {
        if (true || (User !== null) && User.can_list_device_profile) {
            try {
                const response: IDeviceProfile[] = await XProfilesList("device-profiles") as any;
                return response;
            } catch (error) {
                console.log(error);
            }
        }
        return [];
    }

    public getServiceProfiles = async (User) => {
        if (true || (User !== null) && User.can_list_service_profile) {
            try {
                const response: IProfile[] = await XProfilesList("service-profiles") as any;
                return response;
            } catch (error) {
                console.log(error);
            }
        }
        return [];
    }

    public handleChange = (event) => {
        const target = event.target;
        Object.keys(this.state.device).map((element) => {
            if (element === target.name) {
                const device = { ...this.state.device };
                device[element] = target.value;
                this.setState({ device });
            }
        });
        this.validateInput(event);
    }

    public setValidation = (deviceProfile: IDeviceProfile, device: any) => {

        if (deviceProfile.content && deviceProfile.content.activation === "ABP") {
            const macversion = (deviceProfile.content && deviceProfile.content.MACVersion || "").substring(0, 3);
            if (macversion === "1.1") {
                if (!device.devaddr) {
                    this.setState({ error_devaddr: true });
                }
                if (!device.fnwksintkey) {
                    this.setState({ error_fnwksintkey: true });
                }
                if (!device.nwksenckey) {
                    this.setState({ error_nwksenckey: true });
                }
                if (!device.snwksintkey) {
                    this.setState({ error_snwksintkey: true });
                }
            } else {
                if (!device.devaddr) {
                    this.setState({ error_devaddr: true });
                }
                if (!device.nwkskey) {
                    this.setState({ error_nwkskey: true });
                }
            }
        } else {
            this.setState({
                error_devaddr: false,
                error_fnwksintkey: false,
                error_nwksenckey: false,
                error_nwkskey: false,
                error_snwksintkey: false,
            });
        }
    }

    public SelectedProfile = (ProfileData) => {

        const ValidateUUID = /^([0-9a-zA-Z]{8})-([0-9a-zA-Z]{4})-([0-9a-zA-Z]{4})-([0-9a-zA-Z]{4})-([0-9a-zA-Z]{12})$/;
        const SelectedProfile = ProfileData;
        const device = this.state.device;
        const InvalidProfileUUID = `Invalid${ProfileData.ProfileName}UUID`;
        if (!SelectedProfile.value.match("-")) {
            const FormatToUUID = /^(.{8})(.{4})(.{4})(.{4})(.{12})$/;
            const Value = SelectedProfile.value.replace(FormatToUUID, "$1-$2-$3-$4-$5");
            SelectedProfile.value = Value;
            SelectedProfile.label = Value;
        }

        if (SelectedProfile.value.match(ValidateUUID)) {
            this.SetProfile(SelectedProfile);
            this.setState({
                [InvalidProfileUUID]: false,
            });
        } else {
            SelectedProfile.profile_name = ProfileData.value;
            SelectedProfile.profile_uuid = ProfileData.value;
            if (ProfileData.ProfileName === "DeviceProfile") {
                device.device_profile_uuid = "";
                this.setState({
                    device,
                    deviceProfile: SelectedProfile,
                    [InvalidProfileUUID]: true,
                });
            } else {
                device.service_profile_uuid = "";
                this.setState({
                    device,
                    serviceProfile: SelectedProfile,
                    [InvalidProfileUUID]: true,
                });
            }
            this.CheckErrors();
        }
    }

    public SetProfile = async (ProfileData) => {
        const device = { ...this.state.device };

        if (ProfileData.ProfileName === "DeviceProfile") {
            device.device_profile_uuid = ProfileData.value;

            if (ProfileData.label && this.state.device.service_profile_uuid) {
                this.setState({ keys: true, profiles: false });
            }

            const deviceProfile = await this.getDeviceProfileName(ProfileData.value);
            this.limitOperatingClass(device, deviceProfile);
            this.setValidation(deviceProfile, this.state.device);
            this.setState({
                deviceProfile,
                activation: (deviceProfile.content && deviceProfile.content.activation) || "OTAA",
                MACVersion: ((deviceProfile.content && deviceProfile.content.MACVersion) || "1.0").substring(0, 3),
            });

        } else {
            device.service_profile_uuid = ProfileData.value;

            if (ProfileData.label && this.state.device.device_profile_uuid) {
                // Both profiles have been set, swith to key view
                this.setState({ keys: true, profiles: false });
            }

            this.setState({
                serviceProfile: this.findProfileInList(ProfileData.value, this.state.serviceProfiles),
            });

        }

        await this.setState({ device });
        this.CheckErrors();
    }


    public validateInput = async (event) => {
        try {
            const target = event.target;
            const pattern = new RegExp(target.pattern);
            let error = "";
            const change = {};
            Object.keys(this.state).map((element) => {
                if (element.includes(target.name)) {
                    error = element;
                }
            });
            await Object.keys(this.state.device).map((element) => {
                if (element === target.name && target.required && target.value === "") {
                    change[error] = true;
                    this.setState(change);
                } else if (element === target.name && !target.required && target.value === "") {
                    change[error] = false;
                    this.setState(change);
                } else if (element === target.name && (!pattern.test(target.value))) {
                    change[error] = true;
                    this.setState(change);
                } else if (element === target.name && pattern.test(target.value)) {
                    change[error] = false;
                    this.setState(change);
                }
            });
            this.CheckErrors();

        } catch (error) {
            // TODO: Why eat the error?
        }
    }

    public handleTabs = (event) => {
        if (event.target.title === "Keys") {
            this.setState({ keys: true, profiles: false, additional: false, tagsTab: false });
        } else if (event.target.title === "Profiles") {
            this.setState({ keys: false, profiles: true, additional: false, tagsTab: false });
        } else if (event.target.title === "Additional") {
            this.setState({ keys: false, profiles: false, additional: true, tagsTab: false });
        } else if (event.target.title === "Tags") {
            this.setState({ keys: false, profiles: false, additional: false, tagsTab: true });
        }
    }

    public ShowProfileName = (show, name) => {
        if (show === false && name === "DeviceProfile") {
            this.setState({
                InvalidDeviceProfileUUID: false,
                deviceProfile: undefined,
            });
        } else if (show === false && name === "ServiceProfile") {
            this.setState({
                InvalidServiceProfileUUID: false,
                serviceProfile: undefined,
            });
        }
    }

    public ShowUsers = async () => {
        try {
            const response = await getPagination("users", 3, null, null);
            this.setState({ users: response.data, users_exist: true, showUsers: !this.state.showUsers });
        } catch (error) {
            console.log(error);
        }
    }

    public changeOwner = (UserID) => {
        this.setState({ device: { ...this.state.device, userid: UserID } });
        this.CheckErrors();
    }

    public FilterDeviceProfiles =  (SearchValue) => {
        const SourceUser = this.state.ProfilesSourceUser;
        return new Promise(async (resolve) => {
            if (SearchValue.length > 0) {
                const SourceProfilesArray: any = [];
                try {
                    const SearchProfileNameResponse: any = await SearchXProfileName("device-profiles", SearchValue, SourceUser);
                    if (SearchProfileNameResponse.status === 200) {
                        SourceProfilesArray.push(await SearchProfileNameResponse.json());
                    }
                    const SearchProfileDescriptionResponse: any =
                        await SearchXProfileDescription("device-profiles", SearchValue, SourceUser);
                    if (SearchProfileDescriptionResponse.status === 200) {
                        SourceProfilesArray.push(await SearchProfileDescriptionResponse.json());
                    }
                    const SearchProfileUUIDResponse: any = await SearchXProfileUUID("device-profiles", SearchValue, SourceUser);
                    if (SearchProfileUUIDResponse.status === 200) {
                        SourceProfilesArray.push(await SearchProfileUUIDResponse.json());
                    }
                    Promise.all([
                        SearchProfileDescriptionResponse,
                        SearchProfileNameResponse,
                        SearchProfileUUIDResponse,
                    ]).then(() => {
                        const SelectOptions: any = [];
                        SourceProfilesArray.map((SearchResults) => {
                            SearchResults.map((Profiles) => {
                                SelectOptions.push({
                                    description: Profiles.description,
                                    label: Profiles.profile_name,
                                    value: Profiles.profile_uuid,
                                });
                            });
                        });
                        resolve(RemoveDuplicateValues(SelectOptions, "value"));
                    });
                } catch (error) {
                    console.log(error);
                    toast.error(strings.FILTER_PROFILES_ERROR);
                }
            }
        });
    }

    public FilterServiceProfiles =  (SearchValue) => {
        const SourceUser = this.state.ProfilesSourceUser;
        return new Promise(async (resolve) => {
            if (SearchValue.length > 0) {
                const SourceProfilesArray: any = [];
                try {
                    const SearchProfileNameResponse: any = await SearchXProfileName("service-profiles", SearchValue, SourceUser);
                    if (SearchProfileNameResponse.status === 200) {
                        SourceProfilesArray.push(await SearchProfileNameResponse.json());
                    }
                    const SearchProfileDescriptionResponse: any =
                        await SearchXProfileDescription("service-profiles", SearchValue, SourceUser);
                    if (SearchProfileDescriptionResponse.status === 200) {
                        SourceProfilesArray.push(await SearchProfileDescriptionResponse.json());
                    }
                    const SearchProfileUUIDResponse: any = await SearchXProfileUUID("service-profiles", SearchValue, SourceUser);
                    if (SearchProfileUUIDResponse.status === 200) {
                        SourceProfilesArray.push(await SearchProfileUUIDResponse.json());
                    }
                    Promise.all([
                        SearchProfileDescriptionResponse,
                        SearchProfileNameResponse,
                        SearchProfileUUIDResponse,
                    ]).then(() => {
                        const SelectOptions: any = [];
                        SourceProfilesArray.map((SearchResults) => {
                            SearchResults.map((Profiles) => {
                                SelectOptions.push({
                                    description: Profiles.description,
                                    label: Profiles.profile_name,
                                    value: Profiles.profile_uuid,
                                });
                            });
                        });
                        resolve(RemoveDuplicateValues(SelectOptions, "value"));
                    });
                } catch (error) {
                    console.log(error);
                    toast.error(strings.FILTER_PROFILES_ERROR);
                }
            }
        });
    }

    public CheckLoggedUser = async () => {
        try {
            const response: any = await Whoami();
            let LoggedUser = this.state.LoggedUser;
            if (response !== null) {
                if (!response.user.is_customer && response.user.administrator) {
                    getDataElementById("customers", response.user.userid).then(async (userRes) => {
                        let RootUser = this.state.RootUser;
                        if (userRes.status === 200) {
                            const UserData = userRes.data;
                            if (UserData && (UserData.userid !== undefined)) {
                                RootUser = UserData.userid;
                            } else {
                                RootUser = undefined;
                            }
                        } else {
                            RootUser = undefined;
                        }
                        this.setState({
                            RootUser,
                        });
                    }).catch ((error) => {
                        console.log(error);
                        this.setState({
                            RootUser: undefined,
                        });
                    });
                } else {
                    this.setState({
                        RootUser: undefined,
                    });
                }
                LoggedUser = response.user;
            } else {
                LoggedUser = null;
            }
            this.setState({
                LoggedUser,
            });
            return LoggedUser;

        } catch (error) {
            this.setState({
                LoggedUser: null,
            });
            console.log(error);
        }
        return null;
    }

    public AssignToParent = (UserId) => {
        if (UserId !== undefined) {
            this.editDevice({AssignToParent: true, userid: UserId});
        }
    }

    public handleDeviceClass = (value) => {
        this.setState({
            device: { ...this.state.device, lora_device_class: value },
        });
        this.CheckErrors();
    }

    public handleDeviceActivated = (value) => {
        this.setState({
            device: { ...this.state.device, activated: value },
        });
        this.CheckErrors();
    }

    public editTag(index) {
        if (this.state.globalTagNameValid) {
            let currentTagName = "";
            let currentTagDesc = "";

            const tags = this.state.tagsData;
            for (let i = 0; i < tags.length; i++) {
                if (tags[i].id === index && this.state.globalTagNameValid) {
                    tags[i].editable = "show-input-container";

                    currentTagName = tags[i].name;
                    currentTagDesc = tags[i].desc;

                    const that = this;
                    setTimeout(() => {
                        if (that.inputRefs && that.inputRefs[i]) {
                            that.inputRefs[i].focus();
                        }
                    }, 0);
                } else if (this.state.globalTagNameValid) {
                    tags[i].editable = "hide-input-container";
                }
            }

            this.setState({
                oldTagDesc: currentTagDesc,
                oldTagName: currentTagName,
                tagsData: tags,
            });
        }
    }

    public saveEditingTag() {
        if (!this.state.hasSameTagName) {
            const tags = this.state.tagsData;
            for (let i = 0; i < tags.length; i++) {
                if (tags[i].validName) {
                    tags[i].editable = "hide-input-container";
                }
            }

            this.setState({
                tagsData: tags,
            });
        }
    }

    public deleteTag(index) {
        const tags = this.state.tagsData;
        const newTags = [] as any;
        let globalTagNameValidHelper = this.state.globalTagNameValid;
        for (let i = 0; i < tags.length; i++) {
            if (tags[i].id !== index) {
                newTags.push(tags[i]);
            } else if (!tags[i].validName && this.state.isTagNameValid) {
                globalTagNameValidHelper = true;
            }
        }

        this.setState({
            globalTagNameValid: globalTagNameValidHelper,
            tagsData: newTags,
        });
    }

    public tagDescChange(index, e) {
        const tags = this.state.tagsData;
        for (let i = 0; i < tags.length; i++) {
            if (tags[i].id === index) {
                tags[i].desc = e.target.value;
            }
        }

        this.setState({
            tagsData: tags,
        });
    }

    public tagNameChange(index, e) {
        const tags = this.state.tagsData;
        let globalCurrentTagNameValid = this.state.globalTagNameValid && this.state.isTagNameValid;
        let bHasSameTagName = false;
        for (let i = 0; i < tags.length; i++) {
            if (tags[i].id === index) {
                tags[i].name = e.target.value;

                const regexTagName = /^[a-zA-Z\d\-_]*$/;
                const result = tags[i].name.match(regexTagName);
                if (!result) {
                    tags[i].validName = false;
                    globalCurrentTagNameValid = false;
                } else {
                    tags[i].validName = true;
                    globalCurrentTagNameValid = true && this.state.isTagNameValid;
                }

                let matchCounter = 0;
                for (let j = 0; j < tags.length; j++) {
                    if (tags[j].name === e.target.value) {
                        matchCounter++;
                    }
                }

                if (matchCounter > 1) {
                    tags[i].isSameName = true;
                    bHasSameTagName = true;
                } else {
                    tags[i].isSameName = false;
                }

                break;
            }
        }

        this.setState({
            globalTagNameValid: globalCurrentTagNameValid,
            hasSameTagName: bHasSameTagName,
            tagsData: tags,
        });
    }

    public handleKeyPress = (e) => {
        if (e.key === "Enter" && e.target.value !== "") {

            if (this.state.newTagName !== "" && this.state.newTagDesc !== "") {
                this.insertNewTag();
            } else if (e.target.classList.contains("new-tag-name")) {
                this.newTagDescRef.current.focus();
            } else if (e.target.classList.contains("new-tag-description")) {
                this.newTagNameRef.current.focus();
            }
        }
    }

    public handleKeyPressOnEditTag = (e) => {
        if (e.key === "Enter" && e.target.value !== "" && !this.state.hasSameTagName) {

            const tags = this.state.tagsData;
            for (let i = 0; i < tags.length; i++) {
                if (tags[i].validName) {
                    tags[i].editable = "hide-input-container";
                }
            }

            this.setState({
                tagsData: tags,
            });
        }
    }

    public newTagNameChange(e) {
        const that = this;
        setTimeout(() => {
            let bFlag = false;
            const tags = that.state.tagsData;
            for (let i = 0; i < tags.length; i++) {
                if (tags[i].name === that.state.newTagName) {
                    bFlag = true;
                    break;
                }
            }

            that.setState({
                sameTagName: bFlag,
            });

        }, 0);

        const regexTagName = /^[a-zA-Z\d\-_]*$/;
        const result = e.target.value.match(regexTagName);

        if (result != null) {
            this.setState({
                globalTagNameValid: true,
                isTagNameValid: true,
            });
        } else {
            if (e.target.value === "") {
                this.setState({
                    globalTagNameValid: true,
                    isTagNameValid: true,
                });
            } else {
                this.setState({
                    globalTagNameValid: false,
                    isTagNameValid: false,
                });
            }
        }

        const tags = this.state.tagsData;
        for (let i = 0; i < tags.length; i++) {
            if (!tags[i].validName) {
                this.setState({
                    globalTagNameValid: false,
                });
                break;
            }
        }

        this.setState({
            newTagName: e.target.value,
        });

        this.handleKeyPress(e);
    }

    public newTagDescChange(e) {
        this.setState({
            newTagDesc: e.target.value,
        });

        this.handleKeyPress(e);
    }

    public insertNewTag() {
        let bFlag = false;
        const tags = this.state.tagsData;
        for (let i = 0; i < tags.length; i++) {
            if (tags[i].name === this.state.newTagName) {
                bFlag = true;
                break;
            }
        }

        this.setState({
            sameTagName: bFlag,
        });

        if (bFlag) {
            return;
        }

        if (this.state.newTagName !== "" && this.state.newTagDesc !== "" && this.state.isTagNameValid) {
            const tags = this.state.tagsData;
            const tag = {
                desc: this.state.newTagDesc,
                editable: "hide-input-container",
                id: this.state.initialTagsLength,
                isSameName: false,
                name: this.state.newTagName,
                validName: true,
            };

            this.newTagNameRef.current.focus();

            const newTagsLength = this.state.initialTagsLength + 1;

            tags.push(tag);
            this.setState({
                initialTagsLength: newTagsLength,
                newTagDesc: "",
                newTagName: "",
                tagsData: tags,
            });
        } else if (this.state.newTagDesc !== "" && !this.state.isTagNameValid) {
            this.newTagNameRef.current.focus();
        } else if (this.state.newTagDesc === "") {
            this.newTagDescRef.current.focus();
        } else if (this.state.newTagName === "" && this.state.newTagDesc === "") {
            this.newTagNameRef.current.focus();
        }
    }

    public clearTagInputs() {
        let globalTagNameValidHelper = this.state.globalTagNameValid;
        if (!this.state.isTagNameValid) {
            globalTagNameValidHelper = true;
        }

        const tags = this.state.tagsData;
        for (let i = 0; i < tags.length; i++) {
            if (!tags[i].validName) {
                globalTagNameValidHelper = false;

                break;
            }
        }

        this.setState({
            globalTagNameValid: globalTagNameValidHelper,
            isTagNameValid: true,
            newTagDesc: "",
            newTagName: "",
            sameTagName: false,
        });
    }

    public clearCurrentTag(tagId) {
        const tags = this.state.tagsData;

        for (let i = 0; i < tags.length; i++) {

            if (tags[i].id === tagId) {
                tags[i].name = this.state.oldTagName;
                tags[i].desc = this.state.oldTagDesc;
                tags[i].validName = true;
                tags[i].isSameName = false;
            }
        }

        const hasSameTagNameHelper = this.state.hasSameTagName;
        this.setState({
            globalTagNameValid: true && this.state.isTagNameValid,
            hasSameTagName: false && hasSameTagNameHelper,
            tagsData: tags,
        });
    }

    public isEditMode() {
        let className = "";

        if (this.state.readOnly) {
            className = "edit-mode";
        }

        return className;
    }

    public render() {
        const that = this;
        const listTags = this.state.tagsData.map((tag) => {
            return (
                <tr key={tag.id}>
                    <td className={`tag-name-input ${tag.editable}`}>
                        <div className="hide-value">
                            { tag.name }
                        </div>

                        <div className="hide-input">
                            <input
                                type="text"
                                value={tag.name}
                                className="tag-input-container"
                                onKeyPress={that.handleKeyPressOnEditTag.bind(that)}
                                ref={that.setRef}
                                onChange={that.tagNameChange.bind(that, tag.id)} />

                                {!tag.validName && (
                                    <span className="popover-tag-name-react">
                                        {strings.TAG_NAME_AVAILABLE_CHARS}
                                    </span>
                                )}

                                {tag.isSameName && (
                                    <span className="popover-tag-name-react">
                                        {strings.TAG_NAME_UNIQUE}
                                    </span>
                                )}
                        </div>
                    </td>

                    <td className={`tag-desc-input ${tag.editable}`}>
                        <div className="hide-value">
                            { tag.desc }
                        </div>

                        <div className="hide-input">
                            <input
                                type="text"
                                value={tag.desc}
                                className="tag-input-container"
                                onKeyPress={that.handleKeyPressOnEditTag.bind(that)}
                                onChange={that.tagDescChange.bind(that, tag.id)} />
                        </div>
                    </td>

                    <td className="tag-acion-container">
                        <div className={`current-tag-edit-btns ${tag.editable}`}>
                            <button
                                className="fa fa-pencil-square-o fa-fw edit-icon edit-tag"
                                disabled={ that.state.readOnly }
                                onClick={that.editTag.bind(that, tag.id)} >
                            </button>

                            <button
                                className="fa fa-trash-o fa-fw delete-icon"
                                disabled={ that.state.readOnly }
                                onClick={that.deleteTag.bind(that, tag.id)} >
                            </button>
                        </div>

                        <div className={`current-tag-action-btns ${tag.editable}`}>
                            <button
                                className="fa fa-check fa-fw edit-icon"
                                disabled={ that.state.readOnly }
                                onClick={that.saveEditingTag.bind(that)} >
                            </button>

                            <button
                                className="fa fa-times fa-fw clear-input"
                                disabled={ that.state.readOnly }
                                onClick={that.clearCurrentTag.bind(that, tag.id)} >
                            </button>
                        </div>
                    </td>
                </tr>
            );
        });

        const MigrateDevice = this.props.EditDevice ? this.props.EditDevice.MigrateDevice ?
            this.props.EditDevice.MigrateDevice : undefined : undefined;
        const MigrateDeviceTitle = this.props.EditDevice ? this.props.EditDevice.MigrateDeviceBulk ? strings.MIGRATE_DEVICES : strings.MIGRATE_DEVICE : "";
        return (
            <div>
                <Modal
                    show={this.state.ShowModal}
                    onHide={this.closeModal}
                    bsSize="lg"
                    className="ReactModal"
                    backdrop="static"
                >
                    <Modal.Header closeButton={true}>
                        <Modal.Title>
                            {this.props.editMode ? MigrateDevice ? MigrateDeviceTitle : strings.EDIT_DEVICE_BTN : strings.ADD_NEW_DEVICE_BTN}
                        </Modal.Title>
                    </Modal.Header>
                    {this.state.Loading ? (
                        <Col sm={12} className="LoaderWrapper">
                            <i className="fa fa-spinner fa-spin fa-5x"></i>
                        </Col>
                    ) : (
                            <Modal.Body className="padding_0_all">
                                <Row className="padding_top">
                                    <Col sm={12} className="form-horizontal new_style_form white-background">
                                        <div className={this.state.error_deveui ? "form-group has-error" : "form-group"}>
                                            <label className="col-sm-2 control-label">
                                                <strong className="new_style_font_weight font_size_18">{strings.DEVEUI}</strong>
                                            </label>
                                            <div className="col-sm-10">
                                                <input type="text" name="deveui"
                                                    readOnly={this.props.EditDevice ? true : this.state.readOnly}
                                                    onChange={this.handleChange} className="form-control new_style_focus" value={this.state.device.deveui}
                                                    pattern="(^(0[xX])?[0-9a-fA-F]{16}$)|(^([0-9a-fA-F]{2}-){7}[0-9a-fA-F]{2}$)" required />
                                                <small className="help-block new_style_help_block_color font_size_11">{strings.EDIT_DEVICE_HELP_BLOCK}</small>
                                            </div>
                                        </div>
                                        {this.state.activation === "ABP" &&
                                        (!this.props.hasOwnProperty("EditDevice") ||
                                        (this.props.hasOwnProperty("EditDevice") &&
                                        !this.props.EditDevice.hasOwnProperty("MigrateDeviceBulk"))) &&
                                            <div className={this.state.error_appeui ? "form-group has-error" : "form-group"}>
                                                <label className="col-sm-2 control-label">
                                                    <strong className="new_style_font_weight font_size_18">{strings.APP_EUI}</strong>
                                                </label>
                                                <div className="col-sm-10">
                                                    <input type="text" name="appeui" className="form-control"
                                                        onChange={this.handleChange}
                                                        value={(this.state.device.appeui === null ? "" : this.state.device.appeui)}
                                                        readOnly={this.state.readOnly}
                                                        pattern="(^(0[xX])?[0-9a-fA-F]{16}$)|(^([0-9a-fA-F]{2}-){7}[0-9a-fA-F]{2}$)"
                                                    />
                                                    <small className="help-block new_style_help_block_color font_size_11">{strings.APP_EUI_HELP}</small>
                                                </div>
                                            </div>
                                        }
                                        <div className="form-group">
                                            <label className="col-sm-2 control-label">
                                                <strong className="new_style_font_weight font_size_18">{strings.COMMENT}</strong>
                                            </label>
                                            <div className="col-sm-10">
                                                <input type="text" name="comment" onChange={this.handleChange} className="form-control"
                                                    value={this.state.device.comment} readOnly={this.state.readOnly} />
                                                <small className="help-block new_style_help_block_color font_size_11">{strings.DEVICE_COMMENT_HELP_BLOCK}</small>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Tabs id="DeviceModalTab">
                                        <Row className="clearfix ">
                                            <Col className="tabs_style">
                                                <Nav >
                                                    <NavItem className={this.state.profiles ? "nav-items" : ""} onClick={this.handleTabs} title="Profiles">{strings.PROFILES}</NavItem>
                                                    <NavItem className={this.state.keys ? "nav-items" : ""} onClick={this.handleTabs} title="Keys">{strings.TAB_KEYS}</NavItem>
                                                    <NavItem className={this.state.additional ? "nav-items" : ""} onClick={this.handleTabs} title="Additional">{strings.TAB_MISCELLANIOUS}</NavItem>
                                                    <NavItem className={this.state.tagsTab ? "nav-items" : ""} onClick={this.handleTabs} title="Tags">
                                                        {strings.EDIT_USER_TAGS}
                                                    </NavItem>
                                                </Nav>
                                            </Col>
                                            <Col className="style_tabs">
                                                <Tab.Content animation>
                                                    {this.state.keys && <Tab.Pane >
                                                        <div className="form-group">
                                                            <label className="col-sm-2 control-label">
                                                                <strong className="new_style_font_weight">{strings.DEVICE_CLASS}</strong>
                                                            </label>
                                                            <div className="col-sm-10">
                                                                <span className="btn-group">
                                                                    <button type="button" className={this.state.device.lora_device_class === 0 ? "btn btn-default active" : "btn btn-default"} value={this.state.device.lora_device_class}
                                                                        onClick={() => this.handleDeviceClass(0)}
                                                                        disabled={this.state.readOnly}
                                                                    >A</button>
                                                                    <button type="button" className={this.state.device.lora_device_class === 1 ? "btn btn-default active" : "btn btn-default"} value={this.state.device.lora_device_class}
                                                                        onClick={() => this.handleDeviceClass(1)}
                                                                        disabled={this.state.readOnly || !this.state.SupportsClassB}
                                                                    >B</button>
                                                                    <button type="button" className={this.state.device.lora_device_class === 2 ? "btn btn-default active" : "btn btn-default"} value={this.state.device.lora_device_class}
                                                                        onClick={() => this.handleDeviceClass(2)}
                                                                        disabled={this.state.readOnly || !this.state.SupportsClassC}
                                                                    >C</button>
                                                                </span>
                                                                <small className="help-block new_style_help_block_color">{strings.DEVICE_CLASS_HELP}</small>
                                                            </div>
                                                        </div>
                                                        <div className="form-group" >
                                                            <label className="col-sm-2 control-label">
                                                                <strong className="new_style_font_weight">{strings.DEVICE_ACTIVATED}</strong>
                                                            </label>
                                                            <div className="col-sm-10">
                                                                <span className="btn-group">
                                                                    <button type="button" className={this.state.device.activated === true ? "btn btn-default active" : "btn btn-default"} value={this.state.device.activated}
                                                                        onClick={() => this.handleDeviceActivated(true)}
                                                                        disabled={this.state.readOnly}
                                                                    >{strings.YES}</button>
                                                                    <button type="button" className={this.state.device.activated === false ? "btn btn-default active" : "btn btn-default"} value={this.state.device.activated}
                                                                        onClick={() => this.handleDeviceActivated(false)}
                                                                        disabled={this.state.readOnly}
                                                                    >{strings.NO}</button>
                                                                </span>
                                                                <small className="help-block new_style_help_block_color">{strings.DEVICE_ACTIVATED_HELP}</small>
                                                            </div>
                                                        </div>
                                                        {this.state.activation === "ABP" &&
                                                        (!this.props.hasOwnProperty("EditDevice") ||
                                                        (this.props.hasOwnProperty("EditDevice") &&
                                                        !this.props.EditDevice.hasOwnProperty("MigrateDeviceBulk"))) &&
                                                            <div className={this.state.error_devaddr ? "form-group has-error" : "form-group"} >
                                                                <label className="col-sm-2 control-label">
                                                                    <strong className="new_style_font_weight">{strings.DEV_ADDR}</strong>
                                                                </label>
                                                                <div className="col-sm-10">
                                                                    <input type="text" name="devaddr" className="form-control new_style_focus" value={this.state.device.devaddr} readOnly={this.state.readOnly}
                                                                        onChange={this.handleChange} pattern="(^(0[x])?[0-9a-fA-F]{8}$)|(^([0-9a-fA-F]{2}-){3}[0-9a-fA-F]{2}$)i" required />
                                                                    <small className="help-block new_style_help_block_color">{strings.DEV_ADDR_HELP_BLOCK}</small>
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.activation === "ABP" &&  this.state.LoggedUser.can_register &&
                                                        (!this.props.hasOwnProperty("EditDevice") ||
                                                        (this.props.hasOwnProperty("EditDevice") &&
                                                        !this.props.EditDevice.hasOwnProperty("MigrateDeviceBulk"))) &&
                                                        this.state.MACVersion !== "1.1" &&
                                                            <div
                                                                className={this.state.error_nwkskey ? "form-group has-error" : "form-group"}>
                                                                <label className="col-sm-2 control-label">
                                                                    <strong className="new_style_font_weight">{strings.NWKS_KEY}</strong>
                                                                </label>
                                                                <div className="col-sm-10">
                                                                    <input type="text" name="nwkskey" className="form-control new_style_focus" value={this.state.device.nwkskey} readOnly={this.state.readOnly}
                                                                        onChange={this.handleChange} pattern="(^(0[x])?[0-9a-fA-F]{32}$)|(^([0-9a-fA-F]{2}-){15}[0-9a-fA-F]{2}$)" required />
                                                                    <small className="help-block new_style_help_block_color">{strings.NWKS_KEY_HELP}</small>
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.activation === "OTAA" && this.state.LoggedUser.can_register &&
                                                        (!this.props.hasOwnProperty("EditDevice") ||
                                                        (this.props.hasOwnProperty("EditDevice") &&
                                                        !this.props.EditDevice.hasOwnProperty("MigrateDeviceBulk"))) &&
                                                        this.state.MACVersion === "1.1" &&
                                                            <div className={this.state.error_nwkkey ? "form-group has-error" : "form-group"} >
                                                                <label className="col-sm-2 control-label">
                                                                    <strong className="new_style_font_weight">{strings.NWK_KEY}</strong>
                                                                </label>
                                                                <div className="col-sm-10">
                                                                    <input type="text" name="nwkkey" className="form-control" value={this.state.device.nwkkey} readOnly={this.state.readOnly}
                                                                        onChange={this.handleChange} pattern="^$|(^(0[x])?[0-9a-fA-F]{32}$)|(^([0-9a-fA-F]{2}-){15}[0-9a-fA-F]{2}$)" />
                                                                    <small className="help-block new_style_help_block_color">{strings.NWK_KEY_HELP}</small>
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.activation === "ABP" && this.state.LoggedUser.can_register &&
                                                        (!this.props.hasOwnProperty("EditDevice") ||
                                                        (this.props.hasOwnProperty("EditDevice") &&
                                                        !this.props.EditDevice.hasOwnProperty("MigrateDeviceBulk"))) &&
                                                        this.state.MACVersion === "1.1" &&
                                                            <div
                                                                className={this.state.error_snwksintkey ? "form-group has-error" : "form-group"}>
                                                                <label className="col-sm-2 control-label">
                                                                    <strong className="new_style_font_weight">{strings.SNWKS_KEY}</strong>
                                                                </label>
                                                                <div className="col-sm-10">
                                                                    <input type="text" name="snwksintkey" className="form-control new_style_focus" value={this.state.device.snwksintkey}
                                                                        onChange={this.handleChange} pattern="(^(0[x])?[0-9a-fA-F]{32}$)|(^([0-9a-fA-F]{2}-){15}[0-9a-fA-F]{2}$)"
                                                                        required readOnly={this.state.readOnly} />
                                                                    <small className="help-block new_style_help_block_color">{strings.SNWKS_KEY_HELP}</small>
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.activation === "ABP" && this.state.LoggedUser.can_register &&
                                                        (!this.props.hasOwnProperty("EditDevice") ||
                                                        (this.props.hasOwnProperty("EditDevice") &&
                                                        !this.props.EditDevice.hasOwnProperty("MigrateDeviceBulk"))) &&
                                                        this.state.MACVersion === "1.1" &&
                                                            <div
                                                                className={this.state.error_fnwksintkey ? "form-group has-error" : "form-group"}>
                                                                <label className="col-sm-2 control-label">
                                                                    <strong className="new_style_font_weight">{strings.FNWKS_KEY}</strong>
                                                                </label>
                                                                <div className="col-sm-10">
                                                                    <input type="text" name="fnwksintkey" className="form-control new_style_focus" value={this.state.device.fnwksintkey}
                                                                        onChange={this.handleChange} pattern="(^(0[x])?[0-9a-fA-F]{32}$)|(^([0-9a-fA-F]{2}-){15}[0-9a-fA-F]{2}$)"
                                                                        required readOnly={this.state.readOnly} />
                                                                    <small className="help-block new_style_help_block_color">{strings.FNWKS_KEY_HELP}</small>
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.activation === "ABP" && this.state.LoggedUser.can_register &&
                                                        (!this.props.hasOwnProperty("EditDevice") ||
                                                        (this.props.hasOwnProperty("EditDevice") &&
                                                        !this.props.EditDevice.hasOwnProperty("MigrateDeviceBulk"))) &&
                                                        this.state.MACVersion === "1.1" &&
                                                            <div
                                                                className={this.state.error_nwksenckey ? "form-group has-error" : "form-group"}>
                                                                <label className="col-sm-2 control-label">
                                                                    <strong className="new_style_font_weight">{strings.NWKSE_KEY}</strong>
                                                                </label>
                                                                <div className="col-sm-10">
                                                                    <input type="text" name="nwksenckey" className="form-control new_style_focus" value={this.state.device.nwksenckey}
                                                                        onChange={this.handleChange} pattern="(^(0[x])?[0-9a-fA-F]{32}$)|(^([0-9a-fA-F]{2}-){15}[0-9a-fA-F]{2}$)"
                                                                        required readOnly={this.state.readOnly} />
                                                                    <small className="help-block new_style_help_block_color">{strings.NWKSE_KEY_HELP}</small>
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.activation === "ABP" && this.state.LoggedUser.can_register &&
                                                        (!this.props.hasOwnProperty("EditDevice") ||
                                                        (this.props.hasOwnProperty("EditDevice") &&
                                                        !this.props.EditDevice.hasOwnProperty("MigrateDeviceBulk"))) &&
                                                            <div className={this.state.error_appskey ? "form-group has-error" : "form-group"}>
                                                                <label className="col-sm-2 control-label">
                                                                    <strong className="new_style_font_weight">AppSKey</strong>
                                                                </label>
                                                                <div className="col-sm-10">
                                                                    <input type="text" onChange={this.handleChange} name="appskey" className="form-control" value={this.state.device.appskey}
                                                                        pattern="(^(0[x])?[0-9a-fA-F]{32}$)|(^([0-9a-fA-F]{2}-){15}[0-9a-fA-F]{2}$)" readOnly={this.state.readOnly} />
                                                                    <small className="help-block new_style_help_block_color">
                                                                        {strings.APPS_KEY_HELP}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        }

                                                        {this.state.activation === "OTAA" && this.state.LoggedUser.can_register &&
                                                        (!this.props.hasOwnProperty("EditDevice") ||
                                                        (this.props.hasOwnProperty("EditDevice") &&
                                                        !this.props.EditDevice.hasOwnProperty("MigrateDeviceBulk"))) &&
                                                            <div className={this.state.error_appkey ? "form-group has-error" : "form-group"}>
                                                                <label className="col-sm-2 control-label">
                                                                    <strong className="new_style_font_weight">{strings.APP_KEY}</strong>
                                                                </label>
                                                                <div className="col-sm-10">
                                                                    <input type="text" name="appkey" className="form-control" value={this.state.device.appkey} readOnly={this.state.readOnly}
                                                                        onChange={this.handleChange} pattern="(^(0[x])?[0-9a-fA-F]{32}$)|(^([0-9a-fA-F]{2}-){15}[0-9a-fA-F]{2}$)" />
                                                                    <small className="help-block new_style_help_block_color">{strings.APP_KEY_HELP}</small>
                                                                </div>
                                                            </div>
                                                        }
                                                    </Tab.Pane>}
                                                    {this.state.profiles && <Tab.Pane >
                                                        <div className={`form-group select_device_profile ${this.state.InvalidDeviceProfileUUID ? "has-error" : ""}`}>
                                                            <label className="col-sm-2 control-label select_device_profile_label">
                                                                <strong className="new_style_font_weight">{strings.DEVICE_PROFILE}</strong>
                                                            </label>
                                                            <div className="col-sm-10">
                                                                <SelectDropdown
                                                                    ProfileName="DeviceProfile"
                                                                    Profiles={this.state.deviceProfiles}
                                                                    SelectedProfile={this.SelectedProfile}
                                                                    DefaultValue={this.state.deviceProfile}
                                                                    Disabled={this.state.readOnly || this.state.DisableDeviceProfile}
                                                                    wait={this.state.readOnly ? 1000 : 0}
                                                                    ShowProfileName={this.ShowProfileName}
                                                                    Remote={true}
                                                                    RemoteFilter={this.FilterDeviceProfiles}
                                                                    Creatable={true}
                                                                    Error={this.state.InvalidDeviceProfileUUID}
                                                                    Clearable={true}
                                                                    OpenMenuOnClick={true}
                                                                />
                                                                {(this.state.InvalidDeviceProfileUUID || (!this.state.readOnly && this.state.DisableDeviceProfile)) && (
                                                                    <small className={`help-block new_style_help_block_color${this.state.DisableDeviceProfile ? " error_text_style" : ""}`}>
                                                                        {strings[this.state.DisableDeviceProfile ?
                                                                            "HELP_MSG_DEVICE_CHANGING_DEVICE_PROFILE_NOT_ALLOWED" : "INVALID_PROFILE_UUID_FORMAT"
                                                                        ]}
                                                                    </small>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={`form-group select_service_profile ${this.state.InvalidServiceProfileUUID ? "has-error" : ""}`}>
                                                            <label className="col-sm-2 control-label select_service_profile_label">
                                                                <strong className="new_style_font_weight">{strings.SERVICE_PROFILE}</strong>
                                                            </label>
                                                            <div className="col-sm-10">
                                                                <SelectDropdown
                                                                    ProfileName="ServiceProfile"
                                                                    Profiles={this.state.serviceProfiles}
                                                                    SelectedProfile={this.SelectedProfile}
                                                                    DefaultValue={this.state.serviceProfile}
                                                                    Disabled={this.state.readOnly}
                                                                    wait={this.state.readOnly ? 1000 : 0}
                                                                    ShowProfileName={this.ShowProfileName}
                                                                    Remote={true}
                                                                    RemoteFilter={this.FilterServiceProfiles}
                                                                    Creatable={true}
                                                                    Error={this.state.InvalidServiceProfileUUID}
                                                                    Clearable={true}
                                                                    OpenMenuOnClick={true}
                                                                />
                                                                {this.state.InvalidServiceProfileUUID && (
                                                                    <small className="help-block new_style_help_block_color">
                                                                        {strings.INVALID_PROFILE_UUID_FORMAT}
                                                                    </small>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Tab.Pane>}
                                                    {this.state.additional && <Tab.Pane>


                                                        {this.state.activation === "OTAA" &&
                                                            <div className={this.state.error_joineui ? "form-group has-error" : "form-group"}>
                                                                <label className="col-sm-2 control-label">
                                                                    <strong className="new_style_font_weight">{strings.JOIN_EUI}</strong>
                                                                </label>
                                                                <div className="col-sm-10">
                                                                    <input type="text" name="joineui" className="form-control"
                                                                        onChange={this.handleChange}
                                                                        value={(this.state.device.joineui === null ? "" : this.state.device.joineui)}
                                                                        readOnly={this.state.readOnly}
                                                                        pattern="(^(0[xX])?[0-9a-fA-F]{16}$)|(^([0-9a-fA-F]{2}-){7}[0-9a-fA-F]{2}$)"
                                                                    />
                                                                    <small className="help-block new_style_help_block_color">{strings.JOIN_EUI_HELP}</small>
                                                                </div>
                                                            </div>
                                                        }

                                                        <div className={this.state.options_error ? "form-group has-error" : "form-group"}>
                                                            <label className="col-sm-2 control-label">
                                                                <strong className="new_style_font_weight">{strings.OPTIONS}</strong>
                                                            </label>
                                                            <div className="col-sm-10">
                                                                <input type="text" name="options" value={this.state.device.options} className="form-control"
                                                                    onChange={this.handleChange} pattern="(^(0[x])?[0-9a-fA-F]{8}$)|(^([0-9a-fA-F]{2}-){3}[0-9a-fA-F]{2}$)i" readOnly={this.state.readOnly} />
                                                                <small className="help-block new_style_help_block_color">{strings.OPTIONS_HELP}</small>
                                                            </div>
                                                        </div>

                                                        {(this.props.editMode && this.props.Device.user.administrator && (this.state.LoggedUser.administrator || this.state.LoggedUser.customer_admin)) &&
                                                            <div className="form-group">
                                                                <label className="col-sm-2 control-label">
                                                                    <strong className="new_style_font_weight">{strings.DEVICE_USER}</strong>
                                                                </label>
                                                                <div className="col-sm-10">
                                                                    {!this.state.showUsers && <button className="btn change_owner_btn" onClick={this.ShowUsers} disabled={this.state.readOnly}>
                                                                        {strings.DEVICE_USER}
                                                                    </button>}
                                                                    {this.state.showUsers && <button className="btn change_owner_btn red_b_btn_cancel" onClick={this.ShowUsers}>
                                                                        {strings.CANCEL}
                                                                    </button>}
                                                                    {(!this.state.LoggedUser.is_customer && (this.state.RootUser !== undefined) && this.state.LoggedUser.administrator) && (
                                                                        <button
                                                                            className="btn assign_to_parent_btn"
                                                                            onClick={() => {
                                                                                this.AssignToParent(this.state.RootUser);
                                                                            }} disabled={this.state.readOnly}
                                                                        >
                                                                            {strings.formatString(strings.CHANGE_OWNER_ASSIGN_TO_PARENT, {
                                                                                parentId: this.state.RootUser || "",
                                                                            })}
                                                                        </button>
                                                                    )}
                                                                    <small className="help-block new_style_help_block_color">{strings.DEVICE_USER_HELP}</small>
                                                                </div>
                                                                <div className="col-sm-12" id="change_owner_table_React">
                                                                    {this.state.users_exist === true && this.state.showUsers
                                                                        && <DataTable
                                                                            pagination={this.state.users}
                                                                            mode="radio"
                                                                            changeOwner={this.changeOwner}
                                                                            defaultSize={3}
                                                                            UserRights={this.state.LoggedUser}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </Tab.Pane>}

                                                    {this.state.tagsTab && <Tab.Pane>
                                                        <Row className="tags-table-container">
                                                            <Table className="table table-striped table-hover new_style_dark tags-table device">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{strings.EDIT_USER_TAGS_NAME}</th>
                                                                        <th>{strings.EDIT_USER_TAGS_DESCRIPTION}</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody className="table-body">
                                                                    { listTags }

                                                                    <tr className={`new-tag-container ${this.isEditMode()}`}>
                                                                        <td>
                                                                            <input
                                                                                ref={this.newTagNameRef}
                                                                                type="text"
                                                                                value={this.state.newTagName}
                                                                                className="new-tag new-tag-name"
                                                                                placeholder={strings.ENTER_TAG_ID}
                                                                                readOnly={ this.state.readOnly }
                                                                                onKeyPress={this.handleKeyPress}
                                                                                onChange={that.newTagNameChange.bind(that)}
                                                                            />

                                                                            {!this.state.isTagNameValid && (
                                                                                <span className="popover-new-tag-name">
                                                                                    {strings.TAG_NAME_AVAILABLE_CHARS}
                                                                                </span>
                                                                            )}

                                                                            {this.state.sameTagName && (
                                                                                <span className="popover-tag-name-react">
                                                                                    {strings.TAG_NAME_UNIQUE}
                                                                                </span>
                                                                            )}
                                                                        </td>

                                                                        <td>
                                                                            <input
                                                                                ref={this.newTagDescRef}
                                                                                type="text"
                                                                                value={this.state.newTagDesc}
                                                                                className="new-tag new-tag-description"
                                                                                placeholder={strings.ENTER_TAG_VALUE}
                                                                                readOnly={ this.state.readOnly }
                                                                                onKeyPress={this.handleKeyPress}
                                                                                onChange={that.newTagDescChange.bind(that)}
                                                                            />
                                                                        </td>

                                                                        <td className="tag-acion-container">
                                                                            <button
                                                                                className="fa fa-check fa-fw edit-icon"
                                                                                disabled={ this.state.readOnly }
                                                                                onClick={that.insertNewTag.bind(that)} >
                                                                            </button>

                                                                            <button
                                                                                className="fa fa-times fa-fw clear-input"
                                                                                disabled={ this.state.readOnly }
                                                                                onClick={that.clearTagInputs.bind(that)} >
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </Row>
                                                    </Tab.Pane>}
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tabs>
                                </Row>
                            </Modal.Body>)}
                    <div className={(MigrateDevice !== undefined) ? "modal-footer add_edit_device migrate_device" : "modal-footer add_edit_device"}>
                        <div className={(MigrateDevice !== undefined ? "click_to_unlock" : "float_left")}>
                            {this.state.readOnly && <button
                                onClick={() => this.setState({ readOnly: false })}
                                className="btn btn-default glyphicon glyphicon-lock"></button>
                            }
                            <small hidden={!this.state.readOnly} className="horizontal_space">{strings.CLICK_TO_UNLOCK}</small>
                            {this.props.editMode && !this.state.readOnly &&
                                <button disabled className="btn btn-default glyphicon glyphicon-edit"></button>
                            }
                            <small hidden={this.state.readOnly || !this.props.editMode} className="horizontal_space">{strings.EDIT_DEVICE_PARAMETERS}</small>
                        </div>
                        {((MigrateDevice !== undefined) && (MigrateDevice === true) && (this.state.Loading === false)) && (
                            <div className="migrate_device_msg">
                                <img src="/theme/images/icon-attention.png" onError={(e: any) => {e.target.className = "no_image"; }} />
                                <p className="migrate_device_text">{strings.MIGRATE_DEVICE_ACTION_WARNING}</p>
                            </div>
                        )}
                        <div className={(MigrateDevice && (MigrateDevice === true) ? "modal_footer_btns" : undefined)}>
                            <Button
                                onClick={this.handleSubmit}
                                className="React_new_style_btn_ok"
                                disabled={this.state.hasSameTagName || !this.state.globalTagNameValid || this.state.errors}
                            >
                                {strings.OK}
                            </Button>
                            <Button onClick={this.closeModal} className="React_new_style_btn_cancel">
                                {strings.CANCEL}
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
