var app = angular.module('dassUiModule');

function UserListController(UserService, ToastService, MessageService, $filter, $uibModal, $log) {
	const $translate = $filter("translate");
	const formatDeveui = $filter("formatDeveui");
	const sortColumn = $filter('sortByColumn');

	let vm = this;
	// vm.customFilter = {
	// 	all: true
	// }
	vm.workingCount = 0;
	vm.numberOfUsers = 0;
	vm.numberOfCustomers = 0;
	vm.have_license = false;
	vm.have_dc_license = false;
	UserService.getUsers('user').then(
		(user) => {
				vm.numberOfUsers = user.length;
			}
		);
	UserService.getUsers('customer').then(
		(customer) => {
				vm.numberOfCustomers = customer.length;
			}
		);
	vm.updateDataType = (userType) => {
		vm.userType = userType;
		if (userType == 'customer') {
			vm.dataType = "/uiapi/rest/customers";
		} else {
			vm.dataType = "/uiapi/rest/users";
		}
		vm.customFilter = { all: true };
	};

	vm.loadUsers = (type) => {
		UserService.getUsers('user').then(
			(user) => {
					vm.numberOfUsers = user.length;
				}
			);
			UserService.getUsers('customer').then(
				(customer) => {
						vm.numberOfCustomers = customer.length;
					}
				);
		if (!vm.reloadData) return;
		var reloadPromise = vm.reloadData();
		if (reloadPromise) {
			vm.workingCount += 1;
			reloadPromise.then(() => {
				vm.updateDataType(type);
				vm.type = type;
				vm.workingCount -= 1;
			}).catch(err => {
				vm.workingCount -= 1;
			});
		}
	};

	vm.signInForward = (userid) => {
		console.log(`Forwarding sign in to user ${userid}`);
		localStorage.removeItem("currentGroup");

		UserService.signInForward(userid).then(
			response => window.location.href = "/mydevices_list.html"
		).catch(
			error => ToastService.showMessage($translate('MSG_LOGIN_FORWARD_FAILED'), "error")
		);
	};

	vm.reActivateUser = (user) => {
		UserService.updateUser(user.userid, { account_status: 'active' }).then(
			(response) => {
				user.account_status = 'active';
				ToastService.showMessage($translate('ACTIVATE_USER_SUCCESS'), "success");
			},
			(err) => {
				ToastService.showMessage($translate('USER_STATUS_ERROR'), "error");
			}
		);
	}

	vm.disableUser = (user) => {

		UserService.updateUser(user.userid, { account_status: 'disabled' }).then(
			(response) => {
				user.account_status = 'disabled';
				ToastService.showMessage($translate('DISABLE_USER_SUCCESS'), "success");
			},
			(err) => {
				ToastService.showMessage($translate('USER_STATUS_ERROR'), "error");
			}
		);
	}

	vm.suspendUser = (user) => {
		user.account_status = 'suspended';

		UserService.updateUser(user.userid, { account_status: 'suspended' }).then(
			(response) => {
				user.account_status = 'suspended';
				ToastService.showMessage($translate('SUSPEND_USER_SUCCESS'), "success");
			},
			(err) => {
				ToastService.showMessage($translate('USER_STATUS_ERROR'), "error");
			}
		);
	}

	vm.deleteUser = (userid, type) => {
		type = vm.userType === "customer" ? $translate('USER_TYPE_CUSTOMER').toLowerCase() : $translate('USER_TYPE_USER').toLowerCase();

		if (userid.toLowerCase() == vm.user.userid.toLowerCase()) {
			MessageService.showMessage({
				title: "Oops!",
				body: (type === "customer" ? $translate('USER_TYPE_CUSTOMER') + " '" : $translate('USER_TYPE_USER') + " '") + userid + "' cannot delete own account"
			}, (ok) => { });
			return;
		}

		MessageService.showMessage({
			title: $translate('MSG_USER_DELETE_CONFIRM_TITLE', {
				type: type
			}),
			body: $translate('MSG_USER_DELETE_CONFIRM', {
				type: type,
				userid: userid
			})
		}).then((ok) => {
			if (ok == "ok") {
				console.log("sending delete request");
				UserService.deleteUser(userid, vm.userType).then(response => {
					ToastService.showMessage($translate('MSG_USER_DELETE_SUCCESS_BODY', {
						type: type,
						userid: userid
					}), "success");
					vm.loadUsers(vm.userType);
				}).catch(err => {
					ToastService.showMessage($translate('MSG_USER_DELETE_FAIL_BODY', {
						type: type
					}), "error");
				});
			}
		});
	};

	vm.addUserOnDass = (user, usertype) => {
		let ut = usertype === "customer" ? $translate('USER_TYPE_CUSTOMER').toLowerCase() : $translate('USER_TYPE_USER').toLowerCase();
        
		UserService.addUserOnDass(user, usertype).then(response => {
			ToastService.showMessage($translate('MSG_USER_CREATE_SUCCESS_BODY', {
					ut: usertype[0].toUpperCase() + usertype.slice(1)
				}), "success"
			);
			vm.loadUsers(usertype);
		}).catch(err => {
			if(err.status === 412 || err.status === 400){
				ToastService.showMessage($translate('PASSWORD_FAILED'), "error");
			} else {
			ToastService.showMessage($translate('MSG_USER_CREATE_FAIL_BODY', {
				ut: usertype[0].toUpperCase() + usertype.slice(1)
				}) , "error"
			);
		}
		});
	}
	
	vm.addUser = (user, usertype) => {
		console.log("add user 123", user, usertype);
		vm.loadUserData();
		if (user) {
			UserService.getUser(user.userid, usertype).then(response => {
				let user_ = response;
				const modalInstance = $uibModal.open({
					backdrop: "static",
					animation: vm.animationsEnabled,
					templateUrl: 'addUserModalContent.html',
					controller: 'AddUserModalCtrl as vm',
					size: "",
					resolve: {
						items: () => {
							return {
								owner: vm.user,
								user: user_,
								usertype: usertype
							};
						}
					}
				});
				modalInstance.result.then((updateduser) => {
					vm.loadUserData();
					vm.loadUsers(usertype);
				}, () => {
					$log.info('Modal dismissed at: ' + new Date());
				});
			})
		} else {
			UserService.getUserDefaults(usertype).then(response => {
				const userDefaults = response;
				const modalInstance = $uibModal.open({
					backdrop: "static",
					animation: vm.animationsEnabled,
					templateUrl: 'addUserModalContent.html',
					controller: 'AddUserModalCtrl as vm',
					size: "",
					resolve: {
						items: () => {
							return {
								owner: vm.user,
								user: user,
								usertype: usertype,
								userDefaults: userDefaults
							};
						}
					}
				});
				modalInstance.result.then((updateduser) => {
					vm.loadUserData();
					vm.loadUsers(usertype);
				}, () => {
					$log.info('Modal dismissed at: ' + new Date());
				});
			});
		};
	};

	vm.open = function () {
		var modalInstance = $uibModal.open({
		  backdrop: "static",
		  animation: true,
		  ariaLabelledBy: 'modal-title',
		  ariaDescribedBy: 'modal-body',
		  templateUrl: 'customErrorModal.html',
		  controller: 'CustomErrorModalController as vm',
		  size: 'error-modal-class',
		  resolve: {
			items: () => {
				return {
					error: "The User does not have permission to see this page.",
					title: "Error"
				};
			}
		   }
		});
	
		modalInstance.result.then(function () {
			window.location.href = "/app";
		});
	};

	vm.loadUserData = () => {
		return new Promise((resolve, reject) => {
			vm.workingCount += 1;
			UserService.getUserData().then(
				(userData) => {
					if((userData.user.customer_admin && vm.userType == "customer") || (userData.user.administrator && vm.userType == "user")) {
						vm.user = userData.user;
						vm.have_license = (vm.user._license & 0x100) == 0x100;
						vm.have_dc_license = (vm.user._license & 0x400) == 0x400;
						vm.currentDevice = userData.currentDevice;
						vm.workingCount -= 1;
					} 
					else if ((userData.user.customer_admin == undefined && vm.userType == 'customer') || 
						(userData.user.administrator == undefined && vm.userType == 'user')) {
						// if the logged in user doesn`t have customer_admin right, then show alert box instead of direct redirect

						vm.open();
					}
					resolve();
				},
				(response) => {
					console.log("error getting user data:" + response.status);
					vm.workingCount -= 1;
					reject();
				}
			);
		})
	};

	vm.tableSelectionChanged = (user) => {
		console.log("Selection changed ", user);
		vm.selectedUsers = user;
	};

	vm.deleteUsers = () => {
		const UserType = (vm.userType === "customer" ? $translate("USER_TYPE_CUSTOMER").toLowerCase() : $translate("USER_TYPE_USER").toLowerCase());
		MessageService.showMessage({
			title: $translate('MSG_USERS_DELETE_CONFIRM_TITLE', {
				type: vm.userType
			}),
			body: $translate('MSG_USERS_DELETE_CONFIRM', {
				count: vm.selectedUsers.length,
				type: UserType
			})
		}).then(async (ok) => {
			console.log("sending delete request");
			try {
                const response = await UserService.deleteUsers(vm.selectedUsers, vm.userType);                
                if (response.length > 0) {
                    let success = 0;
                    response.map((res) => {
                        if (res.status === 200) {
                            success = success + 1;
                        }
                    });
                    if (success > 0) {
                        ToastService.showMessage($translate('MSG_USERS_DELETE_SUCCESS_BODY',
                            {
                                count: vm.selectedUsers.length,
                                type: UserType
                            }
                        ), "success");
                        vm.loadUsers(vm.userType);
                    }
                }
            } catch (err) {
                let message = "";
                switch (err.status) {
                    case 404:
                        message = 'MSG_USERS_DELETE_BULK_NOT_FOUND_BODY';
                        break;
                    default:
                        message = 'MSG_USERS_DELETE_FAIL_BODY';
                        break;
                }
                ToastService.showMessage($translate(message, {
                        count: vm.selectedUsers.length,
                        types: UserType
                }), "error");
                vm.loadUsers(vm.userType);
            }
		});
	}

	vm.initDataTable = () => {
		console.log("Init datatable");
		vm.actions = [{
			type: "action",
			text: "EDIT_USER",
			icon: "fa fa-pencil-square-o fa-fw",
			action: (user) => vm.addUser(user, vm.userType)
		}, {
			type: "separator"
		}, {
			type: "action",
			text: "DELETE_USER",
			icon: "fa fa-trash-o fa-fw",
			action: (user) => vm.deleteUser(user.userid)
		},
		{
			type: "separator"
		},
		{
            type: "action",
            text: "SUSPEND_USER",
            icon: "fa fa-exclamation-triangle",
            action: (user) => vm.suspendUser(user),
            visible: (entry, user) => vm.have_license && user.account_status == 'active'
		},
		{
            type: "action",
            text: "DISABLE_USER",
            icon: "fa fa-times-circle",
            action: (user) => vm.disableUser(user),
            visible: (entry, user) => vm.have_license && user.account_status == 'active'
		},
		{
            type: "action",
            text: "REACTIVATE_USER",
            icon: "fa fa-check-circle",
            action: (user) => vm.reActivateUser(user),
            visible: (entry, user) => vm.have_license && user.account_status == 'suspended'
		},
		{
            type: "action",
            text: "DISABLE_USER",
            icon: "fa fa-times-circle",
            action: (user) => vm.disableUser(user),
            visible: (entry, user) => vm.have_license && user.account_status == 'suspended'
		},
		{
            type: "action",
            text: "REACTIVATE_USER",
            icon: "fa fa-check-circle",
            action: (user) => vm.reActivateUser(user),
            visible: (entry, user) => vm.have_license && user.account_status == 'disabled'
		},
		{
			type: "action",
			text: "SET_AS_USER",
			icon: "fa fa-arrow-right fa-fw",
			action: (user) => vm.signInForward(user.userid)
		}];
		vm.bulkActions = [{
			type: "action",
			text: "DELETE_USERS",
			icon: "fa fa-trash-o fa-fw",
			action: vm.deleteUsers
		}];
		vm.columns = [{
			key: "userid",
			type: "text",
			title: "USER_ID",
			filterable: true,
			filterField: 'search_id',
			filterType: 'text',
			filterParams: {
				mapper: (x) => x || undefined
			}
		},
		{
			key: "administrator",
			title: "ADMINISTRATOR",
			type: "text",
			filterField: 'search_administrator',
			filterable: true,
			filterType: 'boolean_toggle',
			render: (user) => user.administrator ? $translate("YES") : $translate("NO"),
		}, {
			key: "can_register",
			title: "USER_LIST_REGISTRATION_RIGHTS",
			type: "text",
			filterField: 'search_can_register',
			filterable: true,
			filterType: 'boolean_toggle',
			render: (user) => user.can_register ? $translate("YES") : $translate("NO"),
		}];
	};

	vm.initDataTable();
	vm.loadUserData().then(() => {
		if (vm.have_license) {
			vm.columns.splice(1, 0, {
				key: "account_status",
				title: "STATUS",
				type: "icon_with_tooltip",
				render_icon: 'fa-exclamation-triangle',
				render_icon: (x) => (x.account_status == 'active' ? 'fa fa-check-circle' : x.account_status == 'disabled' ? 'fa fa-times-circle' : 'fa fa-exclamation-triangle'), 
				render_tooltip: x => x.account_status.charAt(0).toUpperCase() + x.account_status.slice(1),
				filterable: false,
				filterField: '',
				filterType: '',
				filterParams: {
					mapper: (x) => x || undefined
				}
			});
			vm.actions.splice(9, 0, {
				type: "separator"
			});
		}
		if (vm.have_dc_license) {
			vm.columns.push({
				key: "num_devices",
				title: "USER_LIST_DEVICE_COUNT",
				type: "text",
				render: (user) => user.num_devices != null ? user.num_devices + "" : "",
			});
		}
	});
};

app.controller("UserListController", ["UserService", "ToastService", "MessageService", "$filter", "$uibModal", "$log", UserListController])
