/*jslint node: true, sloppy: true, bitwise: true, vars: true, eqeq: true, plusplus: true, nomen: true, es5:true */
/*global angular, atob, btoa, google, MessageService.showMessage, constants, WebSocket */

function NavController(MessageService, ToastService, $uibModal, $log, $translate, $filter, $cookies, UserService, $location) {

	var $translateSvc = $translate;
	var $locationSvc = $location.absUrl().split("/")[3];
	var vm = this;
	vm.signedIn = false;
	vm.user = null;
	vm.constants = constants;
	vm.autocomplete = (constants.disable_autocomplete_for_credentials === true) ? "off" : "on";
	vm.isActive = function (viewLocation) {

		return viewLocation === $locationSvc;
	};

	vm.isChildActive = function (value) {
		if (value === '0') {
			if ($locationSvc === 'mydevices_list.html' || $locationSvc === 'manage_groups.html' || $locationSvc === 'data_list.html' || $locationSvc === 'mydevices_map.html' || $locationSvc === 'mydevices_register.html') {
				return true;
			}
		}
		else if (value === '2') {
			if ($locationSvc === 'users_list.html' || $locationSvc === 'customers_list.html') {
				return true;
			}

		} else if (value === '4') {
			if ($locationSvc === 'admin_devices.html' || $locationSvc === 'admin_users.html') {
				return true;
			}
		} else if (value === '6') {
			if($location.absUrl().split("/")[3] === 'app'){
				$locationSvc = $location.absUrl().split("/")[4];
			}
			if ($locationSvc === 'device_profiles' || $locationSvc === 'connectivity_profiles' || $locationSvc === 'service_profiles' || $locationSvc === "roaming_profiles") {
				return true;
			}
		} else {
			return false;
		}
	}

	vm.currentLanguage = $cookies.get('language');

	vm.changeLanguage = function (key) {
		$cookies.put('language', key);
		$translateSvc.use(key);
		vm.currentLanguage = key;
		location.reload();
	};

	var $translate = $filter('translate');

	/* 
		To include a new language, add it to this array/
		Make sure to include BUTTON_LANG_{code} (example BUTTON_LANG_EN)
		to the translation strings for every other language.

		Also the translation need to be registered with the angular
		translate service (see all other translation files for examples).
	*/

	vm.languages = [
		'en'
		// 'de'
	]

	vm.languages = constants.languages || ["en"];

	if (!constants.languages) {
		console.warn("Languages not found in config constants, please specify them explicitly.");
	}

	// Navbar variables
	vm.constants = constants;
	vm.forwardedSignIn = false;
	vm.originalUser = '';

	if (vm.constants.instance_title == null) {
		vm.constants.instance_title = vm.constants.instance_name;
	}

	vm.loadUserData = () => {
		UserService.getUserData().then((userData) => {
			console.log("Loaded user data", userData);
			vm.user = userData.user;
			vm.signedIn = vm.user != null;
			if (vm.signedIn) {
				const users = userData.loginuserid.split('/');
				console.log(users.length);
				if (users.length > 1) {
					vm.originalUser = users[0];
					vm.forwardedSignIn = true;
				}
			}
		}).catch(err => {
			ToastService.showMessage("Failed getting user data", "error");
			console.log(err);
		});
	};

	vm.signInOnDass = (credentials) => {
		console.log("Signing in ", credentials)
		UserService.signInOnDass(credentials).then((response) => {
			console.log("Got answer from service: ", response);
			if (response.user.reset_password === true) {
				window.location.href = "/new_password.html";
			} else
				window.location.href = "/";
		}).catch((response) => {
			ToastService.showMessage($translate('MSG_LOGIN_FAILED_BODY'), "error");
		});
	}

	vm.signInDialog = function () {
		if (vm.signedIn) {
			UserService.signOutOnDass()
				.then(response => {
					console.log("Sign out success", response);
					window.location.href = "/";
				})
				.catch((err) => {
					console.log("Error during sign out ", err);
					window.location.href = "/";
				});
		} else {
			var modalInstance = $uibModal.open({
				backdrop: "static",
				animation: vm.animationsEnabled,
				templateUrl: 'signInModalContent.html',
				controller: 'SignInModalCtrl',
				size: "",
				resolve: {
					items: function () {
						return [constants];
					}
				}
			});

			modalInstance.result.then(function (credentials) {
				vm.signInOnDass(credentials);
			}, function () {
				$log.info('Modal dismissed at: ' + new Date());
			});
		}
	};

	vm.signInForward = (userid) => {
		console.log(`Forwarding sign in to user ${userid}`)
		UserService.signInForward(userid).then(
			response => window.location.href = "/mydevices_list.html"
		).catch(
			error => ToastService.showMessage($translate('MSG_LOGIN_FORWARD_FAILED'), "error")
		);
	};

	vm.userSettings = () => {
		vm.loadUserData();
		setTimeout(() => {
			var modalInstance = $uibModal.open({
				backdrop: "static",
				animation: true,
				templateUrl: 'addUserModalContent.html',
				controller: 'AddUserModalCtrl as vm',
				size: "",
				resolve: {
					items: function () {
						return {
							owner: vm.user,
							user: vm.user,
							editMode: true,
							usertype: vm.user.is_customer ? "customer" : "user"
						};
					}
				}
			});
	
			modalInstance.result.then((updateduser) => {
				// return true;
				vm.loadUserData();
			}, function () {
				$log.info('Modal dismissed at: ' + new Date());
			});
		}, 700);
		
	}

	vm.loadUserData();
};

var app = angular.module('dassUiModule');

app.controller('NavController', ['MessageService', 'ToastService', '$uibModal', '$log', '$translate', '$filter', '$cookies', 'UserService', '$location', NavController]);
