import * as React from "react";
import { MenuItem, Nav, Navbar, NavDropdown, NavItem } from "react-bootstrap";
import * as ReactDOM from "react-dom";
import Cookies from "universal-cookie";
import { strings } from "../../services/Localization";
import { SignInForward, Whoami, SessionInfo, userData } from "../../services/Login";
import { toast } from "../../utils/Toaster";

const cookies = new Cookies();
import { toast as toastlib, ToastContainer } from "react-toastify";
import UserSettingsModal from "./../Users/UserSettingsModal";
import LicenseModal from "./../Users/LicenseModal";
import SoftwareInfoModal from "./../Users/SoftwareInfoModal";
import { getDassInfo } from "../../services/BasicDassQueries";
import { IConstants } from "../../types";
import { getEmailHelpDesk } from "../../services/UserSettings";

declare const constants: IConstants;


interface INavbarState {
    EditMode: boolean;
    ShowUserSettingsModal: boolean;
    forwardedSignIn: boolean;
    language: string;
    numMenusVisible: number;
    originalUser: any;
    profiles: any;
    signedIn: boolean;
    user: any;
    userid_name: string;
    ShowLicenseModal: boolean;
    ShowSoftwareInfoModal: boolean;
    helpdeskMailto: string;
    menu: string;
    click: string;
}

export class NavBar extends React.Component<{}, INavbarState> {
    public portalEl: HTMLElement;
    constructor(props) {
        super(props);
        this.state = {
            EditMode: false,
            ShowUserSettingsModal: false,
            forwardedSignIn: false,
            language: cookies.get("language") ?
                cookies.get("language") : (constants.languages ? constants.languages[0] : ""),
            numMenusVisible: 2,
            originalUser: null,
            profiles: false,
            signedIn: false,
            user: null,
            userid_name: "",
            ShowLicenseModal: false,
            ShowSoftwareInfoModal: false,
            helpdeskMailto: "",
            menu: "",
            click: "",
        };
        this.portalEl = document.createElement("div");
    }

    public async componentWillMount() {
        let signoutOnError = false;
        try {

            const searchObj: any = {};
            const hashObj: any = {};
            if (window.location.search) {
                for (const arg of window.location.search.slice(1).split("&")) {
                    const i = arg.indexOf("=");
                    if (i > 0) {
                        searchObj[arg.slice(0, i)] = decodeURIComponent(arg.slice(i + 1));
                    }
                }
            }

            if (window.location.hash) {
                for (const arg of window.location.hash.slice(1).split("&")) {
                    const i = arg.indexOf("=");
                    if (i > 0) {
                        hashObj[arg.slice(0, i)] = decodeURIComponent(arg.slice(i + 1));
                    }
                }
            }

            if (hashObj.click) {
                this.setState({ click: hashObj.click });
            }

            if (searchObj.code) {
                signoutOnError = true;
                await userData(searchObj);
                history.replaceState(null, null as any, window.location.pathname);
            } else if (hashObj.code) {
                signoutOnError = true;
                await userData(hashObj);
                history.replaceState(null, null as any, ' ');
            }

            const response: any = await Whoami();
            if (response.user) {
                if (response.user._environment.helpdesk_email_template_id) {
                    this.emailHelpdesk();
                }
                this.SessionCheck();
                this.setState({
                    menu: window.location.pathname,
                    userid_name: this.formatName(response.user.userid),
                    originalUser: this.formatName(response.loginuserid.split("/")[0]),
                    profiles: response.user.can_list_device_profile  || response.user.can_list_service_profile ||
                              response.user.can_list_channel_profile || response.user.can_list_connectivity_profile ||
                              response.user.can_list_roaming_profile || response.user.can_list_qos_profile,
                    signedIn: true,
                    user: response.user,
                });
                this.forwardLoginCheck(response);
            }
        } catch (error) {
            console.log(error);
            if (signoutOnError) { this.signOut(); }
        }
    }

    public activateButton(id: string) {
        let retry = 10; // continue to try for 2 seconds
        function tryit() {
            const obj = document.getElementById(id);
            if (obj && obj.click) {
                obj.click();
            } else {
                if (retry-- > 0) {
                    setTimeout(tryit, 200);
                }
            }
        }
        // wait 1 sec the start looking for the  button.
        setTimeout(tryit, 1000);
    }


    public componentDidMount() {
        document.body.insertBefore(this.portalEl, document.body.children[0]);

        this.CheckSystemError();

        if (this.state.click) {
            this.activateButton(this.state.click);
        }
    }

    public componentWillUnmount() {
        document.body.removeChild(this.portalEl);
        clearTimeout(this.SessionCheck());
        clearTimeout(this.CheckSystemErrorTimer);
    }

    public SessionCheck = (time = 1000) => setTimeout(this.GetSessionInfo, time);

    public GetSessionInfo = async () => {
        try {
            const response: any = await SessionInfo();
            const { isActive, maxAge } = await response.json();
            if (!isActive || (maxAge < 10000)) {
                this.signOut();
            } else {
                clearTimeout(this.SessionCheck());
                this.SessionCheck(maxAge - 5000);
            }
        } catch (error) {
            console.log(error);
            this.SessionCheck();
        }
    }


    public systemErrorToast: any;

    public CheckSystemErrorPeriod = 30000;
    public CheckSystemErrorTimer: any;
    public lastToastMessage = "";

    public CheckSystemError = async () => {
        let systemErrorText = "";
        try {
            const dassinfo = (await getDassInfo()).data;
            systemErrorText = dassinfo.systemErrorTxt;
        } catch (e) {
            systemErrorText = "System unreachable";
        }

        if ((this.systemErrorToast && toastlib.isActive(this.systemErrorToast)) &&
            (this.lastToastMessage !== systemErrorText || !systemErrorText)) {
            // Toast is open but message is not the same or the alarm is over, we close the toast
            toastlib.dismiss(this.systemErrorToast);
            this.systemErrorToast = null;
        }

        if (systemErrorText) {
            if (this.systemErrorToast == null || !toastlib.isActive(this.systemErrorToast)) {
                this.lastToastMessage = systemErrorText;
                this.systemErrorToast = toast.error(systemErrorText, { position: "top-right" });
            }
        }

        if (systemErrorText != null) {
            // we only continue to check with the timer if the systemErrorText is non null.
            // if it is null it means the field is not pressent and we can stop checking.
            this.CheckSystemErrorTimer = setTimeout(() => this.CheckSystemError(), this.CheckSystemErrorPeriod);
        }
    }

    public forwardLoginCheck = async (userData) => {
        try {
            if (this.state.signedIn) {
                const users = userData.loginuserid.split("/");
                if (users.length > 1) {
                    this.setState({forwardedSignIn: true });
                } else {
                    this.setState({ forwardedSignIn: false });
                }
            }
        } catch (error) {
            toast.error(error);
        }
    }

    public UserSettings = async () => {
        this.setState({
            ShowUserSettingsModal: true,
        });
        // try {

        // } catch (error) {

        // }
    }

    public ToggleSoftwareInfoModal = () => {
        this.setState({
            ShowSoftwareInfoModal: !this.state.ShowSoftwareInfoModal
        });
    }

    public signInForward = async () => {
        try {
            await SignInForward(undefined);
            localStorage.removeItem("currentGroup");
            window.location.href = constants.landing_page_after_signin || "app/";
        } catch (error) {
            console.log(error);
            toast.error(strings.CAN_NOT_RETURN_TO_PARENT);
        }
    }

    public signOut = async () => {
        localStorage.removeItem("currentGroup");
        window.location.href = '/app/signout';
    }

    public changeLanguage = (event) => {
        cookies.set("language", event.target.id, { path: "/" });
        location.reload();
    }

    public emailHelpdesk = async () => {
        const set = await getEmailHelpDesk();
        this.setState({ helpdeskMailto: set.mailto });
    }


    public formatName = (userid: string) => {
        const IsEmail = /@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (IsEmail.test(userid)) {
            return userid.substring(0, userid.lastIndexOf("@"));
        } else {
            return userid;
        }
    }

    public ToggleUserSettingsModal = () => {
        this.setState({
            ShowUserSettingsModal: !this.state.ShowUserSettingsModal,
        });
    }

    public checkFlag = (license: number, bit: number) => {
        let {user} = this.state;
        if (!user || !user.can_register) {
            user = {
                can_register: false,
            };
        }
        if (license && bit) {
            switch (bit) {
                case 1:
                    // tslint:disable-next-line:no-bitwise
                    return ((license & 0x1) !== 0);
                case 2:
                    return ((license & 0x200) !== 0);
                case 4:
                    // tslint:disable-next-line:no-bitwise
                    return (((license & 0x4) !== 0) && (user.can_register) &&
                    // tslint:disable-next-line:no-bitwise
                    (((license & 0x1) !== 0) || ((license & 0x2) !== 0)));
                case 5:
                    // tslint:disable-next-line:no-bitwise
                    return ((license & 0x8) !== 0);
                case 6:
                    // tslint:disable-next-line:no-bitwise
                    return ((license & 0x10) !== 0);
                default:
                    return false;
            }
        } else {
            return false;
        }
    }

    ToggleShowLicenseModal = () => {
        this.setState({
            ShowLicenseModal: !this.state.ShowLicenseModal
        });
    }

    public ShowLicense = () => {
        this.setState({
            ShowLicenseModal: true,
        });
    }

    public render() {

        let key = 0;
        function mkey() { key = Math.floor(key) + 1; return key; }
        function skey() { key += .1; return key; }
        const userenv = (this.state.user || {})._environment || {};

        const portalled = ReactDOM.createPortal(
            (<ToastContainer toastClassName="toast-container"></ToastContainer>),
            this.portalEl,
        );
        return (
            <div>
                {portalled}
                <Navbar fluid className="top_header_desktop">
                    {constants.leftSideLogo ? <a href="/app">
                        <img src={constants.left_logo_file} className="img-left-logo" />
                    </a> : <div className="fake_left_logo"></div>}
                    <div className="top_bar_name_instance_holder">
                        {constants.app_name &&
                            <div className="padding_left_min app_name_holder">
                                {constants.app_name}
                            </div>
                        }
                        {constants.app_name &&
                            <div className="padding_left_min separator_holder">
                                |
                            </div>
                        }
                        {constants.instance_title &&
                            <div className="padding_left_min instance_holder">
                                {constants.instance_title.substring(0, 29)}
                            </div>
                        }
                        {this.state.user && this.state.user.account_status === "suspended" &&
                            <div className="padding_left_min instance_holder">
                                Warning, this account is currently suspended
                            </div>
                        }
                    </div>

                    <Nav pullRight className="top_header">
                        {constants.languages && constants.languages.length > 1 && (
                        <NavDropdown
                            eventKey={8}
                            title={(<span className="pull-left margin_lang">
                                <img src={`/theme/images/${this.state.language}_flag.png`} className="img_language" />
                                {this.state.language === "en" ? strings.BUTTON_LANG_EN : strings.BUTTON_LANG_ZH}
                            </span>)}
                            id="basic-nav-dropdown"
                            className="shadow_dropdown lang_dropdown">
                            <MenuItem
                                eventKey={8.1}
                                onClick={this.changeLanguage}
                                id="en"
                            >
                                <img src={`/theme/images/en_flag.png`} className="img_language" />
                                {strings.BUTTON_LANG_EN}
                            </MenuItem>
                            <MenuItem
                                eventKey={8.2}
                                onClick={this.changeLanguage}
                                id="zh"
                            >
                                <img src={`/theme/images/zh_flag.png`} className="img_language" />
                                {strings.BUTTON_LANG_ZH}
                            </MenuItem>
                        </NavDropdown>)}
                        {this.state.signedIn && (
                            <NavDropdown
                                eventKey={1}
                                title={this.state.user && this.state.forwardedSignIn ? (
                                    <span className="pull-left margin_lang">
                                        <b id="user_dropdown_item">{this.state.userid_name}</b>
                                        <img
                                            src={"/theme/images/forward_user.png"} className="user_icon" id="user_icon1"
                                        />
                                    </span>) : this.state.signedIn ? (
                                    <span className="pull-left">
                                    <b id="user_dropdown_item">{this.state.userid_name}</b>
                                    {(this.state.user.administrator || this.state.user.customer_admin) ?
                                        <img
                                            src={"/theme/images/admin_user.png"} className="user_icon" id="user_icon2"
                                        /> :
                                        <img src={"/theme/images/user.png"} className="user_icon" id="user_icon2" />
                                    }
                                </span>) : ""}
                                id="basic-nav-dropdown"
                                className="shadow_dropdown width_mobile">
                                {this.state.signedIn && !this.state.forwardedSignIn && (this.state.user.hasOwnProperty("is_organisation") ? 
                                    <MenuItem>
                                        <img src={`/theme/images/user_customer.png`} className="img_user" />
                                        {strings.ADMIN_TYPE_ORGANIZATION}
                                    </MenuItem> : this.state.user.customer_admin ?
                                    <MenuItem>
                                        <img src={`/theme/images/user_customer.png`} className="img_user" />
                                        {strings.ADMIN_TYPE_CUSTOMER}
                                    </MenuItem> : this.state.user.administrator ?
                                    <MenuItem>
                                        <img src={`/theme/images/user_customer.png`} className="img_user" />
                                        {strings.ADMIN_TYPE_USER}
                                    </MenuItem> : this.state.user.is_customer ?
                                    <MenuItem>
                                    <img src={`/theme/images/user_customer.png`} className="img_user" />
                                    {strings.TYPE_CUSTOMER}
                                </MenuItem> :
                                    <MenuItem>
                                        <img src={`/theme/images/user_customer.png`} className="img_user" />
                                        {strings.TYPE_USER}
                                    </MenuItem>
                                )}
                                {this.state.user && this.state.forwardedSignIn &&
                                    <MenuItem className="align-left" eventKey={1.1} >
                                        <img src={`/theme/images/user_customer.png`} className="img_user_forward" />
                                        <div className="forwarded_user">
                                            {strings.NAV_SIGNED_AS} {this.state.userid_name} <br></br>
                                            {strings.formatString(strings.VIA, {
                                                originalUser: this.state.originalUser,
                                            })}
                                            <br></br>
                                        ({this.state.user.customer_admin ?
                                            strings.ADMIN_TYPE_CUSTOMER : strings.ADMIN_TYPE_USER
                                        })
                                    </div>
                                </MenuItem>}
                                {this.state.signedIn &&
                                    <MenuItem
                                        className="align-left user-settings"
                                        eventKey={1.2}
                                        onClick={this.UserSettings}
                                    >
                                        <img src={`/theme/images/settings.png`} className="img_dropdown" />
                                        {strings.NAV_USER_SETTINGS}
                                    </MenuItem>
                                }
                                {this.state.user && this.state.forwardedSignIn &&
                                    <MenuItem className="align-left" eventKey={1.3} onClick={this.signInForward}>
                                        <img
                                            src={"/theme/images/forward_user_dark.png"}
                                            className="user_icon forward_login_icon" />
                                        {strings.formatString(
                                            strings.NAV_RETURN_TO,
                                            { originalUser: this.state.originalUser },
                                        )}
                                    </MenuItem>
                                }
                                <MenuItem className="align-left" divider />
                                {userenv.eula_id &&
                                    <>
                                        <MenuItem
                                            className="align-left"
                                            eventKey={1.5}
                                            onClick={this.ToggleSoftwareInfoModal}
                                        >
                                            <i className="fa fa-info-circle software_info_icon" aria-hidden="true"></i>
                                            {strings.NAV_SOFTWARE_INFO}
                                        </MenuItem>
                                        <MenuItem className="align-left" divider />
                                    </>
                                }
                                {this.state.signedIn &&
                                    <MenuItem
                                        className="align-left user-settings"
                                        eventKey={1.4}
                                        onClick={this.signOut}
                                    >
                                        <img src={`/theme/images/sign-out.png`} className="img_sign_out" />
                                        {strings.SIGN_OUT}
                                    </MenuItem>
                                }
                            </NavDropdown>
                        )}
                        {!this.state.signedIn &&
                            <NavItem className="align-left sign-in" href="/app/signin">
                                {strings.SIGN_IN}
                            </NavItem>
                        }
                        {constants.rightSideLogo &&
                            <NavItem className="right_logo">
                                <div className="img-right-logo">
                                    <img src={constants.right_logo_file} className="img-right-logo-img" />
                                </div>
                            </NavItem>
                        }
                    </Nav>
                </Navbar>

                <Navbar fluid className="main_header_desktop">
                    {this.state.signedIn && <Navbar.Header>
                        <Navbar.Toggle className="pull-left" />
                    </Navbar.Header>}
                    {this.state.signedIn && <Navbar.Collapse className="navbar_padding ">
                        <Nav className={`padding_menu main_header_desktop selected_language_${this.state.language}`}>


                            {constants.dassboardEnabled &&
                                <NavItem
                                    eventKey={mkey()}
                                    href="/mydashboard.html"
                                    className={
                                        this.state.menu === "/mydashboard.html" ?
                                            `active_menu_${this.state.language}_ active_menu_button` : "hover_item"
                                    }
                                >
                                    {strings.NAV_DASHBOARD}
                                </NavItem>
                            }

                            {/*
                              *- DEVICE MENU ---------------------------------------------------------------------
                              */}

                            {!this.state.user.hasOwnProperty("is_organisation") && <NavDropdown
                                eventKey={mkey()}
                                title={strings.NAV_DEVICES_ITEM}
                                id="basic-nav-dropdown"
                                className={
                                    this.state.menu === "/mydevices_list.html" ||
                                    this.state.menu === "/data_list.html" ||
                                    this.state.menu === "/app/network_map" ||
                                    this.state.menu === "/mydevices_register.html" ?
                                        `active_menu_${this.state.language}_ active_menu_dropdown` : "hover_"
                                }
                            >
                                <MenuItem
                                    eventKey={skey()}
                                    href="/mydevices_list.html"
                                    className={
                                        this.state.menu === "/mydevices_list.html" ?
                                            `active_menu_${this.state.language} active_menu_item` : "hover_menu"
                                    }
                                >
                                    {strings.NAV_DEVICES_LIST_DEVICES}
                                </MenuItem>

                                {this.state.user.account_status === "active" && <MenuItem
                                    eventKey={skey()}
                                    href="/data_list.html"
                                    className={
                                        this.state.menu === "/data_list.html" ?
                                            `active_menu_${this.state.language} active_menu_item` : "hover_menu"
                                    }
                                >
                                    {strings.NAV_DATA_SHOW_PACKETS}
                                </MenuItem>}

                                {this.state.user.account_status === "active" && <MenuItem
                                    eventKey={skey()}
                                    href="/app/network_map"
                                    className={
                                        this.state.menu === "/app/network_map" ?
                                            `active_menu_${this.state.language} active_menu_item` : "hover_menu"
                                    }
                                >
                                    {strings.NAV_DEVICES_MAP}
                                </MenuItem>}

                                {this.checkFlag(this.state.user._license, 4) && this.state.user.account_status === "active" &&
                                    <MenuItem
                                        eventKey={skey()}
                                        href="/mydevices_register.html"
                                        className={
                                            this.state.menu === "/mydevices_register.html" ?
                                            `active_menu_${this.state.language} active_menu_item` : "hover_menu"
                                        }
                                    >
                                        {strings.NAV_DEVICES_BATCH}
                                    </MenuItem>
                                }
                            </NavDropdown> }


                            {/*
                              *- GROUPS MENU ---------------------------------------------------------------------------
                              */}

                            {!this.state.user.hasOwnProperty("is_organisation") && this.state.user.account_status === "active" && <NavDropdown
                                eventKey={mkey()}
                                title={strings.NAV_MANAGE_GROUPS}
                                id="basic-nav-dropdown"
                                className={
                                    this.state.menu === "/manage_groups.html" ||
                                    this.state.menu === "/multicast_activity.html" ?
                                        `active_menu_${this.state.language}_ active_menu_dropdown` : "hover_"
                                }
                            >

                                <MenuItem
                                    eventKey={skey()}
                                    href="/manage_groups.html"
                                    className={
                                        this.state.menu === "/manage_groups.html" ?
                                            `active_menu_${this.state.language} active_menu_item` : "hover_menu"
                                    }
                                >
                                    {strings.NAV_MANAGE_GROUPS_TAB}
                                </MenuItem>
                                <MenuItem
                                    eventKey={skey()}
                                    href="/multicast_activity.html"
                                    className={
                                        this.state.menu === "/multicast_activity.html" ?
                                        `active_menu_${this.state.language} active_menu_item` : "hover_menu"
                                    }
                                >
                                    {strings.GROUP_ACTIVITY_TITLE}
                                </MenuItem>
                            </NavDropdown> }

                            {/*
                              *- APPLICATIONS MENU ------------------------------------------------------------
                              */}

                            {!this.state.user.hasOwnProperty("is_organisation") && this.state.user.account_status === "active" && <NavDropdown
                                eventKey={mkey()}
                                title={strings.NAV_APPLICATIONS_ITEM}
                                id="basic-nav-dropdown"
                                className={
                                    this.state.menu === "/my_apps.html" ||
                                    this.state.menu === "/app/active_connections" ||
                                    this.state.menu === "/app/event_log" ?
                                        `active_menu_${this.state.language}_ active_menu_dropdown` : "hover_"
                                }
                            >
                                <MenuItem
                                    eventKey={skey()}
                                    href="/my_apps.html"
                                    className={
                                        this.state.menu === "/my_apps.html" ?
                                            `active_menu_${this.state.language}_ active_menu_item` : "hover_menu"
                                    }
                                >
                                    {strings.MANAGE_APPLICATIONS_NAV_TITLE}
                                </MenuItem>
                                {this.checkFlag(this.state.user._license, 5) && (
                                    <MenuItem
                                        eventKey={skey()}
                                        href="/app/active_connections"
                                        className={
                                            this.state.menu === "/app/active_connections" ?
                                                `active_menu_${this.state.language}_ active_menu_item` : "hover_menu"
                                        }
                                    >
                                        {strings.ACTIVE_CONNECTIONS_NAV_TITLE}
                                    </MenuItem>
                                )}
                                {this.checkFlag(this.state.user._license, 6) && this.state.user.can_access_logs && (
                                    <MenuItem
                                        eventKey={skey()}
                                        href="/app/event_log"
                                        className={
                                            this.state.menu === "/app/event_log" ?
                                                `active_menu_${this.state.language}_ active_menu_item` : "hover_menu"
                                        }
                                    >
                                        {strings.EVENT_LOG_NAV_TITLE}
                                    </MenuItem>
                                )}
                            </NavDropdown> }

                            {/*
                              *- GATEWAYS MENU ------------------------------------------------------------
                              */}

                            {this.state.user.can_own_gtw && !this.state.user.hasOwnProperty("is_organisation") && !this.state.user.can_access_gtw_trace &&
                                <NavItem
                                    eventKey={mkey()}
                                    href="/mygateways_list.html"
                                    className={
                                        this.state.menu === "/mygateways_list.html" ?
                                            `active_menu_${this.state.language}_ active_menu_button` : "hover_item"
                                    }
                                >
                                    {strings.NAV_GATEWAYS_ITEM}
                                </NavItem>
                            }

                            { this.state.user.can_access_gtw_trace &&
                                <NavDropdown
                                    eventKey={mkey()}
                                    title={strings.NAV_GATEWAYS_ITEM}
                                    id="basic-nav-dropdown"
                                    className={this.state.menu === "/mygateways_list.html" ?
                                        `active_menu_${this.state.language}_ active_menu_dropdown` : "hover_"}
                                >

                                    {this.state.user.can_own_gtw && !this.state.user.hasOwnProperty("is_organisation") && (
                                        <MenuItem
                                            eventKey={skey()}
                                            href="/mygateways_list.html"
                                            className={this.state.menu === "/mygateways_list.html" ?
                                                `active_menu_${this.state.language} active_menu_item` : "hover_menu"}
                                        >
                                            {strings.NAV_GATEWAYS_LIST_GATEWAYS}
                                        </MenuItem>
                                    )}

                                    {this.state.user.can_access_gtw_trace && (
                                        <MenuItem
                                            eventKey={skey()}
                                            href="/tfw1/trace" target="_blank"
                                            className="hover_menu"
                                        >
                                            {strings.NAV_GATEWAY_TRACE}
                                        </MenuItem>
                                    )}

                                </NavDropdown>
                            }



                            {/*
                              *- PROFILES MENU ------------------------------------------------------------
                              */}

                            {constants.enable_profiles && this.state.profiles && !this.state.user.hasOwnProperty("is_organisation") &&
                               this.state.user.account_status === "active" && (
                                <NavDropdown
                                    eventKey={mkey()}
                                    title={strings.NAV_PROFILES_ITEM}
                                    id="basic-nav-dropdown"
                                    className={this.state.menu === "/app/device_profiles" ||
                                        this.state.menu === "/app/service_profiles" ||
                                        this.state.menu === "/app/connectivity_profiles" ||
                                        this.state.menu === "/app/roaming_profiles" ||
                                        this.state.menu === "/app/qos_profiles" ||
                                        this.state.menu === "/app/channel_profiles" ?
                                        `active_menu_${this.state.language}_ active_menu_dropdown` : "hover_"}
                                >
                                    {this.state.user.can_list_device_profile && (
                                        <MenuItem
                                            eventKey={skey()}
                                            href="/app/device_profiles"
                                            className={this.state.menu === "/app/device_profiles" ?
                                                `active_menu_${this.state.language} active_menu_item` : "hover_menu"}
                                        >
                                            {strings.NAV_DEVICE_PROFILES}
                                        </MenuItem>
                                    )}
                                    {this.state.user.can_list_service_profile && (
                                        <MenuItem
                                            eventKey={skey()}
                                            href="/app/service_profiles"
                                            className={this.state.menu === "/app/service_profiles" ?
                                                `active_menu_${this.state.language} active_menu_item` : "hover_menu"}
                                        >
                                            {strings.NAV_SERVICE_PROFILES}
                                        </MenuItem>
                                    )}
                                    {this.state.user.can_list_connectivity_profile && (
                                        <MenuItem
                                            eventKey={skey()}
                                            href="/app/connectivity_profiles"
                                            className={this.state.menu === "/app/connectivity_profiles" ?
                                                `active_menu_${this.state.language} active_menu_item` : "hover_menu"}
                                        >
                                            {strings.NAV_CONNECTIVITY_PROFILES}
                                        </MenuItem>
                                    )}
                                    {this.state.user.can_list_roaming_profile && (
                                        <MenuItem
                                            eventKey={skey()}
                                            href="/app/roaming_profiles"
                                            className={this.state.menu === "/app/roaming_profiles" ?
                                                `active_menu_${this.state.language} active_menu_item` : "hover_menu"}
                                        >
                                            {strings.NAV_ROAMING_PROFILES}
                                        </MenuItem>
                                    )}
                                    {this.state.user.can_list_qos_profile && (
                                        <MenuItem
                                            eventKey={skey()}
                                            href="/app/qos_profiles"
                                            className={this.state.menu === "/app/qos_profiles" ?
                                                `active_menu_${this.state.language} active_menu_item` : "hover_menu"}
                                        >
                                            {strings.NAV_QOS_PROFILES}
                                        </MenuItem>
                                    )}
                                    {this.state.user.can_list_channel_profile && (
                                        <MenuItem
                                            eventKey={skey()}
                                            href="/app/channel_profiles"
                                            className={this.state.menu === "/app/channel_profiles" ?
                                                `active_menu_${this.state.language} active_menu_item` : "hover_menu"}
                                        >
                                            {strings.NAV_CHANNEL_PROFILES}
                                        </MenuItem>
                                    )}
                                </NavDropdown>
                            )}


                            {/*
                              *- FIND MENU ------------------------------------------------------------
                              */}

                            {this.state.user && (this.state.user.administrator || this.state.user.customer_admin || this.state.user.org_admin) &&
                                <NavDropdown
                                    eventKey={mkey()}
                                    title={strings.FIND}
                                    id="basic-nav-dropdown"
                                    className={
                                        this.state.menu === "/admin_devices.html" ||
                                        this.state.menu === "/admin_users.html" ?
                                            `active_menu_${this.state.language}_ active_menu_dropdown` : "hover_"
                                    }
                                >
                                    {this.state.user.administrator &&
                                        <MenuItem
                                            eventKey={skey()}
                                            href="/admin_devices.html"
                                            className={
                                                this.state.menu === "/admin_devices.html" ?
                                                    `active_menu_${this.state.language} active_menu_item` : "hover_menu"
                                            }
                                        >
                                            {strings.NAV_ADMIN_DEVICES}
                                        </MenuItem>}
                                    {(this.state.user.customer_admin || this.state.user.org_admin) &&
                                        <MenuItem
                                            eventKey={skey()}
                                            href="/admin_users.html"
                                            className={
                                                this.state.menu === "/admin_users.html" ?
                                                    `active_menu_${this.state.language} active_menu_item` : "hover_menu"
                                            }
                                        >
                                            {strings.NAV_ADMIN_USERS}
                                        </MenuItem>}
                            </NavDropdown>}

                            {/*
                              *- ACCOUNTS MENU ------------------------------------------------------------
                              */}

                            {this.state.user && (this.state.user.administrator || this.state.user.customer_admin || this.state.user.org_admin) &&
                                <NavDropdown
                                    eventKey={mkey()}
                                    title={strings.ACCOUNTS}
                                    id="basic-nav-dropdown"
                                    className={
                                        this.state.menu === "/users_list.html" ||
                                        this.state.menu === "/customers_list.html" ||
                                        this.state.menu === "/organization_list.html" ?
                                            `active_menu_${this.state.language}_ active_menu_dropdown` : "hover_"
                                    }
                                >

                                    {this.state.user.administrator && !this.state.user.hasOwnProperty("is_organisation") &&
                                        <MenuItem
                                            eventKey={skey()}
                                            href="/users_list.html"
                                            className={
                                                this.state.menu === "/users_list.html" ?
                                                    `active_menu_${this.state.language} active_menu_item` : "hover_menu"
                                            }
                                        >
                                            {strings.NAV_USERS_LIST_USERS}
                                        </MenuItem>}
                                    {this.state.user.customer_admin &&
                                        <MenuItem
                                            eventKey={skey()}
                                            href="/customers_list.html"
                                            className={
                                                this.state.menu === "/customers_list.html" ?
                                                    `active_menu_${this.state.language} active_menu_item` : "hover_menu"
                                            }
                                        >
                                            {strings.NAV_CUSTOMERS_LIST_CUSTOMERS}
                                        </MenuItem>
                                    }
                                    {this.state.user.org_admin &&
                                        <MenuItem
                                            eventKey={skey()}
                                            href="/organization_list.html"
                                            className={
                                                this.state.menu === "/organization_list.html" ?
                                                    `active_menu_${this.state.language} active_menu_item` : "hover_menu"
                                            }
                                        >
                                            {strings.NAV_ORGANIZATION_LIST_USERS}
                                        </MenuItem>}
                                </NavDropdown>
                            }

                            {/*
                              *- SYSTEM MENU ------------------------------------------------------------
                              */}

                            {this.state.user.sys_admin  && (
                                            (this.state.user && this.checkFlag(this.state.user._license, 2))
                                            || constants.loralogsEnabled
                                            || constants.svrlogsEnabled) &&

                                <NavDropdown
                                    eventKey={mkey()}
                                    title={strings.NAV_SYSTEM}
                                    id="basic-nav-dropdown"
                                    className="hover_"
                                >
                                    {(this.state.user && this.state.user.sys_admin && this.checkFlag(this.state.user._license, 2)) &&
                                        <MenuItem
                                            className="align-left"
                                            eventKey={skey()}
                                            onClick={this.ShowLicense}
                                        >
                                            {strings.PRODUCT_LICENSE_TITLE}
                                        </MenuItem>
                                    }

                                    {constants.loralogsEnabled && <MenuItem
                                        eventKey={skey()}
                                        href="/rawlog.log"
                                        className="align-left"
                                    >
                                        {strings.LORA_UPLINK_LOGS}
                                    </MenuItem>}

                                    <MenuItem divider={true} />

                                    {constants.svrlogsEnabled && <MenuItem
                                        eventKey={skey()}
                                        href="/svrlogs.tar"
                                        className="align-left"
                                    >
                                        {strings.GET_SERVER_LOGS}
                                    </MenuItem>}

                                    <MenuItem
                                        eventKey={skey()}
                                        href=""
                                        className="align-left"
                                        disabled={true}
                                    >
                                        Build: {constants.buildtime}
                                    </MenuItem>

                                </NavDropdown>
                            }


                            {/*
                              *- HELP MENU ------------------------------------------------------------
                              */}

                            {(userenv.getting_started_link || userenv.help_page_link || userenv.helpdesk_email_template_id || userenv.swagger_ui) &&
                                <NavDropdown
                                    eventKey={mkey()}
                                    title={strings.NAV_HELP}
                                    id="basic-nav-dropdown"
                                    className={
                                        this.state.menu === "/app/swagger" ?
                                            `active_menu_${this.state.language}_ active_menu_dropdown` : "hover_"
                                    }
                                >

                                    {userenv.help_page_link &&
                                        <MenuItem
                                            className="align-left"
                                            eventKey={skey()}
                                            href={userenv.help_page_link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {strings.NAV_HELP_PAGE}
                                        </MenuItem>
                                    }
                                    {userenv.getting_started_link &&
                                        <MenuItem
                                            className="align-left"
                                            eventKey={skey()}
                                            href={userenv.getting_started_link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {strings.NAV_HELP_GETTING_STARTED}
                                        </MenuItem>
                                    }

                                    {userenv.swagger_ui &&
                                        <MenuItem
                                            eventKey={skey()}
                                            href="/app/swagger"
                                            className={this.state.menu === "/app/swagger" ?
                                                `active_menu_${this.state.language} active_menu_item` : "hover_menu"}
                                        >
                                            {strings.NAV_HELP_SWAGGER}
                                        </MenuItem>
                                    }

                                    {(userenv.getting_started_link || userenv.help_page_link) && userenv.helpdesk_email_template_id && <MenuItem divider={true} />}

                                    {userenv.helpdesk_email_template_id && <MenuItem
                                        eventKey={skey()}
                                        href={this.state.helpdeskMailto}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="align-left"
                                    >
                                        {strings.NAV_HELP_SUPPORT_EMAIL}
                                    </MenuItem>}

                                </NavDropdown>
                            }




                        </Nav>
                    </Navbar.Collapse>}
                </Navbar>
                {this.state.ShowUserSettingsModal === true && (
                    <UserSettingsModal
                        ShowModal={this.state.ShowUserSettingsModal}
                        ToggleModal={this.ToggleUserSettingsModal}
                        User={this.state.user}
                        EditMode={this.state.EditMode}
                        ModalTitle={strings.NAV_USER_SETTINGS}
                    />
                )}
                {this.state.ShowLicenseModal === true && (
                    <LicenseModal
                        ShowModal={this.state.ShowLicenseModal}
                        ToggleModal={this.ToggleShowLicenseModal}
                    />
                )}
                {this.state.ShowSoftwareInfoModal === true && (
                    <SoftwareInfoModal
                        ShowModal={this.state.ShowSoftwareInfoModal}
                        ToggleModal={this.ToggleSoftwareInfoModal}
                    />
                )}
            </div>
        );

    }
}
