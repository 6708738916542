import * as moment from "moment";
import "moment/locale/en-ca";
import "moment/locale/en-gb";
export const LocaleDate = () => {
    moment.locale("en-gb");
    let DateFormat = "DD/MM/YYYY";
    let DateTimeFormat = "DD/MM/YYYY HH:mm:ss";
    let TimeFormat = "HH:mm:ss";
    let Time24Hour = true;
    const offset = moment().utcOffset() / 60;
    if (offset <= -4 && offset >= -10) {
        moment.locale("en-ca");
        DateFormat = "MM/DD/YYYY";
        DateTimeFormat = "MM/DD/YYYY h:mm:ss A";
        TimeFormat = "h:mm:ss A";
        Time24Hour = false;
    }

    return ({
        DateFormat, DateTimeFormat, Time24Hour, TimeFormat,
    });
};
