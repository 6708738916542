import * as React from "react";
import * as ReactDOM from "react-dom";
import { EnterpriseAddGateway } from "../components/Gateways/EnterpriseAddGateway";
declare const angular;

export class EnterpriceAddGatewayModal {

  public showEnterpriseAddModalModal() {
    const enterpriseAddGatewayModal = document.getElementById("install-gateway-button") as HTMLAnchorElement;
    const controller = angular.element(enterpriseAddGatewayModal).controller();
    const modalRoot = document.getElementById("reactModalRootEGateway") as HTMLElement;
    ReactDOM.unmountComponentAtNode(modalRoot);
    ReactDOM.render(
      <EnterpriseAddGateway Device={controller} />,
      modalRoot,
    );
  }

}
