(function (angular) {
    var app = angular.module('dassUiModule');
    app.factory('MulticastService', MulticastServiceFn);

    MulticastServiceFn.$inject = ['$http', "$q"];

    function MulticastServiceFn($http, $q) {
        function getGroupMulticastsImpl(groupId) {
            return $http.get("/uiapi/rest/multicast/" + groupId + "/payloads/dl");
        }

        function deleteGroupPayloadImpl(groupId, packetId) {
            return $http.delete("/uiapi/rest/multicast/" + groupId + "/payloads/dl/" + packetId, null);
        }

        function deleteGroupPayloadsImpl(groupId, packetIds) {
            var promises = packetIds.map(packet => deleteGroupPayloadImpl(groupId, packet.id));
            return Promise.all(promises);
        }

        return {
            getGroupMulticasts: getGroupMulticastsImpl,
            deleteGroupPayload: deleteGroupPayloadImpl,
            deleteGroupPayloads: deleteGroupPayloadsImpl
        }
    }
})(angular);
