(function (angular) {

    var app = angular.module('dassUiModule');
    app.factory('PayloadService', PayloadServiceFn);

    PayloadServiceFn.$inject = ['$http', "$q", "$filter"];

    function PayloadServiceFn($http, $q, $filter) {

        function sendDlPayloadImpl(json) {

            const {deveui, fcnt, confirmed, port, data} = json
            const args = "port=" + port + (fcnt != null ? "&fcnt=" + json.fcnt : "") + (confirmed == null ? "" : confirmed ? "&confirmed=true" : "&confirmed=false");

            return $http.post("/uiapi/rest/nodes/" + deveui + "/payloads/dl?" + args, data);
        }

        function sendUlPayloadImpl(data) {
            return $http.post('/send_ulpayload', data);
        }

        function deletePayloadImpl(deveui, packet) {
            console.log("Delete impl");
            return $http.delete("/uiapi/rest/nodes/" + deveui + "/payloads/" + ((packet.rssi !== undefined) ? 'ul/' : 'dl/') + packet.id, null);
        }

        function deletePayloadsImpl(deveui, packets) {
            var promises = packets.map(packet => deletePayloadImpl(deveui, packet));
            return Promise.all(promises);
        }
        function getPayloadsImpl (device) {
            var defer = $q.defer();

            $http.get("/uiapi/rest/nodes" + device + "/payloads/all").then(
                function (response) {
                    defer.resolve(response.data);
                },
                function (response) {
                    defer.reject(response);
                }
            );
            return defer.promise;
        }

        function rePushDevicePayloadULImpl (device, payload) {
            return $http.post("/uiapi/rest/pushmode/test/payload_ul/" + device + "/" + payload);
        }
        return {
            sendDlPayload: sendDlPayloadImpl,
            sendUlPayload: sendUlPayloadImpl,
            deletePayload: deletePayloadImpl,
            deletePayloads: deletePayloadsImpl,
            getPayloads: getPayloadsImpl,
            rePushDevicePayloadUL: rePushDevicePayloadULImpl
        }
    }
})(angular);
