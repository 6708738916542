(function (angular) {
    var app = angular.module('dassUiModule');
    app.factory('UserService', UserServiceFn);

    UserServiceFn.$inject = ['$http', "$q"];

    const typeToUrlMap = {
        customer: "/uiapi/rest/customers",
        organization: "/uiapi/rest/organisations",
        user: "/uiapi/rest/users",
    }


    function UserServiceFn($http, $q) {
        // Get individual gateway data
        function getUserDataImpl() {
            var defer = $q.defer();
            $http.get('/whoami/', {headers:{'Cache-Control': 'no-cache'}}).then(function (response) {

                const allowed = [
                    "/forgot_password.html", "/forgot_username.html", "/email_username.html",
                    "/password_reset_instructions.html",
                    "/reset_password.html", "/password_successfully_changed.html",
                    "/new_password.html", "/welcome.html", "/initial_password.html"
                ];

                if (!response.data.user &&
                    (window.location.pathname == '/admin_users.html' ||
                    window.location.pathname == '/data_list.html' ||
                    window.location.pathname == '/mygateways_list.html' ||
                    window.location.pathname == '/multicast_activity.html' ||
                    window.location.pathname == '/mydevices_register.html' ||
                    window.location.pathname == '/admin_devices.html' ||
                    window.location.pathname == '/users_list.html' ||
                    window.location.pathname == '/mydevices_list.html')) {

                    window.location.href = '/app/signin';
                }
                else if (!response.data.user && allowed.indexOf(window.location.pathname) < 0) {
                    window.location.href = 'welcome.html';
                }

                defer.resolve(response.data);
            }, function (response) {
                defer.reject(response);
            });
            return defer.promise;
        }

        function getUserImpl(id, usertype) {
            var defer = $q.defer();
            url = `${typeToUrlMap[usertype]}/${id}?all=true`;

            $http.get(url).then(function (response) {
                defer.resolve(response.data);
            }, function (response) {
                defer.reject(response);
            });

            return defer.promise;
        }
        function getUsersImpl(type) {
            var defer = $q.defer();
            $http.get(typeToUrlMap[type]).then(function (response) {
                defer.resolve(response.data);
            }, function (response) {
                defer.reject(response);
            });
            return defer.promise;
        }
        function getUserDefaultsImpl(type) {
            var defer = $q.defer();
            $http.get(typeToUrlMap[type] + "?get_defaults=true").then(function (response) {
                defer.resolve(response.data);
            }, function (response) {
                defer.reject(response);
            });
            return defer.promise;
        }


        function setCurrentDeviceImpl(deveui, comment = "") {
            return $http.put('/setcurdev?deveui=' + deveui + '&comment=' + comment);
        }

        function deleteUserImpl(userid, user_type) {
            return $http.delete(typeToUrlMap[user_type] + "/" + userid, null);
        }

        function deleteUsersImpl(users, user_type) {
            var promises = users.map(user => deleteUserImpl(user.userid, user_type));
            return Promise.all(promises);
        }

        function addUserOnDassImpl(user, user_type) {
            return $http.post(typeToUrlMap[user_type], user);
        }

        function updateUserImpl(userid, obj) {
            return $http.put('/uiapi/rest/users/' + userid, obj);
        }

        function signInOnDassImpl(user) {
            var defer = $q.defer();

            const tz = new Date().getTimezoneOffset();
            const tf = new Date().toLocaleTimeString().match(/am|pm/i) ? "ampm" : "24h";

             $http.put('/signin?tz=' + tz + '&tf=' + tf, user).then(response => {
                 console.log(response);
                 if (response.data != null && response.data.user != null && response.data.user.userid != null && response.data.user.userid != "") {
                     defer.resolve(response.data);
                 } else {
                     defer.reject("SIGN_IN_FAILED");
                 }
             }).catch(error => defer.reject(error))
             return defer.promise;
        }

        function signOutOnDassImpl() {
            return $http.put('/signout');
        }

        function signInForwardImpl(userid) {
            return $http.put('/signinforward' + (userid ? '?userid=' + userid : ''));
        }

        function validatePasswordPolicyImpl(user){
            return $http.put('/validate_policy', user);
        }

        function changePasswordImpl(user) {
            const {userid, ...rest} = user;
            return $http.put('/uiapi/rest/users/' + userid, rest);
        }

        function getAllUserDataImpl(userId) {
            return $http.get("/uiapi/rest/customers/" + userId);
        }

        return {
            getAllUserData: getAllUserDataImpl,
            getUserData: getUserDataImpl,
            getUser: getUserImpl,
            getUserDefaults: getUserDefaultsImpl,
            getUsers: getUsersImpl,
            setCurrentDevice: setCurrentDeviceImpl,
            deleteUser: deleteUserImpl,
            deleteUsers: deleteUsersImpl,
            signInForward: signInForwardImpl,
            signInOnDass: signInOnDassImpl,
            signOutOnDass: signOutOnDassImpl,
            addUserOnDass: addUserOnDassImpl,
            updateUser: updateUserImpl,
            changePassword: changePasswordImpl,
            validatePasswordPolicy: validatePasswordPolicyImpl
        }
    }
})(angular);
