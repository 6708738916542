export const LoggedUserInfo = () => {
    return new Promise<Response>(async (resolve, reject) => {

        try {
            const response = await fetch("/whoami", {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const ForwardUserInfo = (UserID) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch(`/uiapi/rest/users/${UserID}`, {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const ValidatePasswordPolicy = (user) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch("/validate_policy", {
                body: JSON.stringify(user),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const UpdateUserSettings = (userid: string, settings: any) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch("/uiapi/rest/users/" + userid, {
                body: JSON.stringify(settings),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const GetTenantName = (tenantId) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch(`/uiapi/rest/tenants?search_id=${tenantId}`, {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

// TODO: this functions is not sure to work
export const GetOrganizationName = (organizationId) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch(`/uiapi/rest/organisations/${organizationId}`, {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const GetLicense = () => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch("/uiapi/rest/license", {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const UpdateLicense = (license) => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch("/uiapi/rest/license", {
                body: JSON.stringify(license),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const GetSoftwareInfo = () => {
    return new Promise<Response>(async (resolve, reject) => {
        try {
            const response = await fetch("/uiapi/rest/users/$?_get_eula=true", {
                credentials: "same-origin",
                method: "GET",
            });

            if (response.status == 401) { // the status should be changed!!!
                window.location.href = '/app/signout?resignin';
            }
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};


export const getEmailHelpDesk = async () => {
    const response = await fetch("/uiapi/rest/users/$?_get_helpdesk_email=true", {
        credentials: "same-origin",
        method: "GET",
    });

    if (response.status == 401) { // the status should be changed!!!
        window.location.href = '/app/signout?resignin';
    }
    return await response.json();
};

export const getWelcomePage = async () => {
    const response = await fetch("/uiapi/rest/users/$?_get_welcome_page=true", {
        credentials: "same-origin",
        method: "GET",
    });
    if (response.status == 401) { // the status should be changed!!!
        window.location.href = '/app/signout?resignin';
    }
    return await response.json();
};
