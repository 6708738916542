import "bootstrap-daterangepicker/daterangepicker.css";
import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import { NavBar } from "./components/NavBar/NavBar";
import "./index.css";
import { AppDefaultPushConfigModal } from "./modals/AppDefaultPushConfigModal";
import { EnterpriceAddGatewayModal } from "./modals/EnterpriceAddGatewayModal";
import { EnterpriseGatewayConfigModal } from "./modals/EnterpriseGatewayConfigModal";
import { DeviceModals } from "./modals/DeviceModals";
import { GatewayModals } from "./modals/GatewayModals";
import "./style/orbiwise/style.css";

const navElement = document.getElementById("navbar-root") as HTMLElement;
if (navElement) {
  ReactDOM.unmountComponentAtNode(navElement);
  ReactDOM.render(
    <NavBar />,
    navElement,
  );
}

const element = document.getElementById("root") as HTMLElement;
if (element) {
  ReactDOM.unmountComponentAtNode(element);
  ReactDOM.render(
    <App />,
    element,
  );
}

(window as any).deviceModals = new DeviceModals();
(window as any).gatewayModals = new GatewayModals();
(window as any).appDefaultPushConfig = new AppDefaultPushConfigModal();
(window as any).eAddGatewayModal = new EnterpriceAddGatewayModal();
(window as any).eGatewayConfigModal = new EnterpriseGatewayConfigModal();
