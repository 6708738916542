(function (angular) {
    var app = angular.module('dassUiModule');
    // TODO: Refactor this ?
    
    app.filter('startFrom', function () {
        return function (input, start) {
            if (input) {
                start = +start; //parse to int
                return input.slice(start);
            }
            return [];
        };
    });

    app.filter('elipsis', () => {
        return (value, max, tail='...') => {
            if (!value) return '';

            max = parseInt(max, 10);
            if (!max) return value;
            if (value.length <= max) return value;

            value = value.substr(0, max);
            
            return value + (tail || ' …');
        };
    });

    let hex = (c) => {
            const nh = (c >> 4),
                nl = (c & 0x0f);
            return String.fromCharCode(nh < 10 ? 48 + nh : 65 + nh - 10) +
                String.fromCharCode(nl < 10 ? 48 + nl : 65 + nl - 10);
	};

    function toHex32(val) {    
        if (typeof val !== "number") {
            return null;
        } else {
            return "00000000".substring(val.toString(16).length) + val.toString(16).toUpperCase();
        }
    }

    app.filter('toHex32', function() {
        return toHex32;
    });

    app.filter('formatDeveui', function () {
        return function (input) {
            if (!input) return "NULL"; // TODO: Maybe return "" if null/undefined
            var d = input.toUpperCase();
            return d.substring(0, 2) + "-" + d.substring(2, 4) + "-" + d.substring(4, 6) + "-" + d.substring(6, 8) + "-" +
                d.substring(8, 10) + "-" + d.substring(10, 12) + "-" + d.substring(12, 14) + "-" + d.substring(14, 16);
        };
    });

    app.filter('statusTooltip', function () {
        return function (obj) {
            if (obj.activated === false) { return "Disactivated"; }
            if (obj.suspended === true)  { return "Suspended"; }

            var statustip = ["Never Seen", "Seen with MIC error", "Joined", "data received"];
            return statustip[obj.device_status];
        }
    });

    app.filter('formatPayload', function() {
        return function (str) {
            try {
                var b = atob(str);
                // console.log(b.length);
                var s = "",
                    i;
                for (i = 0; i < b.length; i++) {
                    // s += (i % 16 == 0) ? hex(i) + ": " : "";
                    s += hex(b.charCodeAt(i));
                    s += (i % 16 != 15) ? " " : "\n";
                }
                return s;
            } catch (e) {
                console.warn("Invalid data ", e)
                return "Invalid Data";
            }
        }
    });

    app.filter('statusSymbol', function () {
        return function (obj) {
            if (obj.registration_status == 1) {
                if (obj.activated === false) { return "fa fa-pause"; }
                if (obj.suspended === true)  { return "fa fa-exclamation-triangle"; }
                var statussymbol = ["fa fa-question", "fa fa-plus-circle", "fa fa-link", "fa fa-upload", "fa fa-exclamation-triangle"];
                return statussymbol[obj.device_status];
            } else if (obj.registration_status == 0) {
                return "fa-plus";
            } else if (obj.registration_status == 2) {
                return "fa-trash";
            } else {
                return "fa-exclamation-triangle";
            }
        }
    });

    app.filter('sortByColumn', function () {
        return function(array, column, reverse) {
            var dir = reverse == true ? -1 : 1;

            return array.sort(function (a, b) {
                a = a[column];
                b = b[column];
                a = a == null ? "" : a;
                b = b == null ? "" : b;
                return a == b ? 0 : a > b ? dir : -dir;
            });
        };
    });

    app.filter('dateString', function () {
        return function (time) {
            if (time == null || time == 0) {
                return "";
            }
            var offset = moment().utcOffset() / 60;

            if (offset <= -4 && offset >= -10){
                return moment(time).format("MM/DD/YYYY h:mm:ss A");
            } else {
                return moment(time).format("DD/MM/YYYY HH:mm:ss");
            }
        };
    });

    app.filter("inDateRange", function () {
        return function (items, field, date_from, date_to) {
            return _.filter(items, function (value) {
                let date_val = new Date(value[field]);

                let result = true;
                if (date_from){
                    result &= date_val >= date_from;
                }
                
                if (date_to) {
                    result &= date_val <= date_to;
                }
                
                return result;
            });
        };
    });

})(angular);
