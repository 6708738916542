import "babel-polyfill";
import * as React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ActiveConnections from "./pages/Applications/ActiveConnections";
import EventLog from "./pages/Applications/EventLog";
import DeviceProfile from "./pages/Profiles/DeviceProfiles";
import ServiceProfiles from "./pages/Profiles/ServiceProfiles";
import NetworkMap from "./pages/Devices/NetworkMap";
import { SignIn } from "./pages/SignIn/SignInPage";
import { ResetPassword } from "./pages/ResetPassword/ResetPasswordPage";
import { Welcome } from "./pages/Welcome/WelcomePage";
import { SwaggerPage } from "./pages/Swagger/SwaggerPage";

class App extends React.Component {
  public componentDidMount() {
    const RemoveClass = document.querySelector("body > div") as HTMLElement;
    RemoveClass.classList.remove("background-container");
  }
  public render() {
    return (
      <div className="App">
        <header className="App-header background-container">
          <Router basename="/app/">
            <div>
              <header className="App-header">
              </header>
              <div>
                <Route exact path="/" component={Welcome} />
                <Route path="/signin" component={SignIn} />
                <Route path="/reset_password" component={ResetPassword} />
                <Route path="/network_map" component={NetworkMap} />
                <Route path="/device_profiles" component={DeviceProfile} />
                <Route path="/connectivity_profiles" component={DeviceProfile} />
                <Route path="/service_profiles" component={ServiceProfiles} />
                <Route path="/roaming_profiles" component={DeviceProfile} />
                <Route path="/qos_profiles" component={DeviceProfile} />
                <Route path="/channel_profiles" component={DeviceProfile} />
                <Route path="/active_connections" component={ActiveConnections} />
                <Route path="/event_log" component={EventLog} />
                <Route path="/swagger" component={SwaggerPage} />
              </div>
            </div>
          </Router>
        </header>
      </div>
    );
  }
}

export default App;
